import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ENtrans from "./en/index.js";
import HItrans from "./fr/index.js";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // .use(HttpApi)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: ENtrans,
      },
      fr: {
        translations: HItrans,
      },
    },
    supportedLngs: ["en", "fr"],
    // backend: {
    //   loadPath: "/locales/{{lng}}/translation.json",
    // },
    // lng:document.querySelector('html').lang,
    fallbackLng: "en",
    debug: true,
    detection: {
      order: ["htmlTag", "cookie", "localStorage", "path", "subdomain"],
      caches: ["cookie"],
    },
    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
