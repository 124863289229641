import React, { useState } from "react";
import avatarPlaceholder from "../../assets/images/avatar-placeholder.svg";

interface Props {
  className?: string;
  imageSource: string;
  alt?: string;
  fallbackSource?: string;
}

const ImageComponent = ({
  className = "",
  imageSource,
  alt = "Logo",
  fallbackSource = avatarPlaceholder,
}: Props) => {
  const [src, setSrc] = useState(imageSource);
  return (
    <img
      className={className}
      src={src}
      onError={() => {
        setSrc(fallbackSource);
      }}
      alt={alt}
    />
  );
};

export default ImageComponent;
