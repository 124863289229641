import React from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import Chart from "../../chart";
import { useSelector } from "react-redux";
import { Skeleton, Typography, Tooltip, Empty } from "antd";
import { getDonutChartOptions } from "utils/apexChartsUtil.tsx";

const { Title } = Typography;

const Cardskeleton = () => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "275px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const ActivityLevelBreakdown = (props) => {
  const { activity_summary_activity_level_break_down_processing } = useSelector(
    (state) => state.activitysummary
  );
  const { graphdata } = props || {};
  const chartOptions = getDonutChartOptions({
    options: {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              total: {
                label: "Total",
              },
            },
          },
        },
      },
      labels: ["75-100%", "50-75%", "<50%"],
      colors: ["#4A2DDA", "#14B7DB", "#99B4F9"],
    },
    type: "NUMBER",
  });

  return (
    <>
      {activity_summary_activity_level_break_down_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>Activity Level Breakdown</Title>
            <Tooltip title="For each day, total number of employees in these different activity levels will equal the number of present employees on that day">
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div className="chartContainer chart_tool" id="chart">
            {/* chart */}
            {(graphdata || graphdata[0] || []).length !== 0 ? (
              <Chart
                options={chartOptions.options}
                series={graphdata[0].series}
                type="donut"
                height={400}
              />
            ) : (
              <div style={{ height: "371px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

ActivityLevelBreakdown.propTypes = {
  getList: PropTypes.func,
};

export default ActivityLevelBreakdown;
