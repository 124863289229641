import React, { useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import _ from "lodash";
import Chart from "../../chart";
import { hm } from "../../../utils/timeConverter";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Typography, Tooltip, Empty } from "antd";
import { getDonutChartOptions } from "utils/apexChartsUtil.tsx";

const { Text, Link, Title } = Typography;

const Cardskeleton = (props) => {
  const { loading = false } = props;
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "275px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const appusages = {
  series: [127000, 27000, 27000],
};

const WorkingTime = (props) => {
  const { data } = props;

  const productivitysummary = useSelector((state) => state.productivitysummary);

  const appusages1 = {
    series: data && data,
  };

  const chartOptions = getDonutChartOptions({
    options: {
      labels: ["Online time", "Offline time", "Break time"],
      colors: ["#A290FF", "#EC66A7", "#FFC800"],
    },
  });

  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  return (
    <>
      {productivitysummary.productivity_summary_working_time_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>Working time</Title>
            <Tooltip title="Displays the time spent by the employee as online, offline and break time.">
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div className="chartContainer chart_tool" id="chart">
            {/* chart */}
            {_.size(
              productivitysummary.productivity_summary_working_time_data
            ) > 0 ? (
              <Chart
                options={chartOptions.options}
                series={appusages1 && appusages1.series}
                type="donut"
                height={400}
              />
            ) : (
              <div style={{ height: "371px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

WorkingTime.propTypes = {
  getList: PropTypes.func,
};

export default WorkingTime;
