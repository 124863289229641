import React from "react";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import genericDomain from "./genericDomain.json";

export const snakeToCamel = (snakeCaseString: string): string =>
  snakeCaseString.replace(/_./g, (match) => match[1].toUpperCase());

export const copyToClipboard = (text, showMessage = true) => {
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed";
  document.body.appendChild(textarea);
  textarea.select();

  try {
    const successful = document.execCommand("copy");
    const textMessage = successful
      ? "URL copied to clipboard"
      : "Unable to copy URL to clipboard";
    if (showMessage) {
      message.success(textMessage);
    }
  } catch (error) {
    console.error("Error copying text to clipboard:", error);
  }

  document.body.removeChild(textarea);
};

export const getHubspotSignupType = (email: string) =>
  Number(!genericDomain.includes(email));

export const antLoadingIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;
