import React, { useState } from "react";
import PropTypes from "prop-types";

import { Layout, Typography, Divider, Descriptions, Tooltip, Card } from "antd";
import "./header.scss";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
const { Meta } = Card;
const { Paragraph, Text, Title, Link } = Typography;

const Companyinfo = (props) => {
  const tenantdata = useSelector((state) => state.tenant);

  const [ellipsis, setEllipsis] = useState(true);
  return (
    <Card size="small" title="Organization" className="org">
      <Title
        className="mb-3 pt-2"
        level={5}
        style={ellipsis ? { width: "99.9%" } : undefined}
        ellipsis={
          ellipsis
            ? {
                tooltip:
                  tenantdata &&
                  tenantdata.tenant_list_data &&
                  tenantdata.tenant_list_data.name != ""
                    ? tenantdata.tenant_list_data.name
                    : "",
              }
            : false
        }
      >
        {/* Zenstack Private Limited */}
        {tenantdata &&
        tenantdata.tenant_list_data &&
        tenantdata.tenant_list_data.name != ""
          ? tenantdata.tenant_list_data.name
          : ""}
      </Title>

      {/* <Meta title="Rajesh Yadav" description="rajeshyadav2012@we360.ai" /> */}
      <Descriptions layout="vertical">
        <Descriptions.Item label="Owner">
          {" "}
          {tenantdata &&
          tenantdata.tenant_list_data &&
          tenantdata.tenant_list_data.contact_first_name != null
            ? tenantdata.tenant_list_data.contact_first_name
            : ""}{" "}
          {tenantdata &&
          tenantdata.tenant_list_data &&
          tenantdata.tenant_list_data.contact_last_name != null
            ? tenantdata.tenant_list_data.contact_last_name
            : ""}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {/* rajeshyadav2012@we360.ai{" "} */}
          {tenantdata &&
          tenantdata.tenant_list_data &&
          tenantdata.tenant_list_data.contact_email != null
            ? tenantdata.tenant_list_data.contact_email
            : ""}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

Companyinfo.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default Companyinfo;
