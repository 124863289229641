import React, { useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Skeleton, Calendar, Badge, Typography, Tooltip, Empty } from "antd";
import moment from "moment";
import { hm } from "utils/timeConverter";
const { Title } = Typography;

const Cardskeleton = (props) => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const MonthlyAttendanceTrend = (props) => {
  const attendancedetailed = useSelector((state) => state.attendancedetailed);
  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const getListData = (value, filterDate) => {
    let listData = [];
    let dataFilter = _.find(
      value,
      (itm) => moment(itm.date).format("YYYY-MM-DD") == filterDate
    );

    if (dataFilter != undefined) {
      listData.push({
        type:
          dataFilter.att_status == "P"
            ? "present"
            : dataFilter.att_status == "A"
            ? "absent"
            : dataFilter.att_status == "WE" ||
              dataFilter.att_status === "WEEK OFF"
            ? "weeklyoff"
            : dataFilter.att_status == "H"
            ? "hoilday"
            : "",
        duration:
          dataFilter.punch_duration != null
            ? hm(dataFilter.punch_duration)
            : "00h 00m ",
      });
    } else {
      listData = [];
    }

    return listData || [];
  };

  const dateCellRender = (value) => {
    var datamap =
      attendancedetailed &&
      attendancedetailed.attendance_detailed_attendance_trend_data &&
      attendancedetailed.attendance_detailed_attendance_trend_data.length > 0 &&
      attendancedetailed.attendance_detailed_attendance_trend_data[0].logs &&
      attendancedetailed.attendance_detailed_attendance_trend_data[0].logs;

    const listData = getListData(datamap, value.format("YYYY-MM-DD"));
    if (!_.first(listData)) {
      return null;
    }
    if (_.first(listData).type.toUpperCase() == "PRESENT") {
      return (
        <Tooltip
          title={
            _.first(listData).type.toUpperCase() +
            " | Duration :" +
            " " +
            _.first(listData).duration
          }
          placement="topLeft"
        >
          <ul className="events d">
            <li key={_.first(listData).content}>
              <Badge status={_.first(listData).type}></Badge>
            </li>
          </ul>
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          title={_.first(listData).type.toUpperCase()}
          placement="topLeft"
        >
          <ul className="events d">
            <li key={_.first(listData).content}>
              <Badge status={_.first(listData).type}></Badge>
            </li>
          </ul>
        </Tooltip>
      );
    }
  };
  const { title, tooltip } = props;

  return (
    <>
      {attendancedetailed &&
      attendancedetailed.attendance_detailed_attendance_trend_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>{title}</Title>
            <Tooltip title={tooltip}>
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div
            className="chartContainer attendCalender userdetailcalender"
            style={{ marginTop: "-10px" }}
          >
            {attendancedetailed &&
            attendancedetailed.attendance_detailed_attendance_trend_data &&
            attendancedetailed.attendance_detailed_attendance_trend_data
              .length > 0 &&
            attendancedetailed.attendance_detailed_attendance_trend_data[0]
              .logs ? (
              <Calendar fullscreen={false} dateCellRender={dateCellRender} />
            ) : (
              <div style={{ height: "271px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

MonthlyAttendanceTrend.propTypes = {
  getList: PropTypes.func,
};

export default MonthlyAttendanceTrend;
