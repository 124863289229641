import Labels from "./labels";
import Messages from "./messages";
import Layout from "./layout";

const translation = {
  ...Labels,
  ...Messages,
  ...Layout,
};

export default translation;
