import { put } from "redux-saga/effects";
import { TenantSettingsActions } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* fetchTenantSettingsSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/admin/settings/`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(TenantSettingsActions.fetchTenantSettingsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TenantSettingsActions.fetchTenantSettingsFailed(errorMessage));
  }
}

export function* updateTenantSettingsSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/admin/settings/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(TenantSettingsActions.updateTenantSettingsSuccess(response.data));
    notification.success({
      message: `Successfully Updated Alert!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TenantSettingsActions.updateTenantSettingsFailed(errorMessage));
  }
}
