import React from "react";

export const Wellness = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="currentColor"
    >
      <path d="M10,6c1.1,0,2-0.9,2-2s-0.9-2-2-2C8.9,2,8,2.9,8,4S8.9,6,10,6z" />
      <path
        d="M19,14v-2c-2.2,0-4.2-1-5.6-2.7l-1.3-1.6C11.7,7.3,11.1,7,10.5,7h-1C8.9,7,8.3,7.3,8,7.7L6.6,9.3
		C5.2,11,3.2,12,1,12v2c2.8,0,5.2-1.2,7-3.3V13l-3.9,1.6C3.5,14.8,3,15.5,3,16.2c0,1,0.8,1.8,1.8,1.8H7v-0.5C7,16.1,8.1,15,9.5,15h3
		c0.3,0,0.5,0.2,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5h-3C8.7,16,8,16.7,8,17.5V18h7.2c1,0,1.8-0.8,1.8-1.8c0-0.7-0.4-1.4-1.1-1.7L12,13
		v-2.2C13.8,12.8,16.2,14,19,14z"
      />
    </svg>
  );
};
