export const SIGNUP_CREATE_INITIAL = "SIGNUP_CREATE_INITIAL";
export const SIGNUP_CREATE_PROCESS = "SIGNUP_CREATE_PROCESS";
export const SIGNUP_CREATE_SUCCESS = "SIGNUP_CREATE_SUCCESS";
export const SIGNUP_CREATE_FAILED = "SIGNUP_CREATE_FAILED";

export const SIGNUP_VERIFY_TOKEN_INITIAL = "SIGNUP_VERIFY_TOKEN_INITIAL";
export const SIGNUP_VERIFY_TOKEN_PROCESS = "SIGNUP_VERIFY_TOKEN_PROCESS";
export const SIGNUP_VERIFY_TOKEN_SUCCESS = "SIGNUP_VERIFY_TOKEN_SUCCESS";
export const SIGNUP_VERIFY_TOKEN_FAILED = "SIGNUP_VERIFY_TOKEN_FAILED";

export const SIGNUP_GOOGLE_VERIFY_TOKEN_INITIAL =
  "SIGNUP_GOOGLE_VERIFY_TOKEN_INITIAL";
export const SIGNUP_GOOGLE_VERIFY_TOKEN_PROCESS =
  "SIGNUP_GOOGLE_VERIFY_TOKEN_PROCESS";
export const SIGNUP_GOOGLE_VERIFY_TOKEN_SUCCESS =
  "SIGNUP_GOOGLE_VERIFY_TOKEN_SUCCESS";
export const SIGNUP_GOOGLE_VERIFY_TOKEN_FAILED =
  "SIGNUP_GOOGLE_VERIFY_TOKEN_FAILED";

export const SIGNUP_GET_TOKEN_INITIAL = "SIGNUP_GET_TOKEN_INITIAL";
export const SIGNUP_GET_TOKEN_PROCESS = "SIGNUP_GET_TOKEN_PROCESS";
export const SIGNUP_GET_TOKEN_SUCCESS = "SIGNUP_GET_TOKEN_SUCCESS";
export const SIGNUP_GET_TOKEN_FAILED = "SIGNUP_GET_TOKEN_FAILED";

export const SIGNUP_SET_PASSWORD_INITIAL = "SIGNUP_SET_PASSWORD_INITIAL";
export const SIGNUP_SET_PASSWORD_PROCESS = "SIGNUP_SET_PASSWORD_PROCESS";
export const SIGNUP_SET_PASSWORD_SUCCESS = "SIGNUP_SET_PASSWORD_SUCCESS";
export const SIGNUP_SET_PASSWORD_FAILED = "SIGNUP_SET_PASSWORD_FAILED";

export const SET_DOMAIN_INITIAL = "SET_DOMAIN_INITIAL";
export const SET_DOMAIN_PROCESS = "SET_DOMAIN_PROCESS";
export const SET_DOMAIN_SUCCESS = "SET_DOMAIN_SUCCESS";
export const SET_DOMAIN_FAILED = "SET_DOMAIN_FAILED";

export const CHECK_DOMAIN_INITIAL = "CHECK_DOMAIN_INITIAL";
export const CHECK_DOMAIN_PROCESS = "CHECK_DOMAIN_PROCESS";
export const CHECK_DOMAIN_SUCCESS = "CHECK_DOMAIN_SUCCESS";
export const CHECK_DOMAIN_FAILED = "CHECK_DOMAIN_FAILED";

export const SET_RESEND_EMAIL_INITIAL = "SET_RESEND_EMAIL_INITIAL";
export const SET_RESEND_EMAIL_PROCESS = "SET_RESEND_EMAIL_PROCESS";
export const SET_RESEND_EMAIL_SUCCESS = "SET_RESEND_EMAIL_SUCCESS";
export const SET_RESEND_EMAIL_FAILED = "SET_RESEND_EMAIL_FAILED";
