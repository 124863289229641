import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification, Upload as AntUpload } from "antd";
import ImgCrop from "antd-img-crop";

import avatarPlaceholder from "../../assets/images/avatar-placeholder.svg";
import {
  SetUploadAvatarInitial,
  SetUploadAvatarProcess,
} from "redux/store/users/action";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { AuthContext } from "contexts/authContext";
import ImageComponent from "./ImageComponent";

interface Props {
  avatarURI: string | null;
  apiPath: string;
  uploadMethod?: "POST" | "PUT";
  uploadType: "avatar" | "logo";
}

const Upload = ({
  avatarURI,
  apiPath,
  uploadMethod = "POST",
  uploadType = "avatar",
}: Props) => {
  const { upload_avatar_success, upload_avatar_failed } = useSelector(
    // @ts-ignore
    (state) => state.users
  );

  const { KState } = useContext(AuthContext);

  const dispatch = useDispatch();

  const [imageURL, setImageURL] = useState(avatarURI || avatarPlaceholder);

  useEffect(() => {
    return () => {
      dispatch(SetUploadAvatarInitial());
    };
  }, []);

  useEffect(() => {
    if (upload_avatar_success) {
      notification.success({
        message: "Success!",
        description: `${
          uploadType[0].toUpperCase() + uploadType.slice(1)
        } uploaded successfully`,
        placement: "top",
      });
    } else if (upload_avatar_failed) {
      notification.error({
        message: "Uh Oh!",
        description: SOMETHING_WENT_WRONG,
        placement: "top",
      });
    }
  }, [upload_avatar_success, upload_avatar_failed]);

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      notification.error({
        message: "Uh Oh!",
        description: "You can only upload JPG/PNG file!",
      });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      notification.error({
        message: "Uh Oh!",
        description: "Image must smaller than 2MB!",
      });
      return false;
    }

    const formData = new FormData();
    formData.append(uploadType, file);

    if (KState.keycloak) {
      dispatch(
        SetUploadAvatarProcess({
          data: formData,
          apiPath,
          uploadMethod,
          headers: { Authorization: `Bearer ${KState.keycloak.token}` },
        })
      );
    }

    return isJpgOrPng && isLt2M;
  };

  return (
    <ImgCrop rotationSlider>
      <AntUpload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onPreview={onPreview}
      >
        <ImageComponent
          className="w-full h-full object-contain upload-avatar"
          imageSource={imageURL}
          alt={uploadType}
        />
      </AntUpload>
    </ImgCrop>
  );
};

export default Upload;
