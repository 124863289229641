import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Profile from "./profile";
import Alerts from "./alerts";
import Onboarding from "./onboarding";
import { Drawer } from "antd";
import "./header.scss";
import ProfileDetail from "./profiledetail";
import { AuthContext } from "../../contexts/authContext";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { TenantAction, Wellness360Action } from "redux/store/action";
import FreeTrail from "./freeTrail";
import { roles } from "constants/constant";
import Help from "common/Help";
const TopHeader = (props) => {
  // console.log("props",props)
  const { silderBtn, ref1, HandleTheme, darkMode } = props;
  const [collapsed, setCollapsed] = useState(false);
  const { KeyCloaklogout, userInfo, KState, role } = useContext(AuthContext);

  const clickSilder = (val) => {
    setCollapsed(val);
    silderBtn(val);
  };
  const [openprofile, setOpenprofile] = useState(false);
  const [openmyzen, setOpenmyzen] = useState(false);
  const openMyzenModal = () => {
    setOpenmyzen(true);
  };

  const closemodal = () => {
    setOpenmyzen(false);
    setOpenprofile(false);
  };

  const openprofilemodal = () => {
    setOpenprofile(true);
  };

  //const [darkMode, setDarkMode] = useState();

  let htmlElm = document.querySelector("html");
  const dispatch = useDispatch();

  useEffect(() => {
    if (KState && KState.keycloak) GetTenantInfo();
  }, [KState]);
  const GetTenantInfo = () => {
    let data = {
      clientId: KState.keycloak.clientId,
      token: KState.keycloak.token,
    };
    dispatch(TenantAction.TenantListProcess(data));
    // dispatch(Wellness360SummaryAction.Wellness360SummaryHealthyEmployeesProcess(data));
  };

  useEffect(() => {
    //
    htmlElm.classList.add("lightmode");
    htmlElm.classList.add("content");
    if (darkMode === true) {
      // setDarkMode(true);
      htmlElm.className += " " + "darkmode";
      htmlElm.classList.remove("lightmode");
    } else if (darkMode === false) {
      htmlElm.classList.add("lightmode");
    }
    return function cleanup() {
      htmlElm.classList.remove("lightmode");
      htmlElm.classList.remove("darkmode");
    };
  }, [darkMode]);

  return (
    <div className="header">
      <div className="logo_section">
        {/* <div className="desktop">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger h_icon",
              onClick: () => clickSilder(!collapsed),
            }
          )}
        </div> */}
        <div className="mobile">
          {React.createElement(
            collapsed ? MenuFoldOutlined : MenuUnfoldOutlined,
            {
              className: "trigger h_icon",
              onClick: () => clickSilder(!collapsed),
            }
          )}
        </div>
      </div>
      <div className="flex item_center">
        <div className="mr-4 " ref={ref1}>
          {/* <Onboarding /> */}
        </div>
        <div className="mr-4 ">{/* <Alerts />  */}</div>

        <div>{role === roles.OWNER && <FreeTrail />}</div>
        <Help />
        <div className="mr-4" />
        <Profile
          downloadModal={openMyzenModal}
          ProfileModal={openprofilemodal}
          // darkMode={darkMode}
          // setDarkMode={setDarkMode}
          HandleTheme={(val) => HandleTheme(val)}
        />
      </div>

      <Drawer
        title="My Profile"
        width={520}
        onClose={closemodal}
        open={openprofile}
      >
        <ProfileDetail />
      </Drawer>
    </div>
  );
};

TopHeader.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default TopHeader;
