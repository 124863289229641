import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Tag, Space, Tooltip, notification } from "antd";

import "./header.scss";
import { AuthContext } from "../../contexts/authContext";
import avatarPlaceholder from "../../assets/images/avatar-placeholder.svg";
import {
  SetUploadAvatarInitial,
  SetUploadAvatarProcess,
} from "redux/store/users/action";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { getAvatarUrl } from "utils/transport";
import Upload from "common/Upload/index.tsx";
import URLs from "constants/urls";

const { Text, Title } = Typography;

const Profileinfo = () => {
  const users = useSelector((state) => state.users);
  const [rolename, setrolename] = useState("");
  const { userInfo, coreApiUrl } = useContext(AuthContext);
  useEffect(() => {
    if (users && _.size(users.role_user_list_data) > 0) {
      var dataFilterrole = _.find(
        users.role_user_list_data,
        (itm) =>
          (itm && itm.identity && itm.identity.id) ==
          users.medetails_data.identity.id
      );
      var dataFilter = Object.keys(dataFilterrole).length;

      if (dataFilter > 0) {
        setrolename(dataFilterrole);
      } else {
        setrolename("");
      }
    }
  }, [users]);
  return (
    <div className="profileinfo">
      <div className="p_pic">
        <Upload
          avatarURI={
            users.medetails_data?.identity?.avatar_uri === "@we360"
              ? getAvatarUrl(coreApiUrl, users.medetails_data?.identity?.id)
              : users.medetails_data?.identity?.avatar_uri
          }
          apiPath={URLs.ME_AVATAR_PATH}
        />
      </div>
      <div className="p_info">
        <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
          <Title
            className="mb-0"
            level={3}
            style={{ width: "90%" }}
            ellipsis={{ tooltip: userInfo && userInfo.name }}
          >
            {userInfo && userInfo.name}
          </Title>
          <Text
            style={{ width: "90%" }}
            ellipsis={{ tooltip: userInfo && userInfo.email }}
          >
            {userInfo && userInfo.email}
          </Text>
          <div className="flex item-center">
            <Tooltip title={"Role"}>
              <Tag color="processing" style={{ textAlign: "center" }}>
                {/* Manager */}
                {rolename && rolename.role && rolename.role.name}
              </Tag>
            </Tooltip>
            <Tooltip title={"Designation"}>
              {users &&
              users.medetails_data &&
              users.medetails_data.designation != null ? (
                <Tag color="success" style={{ textAlign: "center" }}>
                  {/* UI/Ux Desinger */}
                  {users &&
                    users.medetails_data &&
                    users.medetails_data.designation &&
                    users.medetails_data.designation.name}
                </Tag>
              ) : null}
            </Tooltip>
          </div>
        </Space>
      </div>
    </div>
  );
};

Profileinfo.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default Profileinfo;
