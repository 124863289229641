import React, { useState, useEffect } from "react";
import "../index.scss";
import { useSelector } from "react-redux";
import { Divider, TreeSelect } from "antd";

const { SHOW_PARENT } = TreeSelect;

const UserTree = (props) => {
  const {
    filterDataPass,
    userNodes,
    userIds,
    allUserNames,
    isReload,
    userName,
    singleSelect,
    selectedUserIds,
  } = props || {};
  const [groupdatabyId, setgroupdatabyId] = useState([]);
  const [degroupdatabyId, setdegroupdatabyId] = useState([]);
  const {
    team_wise_user_list_success,
    user_list_failed,
    user_list_processing,
    team_wise_user_list_processing,
  } = useSelector((state) => state.users);
  const [value, setValue] = useState([]);

  const handleClear = () => {
    setValue([]);
    setgroupdatabyId([]);
    setdegroupdatabyId([]);
    filterDataPass([]);
  };

  const handleaAllSelect = () => {
    setValue(allUserNames);
    setgroupdatabyId(userIds);
    setdegroupdatabyId([]);
    filterDataPass([]);
  };

  useEffect(() => {
    if (isReload) {
      handleClear();
    }
  }, [isReload]);
  useEffect(() => {
    if (userName !== "") {
      setValue([userName]);
    }
  }, [userName]);

  useEffect(() => {
    if (team_wise_user_list_success === true) {
      setValue([]);
      setgroupdatabyId([]);
    } else if (user_list_failed === true) {
      setValue([]);
      setgroupdatabyId([]);
    }
  }, [team_wise_user_list_success, user_list_failed]);

  const onChange = (newValue) => {
    setValue(singleSelect ? [newValue] : newValue);
  };
  const onDeselect = (value) => {
    degroupdatabyId.push(value);
    const result = groupdatabyId.filter(
      (x) => degroupdatabyId.indexOf(x) === -1
    );
    const newIds = [];

    if ((result || []).length > 0) {
      result.map((item) => newIds.push(item));
      setgroupdatabyId(newIds);
      filterDataPass(newIds);
    } else if ((result || []).length === 0) {
      setgroupdatabyId([]);
      filterDataPass([]);
    }
  };
  const handleSelect = (value) => {
    if (singleSelect) {
      setgroupdatabyId([value]);
      filterDataPass([value]);
    } else {
      groupdatabyId.push(value);
      filterDataPass(groupdatabyId);
    }
  };

  const dropdownRender = (menus) => (
    <div>
      {!singleSelect && (
        <>
          <div className="d_filter">
            <div className="d_hover" onClick={handleaAllSelect}>
              {" "}
              Select all
            </div>
            <div className="d_hover" onClick={handleClear}>
              Clear
            </div>
          </div>

          <Divider
            style={{
              margin: "0px",
              marginBottom: "5px",
            }}
          />
        </>
      )}
      {menus}
    </div>
  );

  const sortUserfunc = (userA, userB) => {
    return value.includes(userA.value) && value.includes(userB.value)
      ? 0
      : value.includes(userA.value)
      ? -1
      : 1;
  };

  return (
    <TreeSelect
      autoClearSearchValue={false}
      loading={user_list_processing || team_wise_user_list_processing}
      treeData={[...userNodes].sort(sortUserfunc)}
      value={
        user_list_processing || team_wise_user_list_processing
          ? []
          : selectedUserIds?.length
          ? selectedUserIds
          : value
      }
      onChange={onChange}
      onSelect={handleSelect}
      onDeselect={onDeselect}
      treeCheckable={!singleSelect}
      showCheckedStrategy={SHOW_PARENT}
      placeholder={singleSelect ? "" : "All Users"}
      showSearch={true}
      showArrow={true}
      maxTagCount="responsive"
      style={{
        minWidth: "180px",
      }}
      onClear={handleClear}
      dropdownRender={dropdownRender}
      filterTreeNode={(input, option) =>
        // @ts-ignore
        (option.title || "").toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    />
  );
};

export default UserTree;
