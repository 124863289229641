import React, { useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";

import {
  Table,
  Skeleton,
  Typography,
  Avatar,
  ConfigProvider,
  Progress,
  Button,
  Tooltip,
} from "antd";
import {
  InfoCircleFilled,
  CalendarOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { hms } from "../../../utils/timeConverter";
import { useSelector } from "react-redux";
const { Text, Link, Title } = Typography;

const Cardskeleton = (props) => {
  const { loading = false } = props;
  return (
    <>
      <div className="charts marginT">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const ActivityTable = (props) => {
  const activitydetails = useSelector((state) => state.actvitydetail);
  const { employeeListData, duration } = props;
  const [customize, setCustomize] = useState(true);
  const [loading, setLoading] = useState(true);
  const [averageToggle, setAverageToggle] = useState(false);

  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const columnsAverage = [
    {
      dataIndex: "totalonlinetime",

      title: () => (
        <div className="column_tooltip">
          Average Online time
          <Tooltip
            title={
              <div>
                Online time averaged over the number of days the employee was
                present.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 190,
      defaultSortOrder: "descend",

      render: (value) => {
        if (duration !== null && value !== null) {
          return value > 0 ? hms(value / duration) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },

    {
      dataIndex: "totalactivetime",
      title: () => (
        <div className="column_tooltip">
          Average Active time
          <Tooltip
            title={
              <div>
                Active time averaged over the number of days the employee was
                present.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 190,
      defaultSortOrder: "descend",

      render: (value) => {
        if (duration !== null && value !== null) {
          return value > 0 ? hms(value / duration) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalidletime",
      title: () => (
        <div className="column_tooltip">
          Average Idle time
          <Tooltip
            title={
              <div>
                Idle time averaged over the number of days the employee was
                present.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 180,

      defaultSortOrder: "descend",

      render: (value) => {
        if (duration !== null && value !== null) {
          return value > 0 ? hms(value / duration) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalbreaktime",
      title: () => (
        <div className="column_tooltip">
          Average Break time
          <Tooltip
            title={
              <div>
                Break time averaged over the number of days the employee was
                present.
              </div>
            }
          >
            <span className="info_i">
              <InfoCircleFilled />
            </span>
          </Tooltip>
        </div>
      ), // note it's func
      width: 190,
      defaultSortOrder: "descend",

      render: (value) => {
        if (duration !== null && value !== null) {
          return value > 0 ? hms(value / duration) : hms(value);
        } else {
          return hms(0);
        }
      },
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "activityper",
      title: "Activity %",
      width: 150,
      fixed: "right",
      render: (val) => (
        <>
          <Progress
            strokeLinecap="butt"
            percent={!val ? 0 : val === 100 ? 99.99 : val || 0}
            strokeColor={"#104ADF"}
          />
        </>
      ),
    },
  ];
  const columnsTotal = [
    {
      dataIndex: "totalonlinetime",
      title: "Online time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalonlinetime - b.totalonlinetime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },

    {
      dataIndex: "totalactivetime",
      title: "Active time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalactivetime - b.totalactivetime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalidletime",
      title: "Idle time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalidletime - b.totalidletime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "totalbreaktime",
      title: "Break time",
      width: 150,
      defaultSortOrder: "descend",
      // sorter: (a, b) => a.totalbreaktime - b.totalbreaktime,
      render: (value) => hms(value),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      dataIndex: "activityper",
      title: "Activity %",
      width: 150,
      fixed: "right",
      render: (val) => (
        <>
          <Progress
            strokeLinecap="butt"
            percent={!val ? 0 : val === 100 ? 99.99 : val || 0}
            strokeColor={"#4A2DDA"}
          />
        </>
      ),
    },
  ];
  const toggleAverage = (e) => {
    e.preventDefault();
    setAverageToggle(!averageToggle);
  };

  return (
    <>
      <div className="charts ">
        <div className="chart_header">
          {activitydetails.activity_employee_detailed_employee_list_processing ? (
            <Skeleton.Input
              size={"small"}
              shape={"default"}
              style={{ height: "15px" }}
            />
          ) : (
            <Title level={2}>Activity</Title>
          )}
          {/* <Button size="defult" icon={<LineChartOutlined />}>
            {" "}
            Average{" "}
          </Button> */}
          <Button
            size="defult"
            icon={<LineChartOutlined />}
            onClick={toggleAverage}
          >
            {averageToggle ? "Total" : "Average"}
          </Button>
        </div>
        <ConfigProvider renderEmpty={customize ? null : undefined}>
          <div className=" " style={{ marginTop: "-10px" }}>
            <Table
              // dataSource={dataSource}
              dataSource={
                _.size(
                  activitydetails.activity_employee_detailed_employee_list_data
                ) > 0
                  ? employeeListData && employeeListData
                  : null
              }
              // columns={columns}
              columns={averageToggle ? columnsAverage : columnsTotal}
              size="small"
              scroll={{
                x: 1500,
                // y: 300,
              }}
              rowKey="id"
              pagination={false}
            />
          </div>
        </ConfigProvider>
      </div>
    </>
  );
};

ActivityTable.propTypes = {
  getList: PropTypes.func,
};

export default ActivityTable;
