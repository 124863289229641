const translation = {
  dashboard: "Dashboard",
  attendance: "Attendance",
  livestream: "Live Stream",
  wellness360: "Wellness 360",
  activity: "Activity",
  productivity: "Productivity",
  timeline: "Timeline",
  application: "Application",
  reports: "Reports",
  alerts: "Alerts",
  settings: "Settings",
};

export default translation;
