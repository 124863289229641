import "./public-path";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// import { Provider } from "react-redux";
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/index";
// import { toast } from 'react-toastify';
import Route from "./route";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import { ConfigProvider } from "antd";
import "./utils/localization";
// import { ThemeProvider } from "./theme/theme-provider";
import { PostHogProvider } from "posthog-js/react";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  persistence: "localStorage+cookie",
};

// import 'react-toastify/dist/ReactToastify.css';
// import { initializeFirebase, askForPermissionToReceiveNotifications } from './push-notification'
// import * as firebase from 'firebase'

//const store = configureStore();

// toast.configure();
ReactDOM.render(
  // <ConfigProvider
  //   theme={{
  //     token: {
  //       colorPrimary: "#573ed6",
  //     },
  //   }}
  // >
  <StrictMode>
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <ConfigProvider
        theme={{
          token: {
            fontFamily: "Lato",
          },
        }}
      >
        {/* <ThemeProvider> */}
        <ReduxProvider store={store}>
          {/* <PersistGate loading={<h6>Loading...</h6>} persistor={persistor}> */}
          <BrowserRouter>
            {/* <Suspense fallback={"Loading..."}> */}
            <Route />
            {/* </Suspense> */}
          </BrowserRouter>
          {/* </PersistGate> */}
        </ReduxProvider>
        {/* </ThemeProvider> */}
      </ConfigProvider>
    </PostHogProvider>
  </StrictMode>,
  // </ConfigProvider>
  document.getElementById("root")
);

// initialize firebase for push notification.
// initializeFirebase();
// const messaging = firebase.messaging();
// messaging.usePublicVapidKey("BEsBewuGG1j0DvOr65lpGnMXHGWwqwvrLCn5VAIZ0M6v9EAGX0EfNgQ7AGDrpXfFZIt8IhPUJteObE3Gnb9XL9s");

// if(!localStorage.getItem("notification-token")){
// 	askForPermissionToReceiveNotifications();
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('../firebase-messaging-sw.js')
//   .then(function(registration) {
//     console.log('Registration successful, scope is:', registration.scope);
//   }).catch(function(err) {
//     console.log('Service worker registration failed, error:', err);
//   });
// }
