import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Content } from "./components/tabs";
import { AuthContext } from "contexts/authContext";

import { Divider, Row, Col, Card, message, Drawer } from "antd";
import Profileinfo from "./components/profileinfo";
import ApplicationTopApp from "../Application/components/applicationTopApp";
import ApplicationTopUrl from "../Application/components/applicationTopUrl";
import ApplicationTopCategory from "../Application/components/applicationTopCategery";
// attendance
import Attendancetable from "./components/attendancetable";
import AttendanceDetailChart from "./../Attendance/components/attendanceDetailChart";
import AttendanceChart from "./components/AttendanceChart";
import MonthlyAttendanceTrend from "./components/monthlyattendanceTrend";
import WellnessOverallScore from "./components/wellnessoverallScore";
import MonthlyWellnessTrend from "./components/monthlywellnessTrend";
import WellnessTable from "./components/wellnesstable";
import WorkingTime from "components/Productivity/components/workingtime";
import ProductivityBreakdown from "components/Productivity/components/productivityBreakdown";
import ProductivityTable from "./components/productivityTable";
import offlineValue from "../../utils/getOfflineTime";
import dayjs from "dayjs";
import OnlineTimeBreakdown from "components/Activity/components/onlinetimeBreakdown";
import ActivityLevelBreakdown from "components/Activity/components/activitylevelBreakdown";
import ActivityTable from "./components/activitytable";
import customParseFormat from "dayjs/plugin/customParseFormat";
import CategoryUtilisation from "../Application/components/categoryUtilisation";
import TotalAppusage from "../Application/components/totalappusases";
import TotalYUrlusage from "../Application/components/totalurlusases";
import AppUrlLogs from "./components/appurllogs";
import {
  ActivityDetailAction,
  ActivitySummaryAction,
  ApplicationDetailAction,
  ApplicationSummaryAction,
  AttendanceDetailedAction,
  ProductivityDetailAction,
  ProductivitySummaryAction,
  Wellness360DetailsAction,
  Wellness360SummaryAction,
} from "../../redux/store/action";
import { hm } from "utils/timeConverter";
import { useSelector, useDispatch } from "react-redux";
import GetParamers from "utils/getParamers";
import Filter from "components/Filter";
import { roles } from "constants/constant";
import InnerHeader from "common/InnerHeader";
import { AiOutlineUser } from "react-icons/ai";
import { pathToFeatureKeyMap } from "utils/billingUtils";
import {
  ACTIVITY,
  APPLICATION,
  ATTENDANCE,
  PRODUCTIVITY,
  WELLNESS,
} from "route/constant";
import { closeUserDetail } from "redux/store/user-detail/action";
dayjs.extend(customParseFormat);

let isMonthFilter = false;
const UserDetail = (props) => {
  const {
    open,
    sDate = dayjs().subtract(6, "d"),
    eDate = dayjs(),
    tab = 0,
    identityId,
    page,
  } = props;

  const [dateDiff, setDateDiff] = useState(1);

  const users = useSelector((state) => state.users);

  const [active, setActive] = useState(tab && tab);
  const [startDate, setstartDate] = useState(sDate);
  const [endDate, setendDate] = useState(eDate);

  const [userIds, setuserIds] = useState([
    identityId || users.medetails_data?.identity?.id,
  ]);
  const [groupIds, setgroupIds] = useState([]);

  const dispatch = useDispatch();
  const { KState, role } = useContext(AuthContext);
  const applicationsummary = useSelector((state) => state.applicationsummary);
  const productivitysummary = useSelector((state) => state.productivitysummary);
  const productivitydetail = useSelector((state) => state.productivitydetail);

  const [topAppData, settopAppData] = useState([]);
  const [topUrlData, settopUrlData] = useState([]);
  const [topCategoryData, settopCategoryData] = useState([]);
  const [ApplicationAppWorkingHoursTime, setApplicationAppWorkingHoursTime] =
    useState([]);
  const [ApplicationAppPercentage, setApplicationAppPercentage] = useState([]);
  const [ApplicationAppCategoryList, setApplicationAppCategoryList] = useState(
    []
  );
  const [ApplicationUrlWorkingHoursTime, setApplicationUrlWorkingHoursTime] =
    useState([]);
  const [ApplicationUrlPercentage, setApplicationUrlPercentage] = useState([]);
  const [ApplicationUrlCategoryList, setApplicationUrlCategoryList] = useState(
    []
  );
  const [workingTimeSummary, setworkingTimeSummary] = useState([]);
  const [breakDownSummary, setbreakDownSummary] = useState([]);
  const [ActivityLevelData, setActivityLevelData] = useState([]);
  const activitydetails = useSelector((state) => state.actvitydetail);

  const [
    ApplicationCategoryWorkingHoursTime,
    setApplicationCategoryWorkingHoursTime,
  ] = useState([]);
  const [ApplicationCategoryPercentage, setApplicationCategoryPercentage] =
    useState([]);
  const [ApplicationCategoryList, setApplicationCategoryList] = useState([]);
  const activitysummary = useSelector((state) => state.activitysummary);
  const [employeeListData, setemployeeListData] = useState([]);
  const [ProductivityPerData, setProductivityPerData] = useState([]);
  const [employeeListDataProductivity, setemployeeListDataProductivity] =
    useState([]);

  const { subscriptionsData } = useSelector((state) => state.billing);

  const subscriptionFeatures =
    subscriptionsData?.plan?.plan_type?.plan_features?.features || {};

  const onChangeUser = (userIds) => {
    setuserIds(userIds);
  };

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    var sDate = {};
    var eDate = {};
    if (index == 1) {
      isMonthFilter = true;
      sDate = dayjs().startOf("month");
      eDate = dayjs();
      //setstartDate(sDate);
      //setendDate(eDate);
    } else {
      isMonthFilter = false;
      // sDate = startDate;
      // eDate = endDate;
      sDate = dayjs().subtract(6, "day");
      eDate = dayjs();
      setstartDate(sDate);
      setendDate(eDate);
      // setstartDate(sDate);
      // setendDate(eDate);
    }

    if (index !== active) {
      setActive(index);
      loadAPi(index, sDate, eDate);
    }
  };

  const loadAPi = (active, sDate, eDate) => {
    if (KState && KState.keycloak && users) {
      if (active === 0) {
        AttendanceTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      } else if (active === 1) {
        isMonthFilter = true;
        WellnessTab(KState.keycloak, sDate, eDate, groupIds, userIds, 1, 0);
      } else if (active === 2) {
        ProductivityTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      } else if (active === 3) {
        ActivityTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      } else if (active === 4) {
        ApplicationTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      } else {
        AttendanceTab(KState.keycloak, sDate, eDate, groupIds, userIds);
      }
    }
  };

  useEffect(() => {
    loadAPi(active, startDate, endDate);
  }, [userIds]);
  // const loadAPi = (active) => {
  //   if (KState && KState.keycloak && users) {
  //     // setChangeSegmented(true);
  //     // ClearSummaryTab();
  //     // ClearDetailsTab();
  //     if (active === 0) {
  //       AttendanceTab(KState.keycloak, startDate, endDate, groupIds, userIds);
  //     } else if (active === 1) {
  //       WellnessTab(
  //         KState.keycloak,
  //         startDate,
  //         endDate,
  //         groupIds,
  //         userIds,
  //         1,
  //         0
  //       );
  //     } else if (active === 2) {
  //       ProductivityTab(KState.keycloak, startDate, endDate, groupIds, userIds);
  //     } else if (active === 3) {
  //       ActivityTab(KState.keycloak, startDate, endDate, groupIds, userIds);
  //     } else if (active === 4) {
  //       ApplicationTab(KState.keycloak, startDate, endDate, groupIds, userIds);
  //     }
  //   }
  // };

  useEffect(() => {
    if (KState && KState.keycloak && users) {
      loadAPi(tab, props && props.sDate, props && props.eDate);
    }
  }, []);

  const AttendanceTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = ""
  ) => {
    dispatch(
      AttendanceDetailedAction.AttendanceDetailedAttendanceTrendProcess(
        // datacurrent1
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          true
        )
      )
    );
  };

  const WellnessTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = "",
    page,
    limit
  ) => {
    dispatch(
      Wellness360SummaryAction.Wellness360MeOverallScoreProcess(
        // preRequest
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      Wellness360DetailsAction.Wellness360EmployeeDetailsEmployeeListProcess(
        // PreData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, page, limit)
      )
    );
  };

  const ProductivityTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = ""
  ) => {
    calculateDifference(startDate, endDate);

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTopAppUrlProcess(
        // topAppUrlData
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "productivity"
        )
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryTopCategoryProcess(
        // topCategoryDataset
        keycloak.token,
        // GetParamers(startDate, endDate, groupIds, userIds)
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "productivity"
        )
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryWorkingTimeProcess(
        // WorkingTimeData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryProductivityBreakdownProcess(
        // ProductivityBreackDownData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      ProductivityDetailAction.ProductivityEmployeeDetailedEmployeeListProcess(
        // EmpListDataProductiviy
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );
  };

  const ActivityTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = ""
  ) => {
    calculateDifference(startDate, endDate);

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTopAppUrlProcess(
        // topAppUrlData
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "activity"
        )
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryTopCategoryProcess(
        // topCategoryDataset
        keycloak.token,
        // GetParamers(startDate, endDate, groupIds, userIds)
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "activity"
        )
      )
    );

    dispatch(
      ActivitySummaryAction.ActivitySummaryOnlineTimeBreakdownProcess(
        // OnlineTimeBreackDownData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 0, false, true)
      )
    );

    dispatch(
      ActivitySummaryAction.ActivitySummaryActivityLevelBreakdownProcess(
        // levelBreakDownData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      ActivityDetailAction.ActivityEmployeeDetailedEmployeeListProcess(
        // EmpListData

        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );
  };

  const ApplicationTab = (
    keycloak,
    startDate,
    endDate,
    groupIds = "",
    userIds = ""
  ) => {
    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTopAppUrlProcess(
        // topAppUrlData
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "activity"
        )
      )
    );

    dispatch(
      ProductivitySummaryAction.ProductivitySummaryTopCategoryProcess(
        // topCategoryDataset
        keycloak.token,
        // GetParamers(startDate, endDate, groupIds, userIds)
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "",
          false,
          "activity"
        )
      )
    );

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTotalApplicationUsageProcess(
        // totalApplicationUsageData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryTotalUrlUsageProcess(
        // totalUrlUsageData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds, 1, 10)
      )
    );

    dispatch(
      ApplicationSummaryAction.ApplicationSummaryCategoryUtilizationProcess(
        // CategoryUtilizationData
        keycloak.token,
        GetParamers(startDate, endDate, groupIds, userIds)
      )
    );

    dispatch(
      ApplicationDetailAction.ApplicationDetailedSearchWiseAppDetailProcess(
        // SearchWiseAppDetaildata
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "app",
          "",
          false,
          "",
          "",
          "",
          "",
          false,
          "",
          true
        )
      )
    );

    dispatch(
      ApplicationDetailAction.ApplicationDetailedSearchWiseUrlDetailProcess(
        // SearchWiseUrlDetaildata
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "",
          "url",
          false,
          "",
          "",
          "",
          "",
          false,
          "",
          true
        )
      )
    );

    dispatch(
      ApplicationDetailAction.ApplicationDetailedSearchWiseAppUrlDetailProcess(
        // SearchWiseAppUrlDetaildata
        keycloak.token,
        GetParamers(
          startDate,
          endDate,
          groupIds,
          userIds,
          0,
          0,
          false,
          false,
          "",
          "app",
          "url"
        )
      )
    );
  };

  useEffect(() => {
    if (applicationsummary.application_summary_app_url_success === true) {
      var AppData = [];
      if (_.size(applicationsummary.application_summary_app_url_data) > 0) {
        AppData.push({
          id: "1",
          label: "Top Application",
          value:
            applicationsummary.application_summary_app_url_data &&
            applicationsummary.application_summary_app_url_data
              .top_application_uses === null
              ? "--"
              : applicationsummary.application_summary_app_url_data
                  .top_application_uses.application_name,
          bottomtext:
            applicationsummary.application_summary_app_url_data &&
            applicationsummary.application_summary_app_url_data
              .top_application_uses === null
              ? "--"
              : hm(
                  applicationsummary.application_summary_app_url_data
                    .top_application_uses.total_duration
                ),
          infotitle:
            "The most used application of the filtered team/organisation",
          data:
            applicationsummary &&
            applicationsummary.application_summary_app_url_data,
        });
      } else {
        AppData.push({
          id: "1",
          label: "Top Application",
          value: "--",
          bottomtext: "--",
          infotitle:
            "The most used application of the filtered team/organisation",
          data: null,
        });
      }
      settopAppData(AppData);
    }

    if (applicationsummary.application_summary_app_url_success === true) {
      var ulrdata = [];
      if (_.size(applicationsummary.application_summary_app_url_data) > 0) {
        ulrdata.push({
          id: "2",
          label: "Top URL",
          value:
            applicationsummary.application_summary_app_url_data &&
            applicationsummary.application_summary_app_url_data
              .top_urls_uses === null
              ? "--"
              : applicationsummary.application_summary_app_url_data
                  .top_urls_uses.url,
          bottomtext:
            applicationsummary.application_summary_app_url_data &&
            applicationsummary.application_summary_app_url_data
              .top_urls_uses === null
              ? "--"
              : hm(
                  applicationsummary.application_summary_app_url_data
                    .top_urls_uses.total_duration
                ),
          infotitle:
            "The most used productive category of the filtered team/organisation",
          data:
            applicationsummary &&
            applicationsummary.application_summary_app_url_data,
        });
        settopUrlData(ulrdata);
      } else {
        ulrdata.push({
          id: "2",
          label: "Top URL",
          value: "--",
          bottomtext: "--",
          infotitle:
            "The most used productive category of the filtered team/organisation",
          data: null,
        });
      }
    }

    if (
      productivitysummary.productivity_summary_top_category_success === true
    ) {
      var categorydata = [];
      if (
        _.size(productivitysummary.productivity_summary_top_category_data) > 0
      ) {
        categorydata.push({
          id: "3",
          label: "Top Category",
          value:
            productivitysummary.productivity_summary_top_category_data &&
            productivitysummary.productivity_summary_top_category_data
              .category === null
              ? "--"
              : productivitysummary.productivity_summary_top_category_data
                  .category,
          bottomtext:
            productivitysummary.productivity_summary_top_category_data &&
            productivitysummary.productivity_summary_top_category_data
              .duration === null
              ? "--"
              : hm(
                  productivitysummary.productivity_summary_top_category_data
                    .duration
                ),
          infotitle:
            "The most used productive category of the filtered team/organisation",
          data:
            productivitysummary &&
            productivitysummary.productivity_summary_top_category_data,
        });
      } else {
        categorydata.push({
          id: "3",
          label: "Top Category",
          value: "--",
          bottomtext: "--",
          infotitle:
            "The most used productive category of the filtered team/organisation",
          data: null,
        });
      }

      settopCategoryData(categorydata);
    }
    if (
      applicationsummary.application_summary_total_application_usage_success ===
      true
    ) {
      var onlyApplicationAppPer = [];
      var onlyApplicationAppDur = [];
      var onlyApplicationTeamList = [];
      if (
        _.size(
          applicationsummary &&
            applicationsummary.application_summary_total_application_usage_data
        ) > 0
      ) {
        var AppTotal = _.orderBy(
          applicationsummary.application_summary_total_application_usage_data,
          "productive_duration",
          "desc"
        );

        let AppTen = _.take(AppTotal, 10);

        AppTen.map((item) => {
          onlyApplicationAppPer.push(item.prod_percent);
          onlyApplicationAppDur.push(item.productive_duration);
          onlyApplicationTeamList.push(item.application_name);
        });
        setApplicationAppPercentage(onlyApplicationAppPer);
        setApplicationAppWorkingHoursTime(onlyApplicationAppDur);
        setApplicationAppCategoryList(onlyApplicationTeamList);
      }
    }
    if (
      applicationsummary.application_summary_total_url_usage_success === true
    ) {
      var onlyApplicationUrlPer = [];
      var onlyApplicationUrlDur = [];
      var onlyApplicationUrlList = [];
      if (
        _.size(
          applicationsummary &&
            applicationsummary.application_summary_total_url_usage_data
        ) > 0
      ) {
        var UrlTotal = _.orderBy(
          applicationsummary.application_summary_total_url_usage_data,
          "productive_duration",
          "desc"
        );

        let urlTen = _.take(UrlTotal, 10);

        urlTen.map((item) => {
          onlyApplicationUrlPer.push(item.prod_percent);
          onlyApplicationUrlDur.push(item.productive_duration);
          onlyApplicationUrlList.push(item.url);
        });
        setApplicationUrlPercentage(onlyApplicationUrlPer);
        setApplicationUrlWorkingHoursTime(onlyApplicationUrlDur);
        setApplicationUrlCategoryList(onlyApplicationUrlList);
      }
    }
    if (
      applicationsummary.application_summary_category_utilization_success ===
      true
    ) {
      const onlyApplicationCategoryUtilizationPer = [];
      const onlyApplicationCategoryUtilizationDur = [];
      const onlyApplicationCategoryUtilizationList = [];
      if (
        (
          applicationsummary?.application_summary_category_utilization_data ||
          []
        ).length > 0
      ) {
        applicationsummary.application_summary_category_utilization_data.map(
          (item) => {
            onlyApplicationCategoryUtilizationPer.push(
              item.percentage_duration
            );
            onlyApplicationCategoryUtilizationDur.push(item.active_duration);
            onlyApplicationCategoryUtilizationList.push(
              !item.cate_name ? "null" : item.cate_name
            );
          }
        );

        setApplicationCategoryPercentage(onlyApplicationCategoryUtilizationPer);
        setApplicationCategoryWorkingHoursTime(
          onlyApplicationCategoryUtilizationDur
        );
        setApplicationCategoryList(onlyApplicationCategoryUtilizationList);
      }
    }

    if (
      activitysummary.activity_summary_activity_level_break_down_success ===
      true
    ) {
      setActivityLevelData([]);
      var activityLevelData = [];
      if (
        _.size(
          activitysummary &&
            activitysummary.activity_summary_activity_level_break_down_data
        ) > 0
      ) {
        activityLevelData.push({
          series: [
            activitysummary &&
              activitysummary.activity_summary_activity_level_break_down_data
                .greater_then_75_count,
            activitysummary.activity_summary_activity_level_break_down_data
              .less_then_75_equal_50_count,
            activitysummary.activity_summary_activity_level_break_down_data
              .less_then_50_count,
          ],
        });
      }
      setActivityLevelData(activityLevelData);
    }
    if (
      activitydetails.activity_employee_detailed_employee_list_success === true
    ) {
      var empData = [];
      if (
        _.size(activitydetails.activity_employee_detailed_employee_list_data) >
        0
      ) {
        activitydetails.activity_employee_detailed_employee_list_data.map(
          (item) => {
            empData.push({
              id: item.identity_id,
              employee: item.first_name + " " + item.last_name,
              totalworkingtime: item.punch_duration,
              totalonlinetime: item.online_duration,
              totalofflinetime: offlineValue(
                item.punch_duration,
                item.online_duration,
                item.break_duration
              ),
              totalactivetime: item.active_duration,
              totalidletime: item.idle_duration,
              totalbreaktime: item.break_duration,
              activityper: item.active_percent,
            });
          }
        );
      }
      setemployeeListData(empData);
    }

    ///////////====================/////////////////////
    if (
      productivitysummary.productivity_summary_working_time_success === true
    ) {
      var workingTimeSum = [];
      if (
        _.size(productivitysummary.productivity_summary_working_time_data) > 0
      ) {
        productivitysummary.productivity_summary_working_time_data.map(
          (item) => {
            workingTimeSum.push([
              item.total_online_duration === null
                ? 0
                : item.total_online_duration,
              offlineValue(
                item.total_punch_duration,
                item.total_online_duration
              ),
              item.total_break_duration === null
                ? 0
                : item.total_break_duration,
            ]);
          }
        );

        setworkingTimeSummary(workingTimeSum[0]);
      }
    }
    if (productivitysummary.productivity_summary_working_time_failed === true) {
      message.error(
        productivitysummary.productivity_summary_working_time_message.message
      );
    }
    if (
      productivitysummary.productivity_summary_productivity_break_down_success ===
      true
    ) {
      var breakdownSum = [];
      var PercentData = [];
      if (
        _.size(
          productivitysummary.productivity_summary_productivity_break_down_data
        ) > 0
      ) {
        productivitysummary.productivity_summary_productivity_break_down_data.map(
          (item) => {
            breakdownSum.push([
              item.total_productive_duration === null
                ? 0
                : item.total_productive_duration,
              item.total_unproductive_duration === null
                ? 0
                : item.total_unproductive_duration,
              item.total_neutral_duration === null
                ? 0
                : item.total_neutral_duration,
            ]);
            PercentData.push({
              id: "2",
              label: "Product Percentage",
              value:
                item.total_productive_duration === null ||
                item.total_working_duration === null
                  ? 0
                  : parseFloat(
                      (item.total_productive_duration /
                        item.total_working_duration) *
                        100
                    ).toFixed(2),

              infotitle:
                "The most used application of the filtered team/organisation",
              data: item,
            });
          }
        );
      } else {
        PercentData.push({
          id: "2",
          label: "Product Percentage",
          value: "--",
          bottomtext: "--",
          infotitle:
            "The most used application of the filtered team/organisation",
          data: null,
        });
      }
      setProductivityPerData(PercentData);
      setbreakDownSummary(breakdownSum[0]);
    }
    if (
      productivitysummary.productivity_summary_productivity_break_down_failed ===
      true
    ) {
      message.error(
        productivitysummary.productivity_summary_productivity_break_down_message
          .message
      );
    }

    if (
      productivitydetail.productivity_employee_detailed_employee_list_success ===
      true
    ) {
      var empData = [];
      if (
        _.size(
          productivitydetail.productivity_employee_detailed_employee_list_data
        ) > 0
      ) {
        productivitydetail.productivity_employee_detailed_employee_list_data.map(
          (item) => {
            empData.push({
              id: item.identity_id,
              employee: item.first_name + " " + item.last_name,
              totalworkingtime: item.punch_duration,
              totalonlinetime: item.online_duration,
              totalofflinetime: offlineValue(
                item.punch_duration,
                item.online_duration,
                item.break_duration
              ),
              totalproductivetime: item.productive_duration,
              totalunproductivetime: item.unproductive_duration,
              totalneutraltime: item.neutral_duration,
              totalbreaktime: item.break_duration,
              activityper: item.active_percent,
            });
          }
        );
      }

      setemployeeListDataProductivity(empData);
    } else if (
      productivitydetail.productivity_employee_detailed_employee_list_failed ===
      true
    ) {
      message.error(
        productivitydetail.productivity_employee_detailed_employee_list_message
          .message
      );
    }
  }, [applicationsummary, activitydetails, productivitydetail]);

  const calculateDifference = (start, end) => {
    const date1 = dayjs(start);
    const date2 = dayjs(end);
    const difference = date2.diff(date1, "days");
    setDateDiff(difference);
  };
  const pagination = (page, limit) => {
    let PreData = {
      clientId: KState.keycloak.clientId,
      token: KState.keycloak.token,
      schemaname: KState.keycloak.clientId,
      identityid:
        users.medetails_data &&
        users.medetails_data.identity &&
        users.medetails_data.identity.id,
      start_date: startDate + "T00:00:00.474505",
      end_date: endDate + "T23:59:59.474505",
      group_id: _.size(groupIds) > 0 ? groupIds.join(",") : "",
      user_id: _.size(userIds) > 0 ? userIds.join(",") : "",
      page: page,
      limit: limit,
    };

    dispatch(
      Wellness360DetailsAction.Wellness360DetailsEmployeeListProcess(PreData)
    );
  };

  const onCloseModal = () => {
    dispatch(closeUserDetail());
    setActivityLevelData([]);
  };

  const dateRange = (startDate, endDate) => {
    var sDate = startDate;
    var eDate = endDate;
    setstartDate(sDate);
    setendDate(eDate);

    loadAPi(active, startDate, endDate);
    // setstartDate(sDate);
    // setendDate(eDate);

    // loadAPi(active, startDate, endDate);
  };
  const OnSelctMonth = (sDate, eDate) => {
    setstartDate(sDate);
    setendDate(eDate);
    loadAPi(active, sDate, eDate);
  };
  const reload = (reload) => {
    var sDate = {};
    var eDate = {};
    if (active == 1) {
      isMonthFilter = true;
      sDate = dayjs().startOf("month");
      eDate = dayjs();
      setstartDate(sDate);
      setendDate(eDate);
    } else {
      isMonthFilter = false;
      sDate = dayjs().subtract(6, "day");
      eDate = dayjs();
      setstartDate(sDate);
      setendDate(eDate);
    }

    loadAPi(active, sDate, eDate);
  };

  const onSelectDate = (date, dateStrings) => {};

  const getHOCWrapped = (children) => {
    if (page) {
      return (
        <div className="inner_layout">
          <InnerHeader
            headingicon={<AiOutlineUser size={20} />}
            headingname={role === roles.USER ? "Me" : "Userwise Detail"}
            iconbgColor={"#14B8A61a"}
            iconcolor={"#14B8A6"}
            // FirstswicthName={"Activity"}
            // SecondswicthName={"Productivity"}
            // check={activityswitch}
            // FirstClickswicth={setActivitySwitchTrue}
            // SecondClickswicth={setActivitySwitchFalse}
            ExportDownload={() => {}}
            segmentArray={[]}
          />{" "}
          <br />
          {children}
        </div>
      );
    }
    return (
      <Drawer
        //   title="Drawer with extra actions"
        width={"calc(100vw - 220px)"}
        className={"userdetail_block"}
        onClose={onCloseModal}
        open={open}
      >
        {children}
      </Drawer>
    );
  };

  return getHOCWrapped(
    <>
      <div className="filter_all" style={{ marginTop: "-5px" }}>
        {active === 6 ? (
          <Filter
            teamshow={false}
            usershow={false}
            monthshow={true}
            rangeshow={false}
            btnDownload={false}
            btnReload={true}
            isfilter={false}
            onSelectDate={onSelectDate}
            startDate={dayjs()}
            // // selectTeam={(ids) => ChangeTeamFilter(ids)}
            //filterDataPass={(userIds) => filterDataPass(userIds)}
            ClickReload={reload}
          />
        ) : (
          <Filter
            teamshow={false}
            usershow={role !== roles.USER}
            monthshow={false}
            rangeshow={isMonthFilter === true ? false : true}
            monthpikershow={isMonthFilter}
            btnDownload={false}
            btnReload={true}
            isfilter={false}
            startDate={startDate}
            endDate={endDate}
            dateRange={(startDate, endDate) => dateRange(startDate, endDate)}
            OnSelctMonth={(sDate, eDate) => OnSelctMonth(sDate, eDate)}
            // // selectTeam={(ids) => ChangeTeamFilter(ids)}
            //filterDataPass={(userIds) => filterDataPass(userIds)}
            ClickReload={reload}
            singleSelect
            filterDataPass={onChangeUser}
            selectedUserIds={userIds}
          />
        )}
      </div>
      <div className="mgrid column_m2">
        <Card
          style={{
            height: "calc(100vh - 155px)",
            position: "sticky",
            top: "0px",
          }}
        >
          <Profileinfo
            UserDetaildata={
              users.user_map_data[userIds.length ? userIds[0] : identityId]
            }
          />
          <Divider />

          <Tabs>
            {/* <Tab onClick={handleClick} active={active === 5} id={5}>
              Dashboard
            </Tab> */}

            {subscriptionFeatures[pathToFeatureKeyMap[ATTENDANCE]]?.data && (
              <Tab onClick={handleClick} active={active === 0} id={0}>
                Attendance
              </Tab>
            )}

            {subscriptionFeatures[pathToFeatureKeyMap[WELLNESS]]?.data && (
              <Tab onClick={handleClick} active={active === 1} id={1}>
                Wellness
              </Tab>
            )}
            {subscriptionFeatures[pathToFeatureKeyMap[PRODUCTIVITY]]?.data && (
              <Tab onClick={handleClick} active={active === 2} id={2}>
                Productivity
              </Tab>
            )}
            {subscriptionFeatures[pathToFeatureKeyMap[ACTIVITY]]?.data && (
              <Tab onClick={handleClick} active={active === 3} id={3}>
                Activity
              </Tab>
            )}
            {subscriptionFeatures[pathToFeatureKeyMap[APPLICATION]]?.data && (
              <Tab
                onClick={handleClick}
                active={active === 4}
                id={4}
                style={{ margin: 0 }}
              >
                Application
              </Tab>
            )}
            {/* <Tab onClick={handleClick} active={active === 5} id={5}>
              Alerts
            </Tab> */}
          </Tabs>
        </Card>

        {/* attendance content */}
        <Content active={active === 0}>
          <div
            className="mgrid column_3by2"
            style={{ marginTop: "-20px", marginBottom: "20px" }}
          >
            <AttendanceChart
              title={"Attendance"}
              tooltip={"Displays attendance summary of the employee."}
            />

            <MonthlyAttendanceTrend
              title={"Monthly Attendance"}
              tooltip={
                "Displays monthly attendance of the employee. Hover over the dates for the total working time."
              }
            />
          </div>
          <Attendancetable
            identityId={
              users.user_map_data[userIds.length ? userIds[0] : identityId]
            }
          />
        </Content>

        {/* wellness360 content */}
        <Content active={active === 1}>
          <div
            className="mgrid column_3by2"
            style={{ marginTop: "-20px", marginBottom: "20px" }}
          >
            <WellnessOverallScore
              title={"Wellness"}
              tooltip={
                "Displays the number of days the employee was healthy, underutilized and overburdened."
              }
            />

            <MonthlyWellnessTrend
              title={"Monthly Wellness"}
              tooltip={
                "Displays the monthly wellness status of the employee. Hover over the dates for daily status."
              }
            />
          </div>
          <div className="modal_table">
            <WellnessTable />
          </div>
        </Content>

        {/* productive Content */}
        <Content active={active === 2}>
          <div className="mgrid column_3">
            {/* {numberstat.map((nitem, nindex) => {
              return (
                <NumberStats
                  key={nindex}
                  label={nitem.label}
                  value={nitem.value}
                  infotitle={nitem.infotitle}
                  bottomtext={nitem.bottomtext}
                />
              );
            })} */}
            <ApplicationTopApp
              key={1}
              label={"Top Application"}
              value={_.first(topAppData) ? _.first(topAppData).value : "-"}
              infotitle={
                "Displays the most used app by the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topAppData) ? _.first(topAppData).bottomtext : "-"
              }
              fulldata={_.first(topAppData) ? _.first(topAppData).data : "-"}
            />

            <ApplicationTopUrl
              key={2}
              label={"Top URL"}
              value={_.first(topUrlData) ? _.first(topUrlData).value : "-"}
              infotitle={
                "The most used URL of the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topUrlData) ? _.first(topUrlData).bottomtext : "-"
              }
              fulldata={_.first(topUrlData) ? _.first(topUrlData).data : "-"}
            />

            <ApplicationTopCategory
              key={3}
              label={"Top Category"}
              value={
                _.first(topCategoryData) ? _.first(topCategoryData).value : "-"
              }
              infotitle={
                "Displays the most used category of apps/URLs by the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topCategoryData)
                  ? _.first(topCategoryData).bottomtext
                  : "-"
              }
              fulldata={
                _.first(topCategoryData) ? _.first(topCategoryData).data : "-"
              }
            />
          </div>
          <div
            className="mgrid"
            style={{ marginTop: "0px", marginBottom: "20px" }}
          >
            <ProductivityBreakdown
              breakDownSummary={breakDownSummary && breakDownSummary}
            />
          </div>
          <div
            className="mgrid "
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            {/* <WorkingTimeTrends /> */}
            {/* <TeamwiseProductivityTrends /> */}
          </div>

          <div className="modal_table">
            <ProductivityTable
              employeeListData={
                employeeListDataProductivity && employeeListDataProductivity
              }
              duration={dateDiff}
            />
          </div>
        </Content>

        {/* activity content */}
        <Content active={active === 3}>
          <div className="mgrid column_3">
            <>
              <ApplicationTopApp
                key={1}
                label={"Top Application"}
                value={_.first(topAppData) ? _.first(topAppData).value : "-"}
                infotitle={
                  "Displays the most used app by the employee. Usage duration is also displayed."
                }
                bottomtext={
                  _.first(topAppData) ? _.first(topAppData).bottomtext : "-"
                }
                fulldata={_.first(topAppData) ? _.first(topAppData).data : "-"}
              />

              <ApplicationTopUrl
                key={2}
                label={"Top URL"}
                value={_.first(topUrlData) ? _.first(topUrlData).value : "-"}
                infotitle={
                  "The most used URL of the employee. Usage duration is also displayed."
                }
                bottomtext={
                  _.first(topUrlData) ? _.first(topUrlData).bottomtext : "-"
                }
                fulldata={_.first(topUrlData) ? _.first(topUrlData).data : "-"}
              />

              <ApplicationTopCategory
                key={3}
                label={"Top Category"}
                value={
                  _.first(topCategoryData)
                    ? _.first(topCategoryData).value
                    : "-"
                }
                infotitle={
                  "Displays the most used category of apps/URLs by the employee. Usage duration is also displayed."
                }
                bottomtext={
                  _.first(topCategoryData)
                    ? _.first(topCategoryData).bottomtext
                    : "-"
                }
                fulldata={
                  _.first(topCategoryData) ? _.first(topCategoryData).data : "-"
                }
              />
            </>
          </div>
          <div
            className="mgrid "
            style={{ marginTop: "0px", marginBottom: "20px" }}
          >
            <OnlineTimeBreakdown
              graphdata={
                activitysummary &&
                activitysummary.activity_summary_online_time_break_down_data
              }
            />
          </div>
          <div
            className="mgrid "
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            {/* <AWorkingTimeTrends /> */}
            {/* <TeamwiseActiveyTrends /> */}
          </div>
          <div className="modal_table">
            <ActivityTable
              employeeListData={employeeListData && employeeListData}
              duration={dateDiff}
            />
          </div>
        </Content>
        {/* Application content */}
        <Content active={active === 4}>
          <div className="mgrid column_3">
            {/* {appnumberstat.map((nitem, nindex) => {
              return (
                <NumberStats
                  key={nindex}
                  label={nitem.label}
                  value={nitem.value}
                  infotitle={nitem.infotitle}
                  bottomtext={nitem.bottomtext}
                />
              );
            })} */}
            <ApplicationTopApp
              key={1}
              label={"Top Application"}
              value={_.first(topAppData) ? _.first(topAppData).value : "-"}
              infotitle={
                "The most used app of the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topAppData) ? _.first(topAppData).bottomtext : "-"
              }
              fulldata={_.first(topAppData) ? _.first(topAppData).data : "-"}
            />

            <ApplicationTopUrl
              key={2}
              label={"Top URL"}
              value={_.first(topUrlData) ? _.first(topUrlData).value : "-"}
              infotitle={
                "The most used URL of the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topUrlData) ? _.first(topUrlData).bottomtext : "-"
              }
              fulldata={_.first(topUrlData) ? _.first(topUrlData).data : "-"}
            />

            <ApplicationTopCategory
              key={3}
              label={"Top Category"}
              value={
                _.first(topCategoryData) ? _.first(topCategoryData).value : "-"
              }
              infotitle={
                "Displays the most used category of apps/URLs by the employee. Usage duration is also displayed."
              }
              bottomtext={
                _.first(topCategoryData)
                  ? _.first(topCategoryData).bottomtext
                  : "-"
              }
              fulldata={
                _.first(topCategoryData) ? _.first(topCategoryData).data : "-"
              }
            />
          </div>
          <div
            className="mgrid column_3 app_charts"
            style={{ marginTop: "0px", marginBottom: "20px" }}
          >
            <CategoryUtilisation
              applicationCategoryWorkingHoursTime={
                ApplicationCategoryWorkingHoursTime
              }
              applicationCategoryList={ApplicationCategoryList}
              chartTitle={"Category Utilization"}
              legend={false}
              tooltipTitle={
                "Displays the time spent by the employee in apps/URLs of different productivity categories."
              }
              height={"260px"}
            />

            <TotalAppusage
              applicationAppWorkingHoursTime={ApplicationAppWorkingHoursTime}
              applicationAppCategoryList={ApplicationAppCategoryList}
              chartTitle={"Total Application usage"}
              tooltipTitle={
                "Displays the time spent by the employee in apps arranged in descending order of usage duration."
              }
              legend={false}
              height={"334px"}
            />

            <TotalYUrlusage
              applicationUrlWorkingHoursTime={ApplicationUrlWorkingHoursTime}
              applicationUrlCategoryList={ApplicationUrlCategoryList}
              chartTitle={"Total URL usage"}
              tooltipTitle={
                "Displays the time spent by the employee in URLs arranged in descending order of usage duration."
              }
              legend={false}
              height={"334px"}
            />
          </div>
          <div className=" " style={{ marginTop: "0px", marginBottom: "20px" }}>
            <AppUrlLogs />
          </div>
        </Content>
        <Content active={active === 5}>
          <Row gutter={20} style={{ marginTop: "-20px", marginBottom: "20px" }}>
            <Col className="gutter-row" xs={24} xl={8}></Col>
            <Col className="gutter-row" xs={24} xl={16}></Col>
          </Row>
        </Content>
      </div>
    </>
  );
};

UserDetail.propTypes = {
  getList: PropTypes.func,
};

export default UserDetail;
