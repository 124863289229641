//GET BREAKS

import * as actionTypes from "./actionTypes";

export const BreaksReportInitial = (token) => {
  return {
    type: actionTypes.BREAKS_REPORT_INITIAL,
    token,
  };
};

export const BreaksReportProcess = (token, bodyData) => {
  return {
    type: actionTypes.BREAKS_REPORT_PROCESS,
    token,
    bodyData,
  };
};
export const BreaksReportSuccess = (data) => {
  return {
    type: actionTypes.BREAKS_REPORT_SUCCESS,
    data,
  };
};
export const BreaksReportFailed = (data) => {
  return {
    type: actionTypes.BREAKS_REPORT_FAILED,
    data,
  };
};

export const BreaksDownloadReportInitial = (token) => {
  return {
    type: actionTypes.BREAKS_DOWNLOAD_REPORT_INITIAL,
    token,
  };
};

export const BreaksDownloadReportProcess = (token, bodyData) => {
  return {
    type: actionTypes.BREAKS_DOWNLOAD_REPORT_PROCESS,
    token,
    bodyData,
  };
};
export const BreaksDownloadReportSuccess = (data) => {
  return {
    type: actionTypes.BREAKS_DOWNLOAD_REPORT_SUCCESS,
    data,
  };
};
export const BreaksDownloadReportFailed = (data) => {
  return {
    type: actionTypes.BREAKS_DOWNLOAD_REPORT_FAILED,
    data,
  };
};
