import React, { useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { Skeleton, Tooltip, Empty } from "antd";

const Cardskeleton = (props) => {
  return (
    <div className="number_stats">
      <div className="number_block">
        <div>
          <div className="n_label">
            <Skeleton.Input
              size={"small"}
              shape={"default"}
              style={{ height: "10px" }}
            />
          </div>
          <div className="n_value">
            <Skeleton.Button
              size={"large"}
              shape={"default"}
              style={{ margin: "10px 0" }}
            />{" "}
          </div>
        </div>
        <div className="">
          <div className="more_i">
            <Skeleton.Avatar
              shape={"circle"}
              style={{ height: "12px", width: "12px" }}
            />
          </div>
        </div>
      </div>
      <div className="n_less">
        <Skeleton.Input
          size={"small"}
          shape={"default"}
          style={{ height: "15px" }}
        />
      </div>
    </div>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const ApplicationTopApp = (props) => {
  const { label, value, infotitle, bottomtext, fulldata } = props || {};

  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const dataname = value && value.length;

  return (
    <>
      {loading ? (
        <Cardskeleton />
      ) : (
        <div className="number_stats">
          <div className="number_block in">
            <div>
              <div className="n_label">{label}</div>

              {fulldata && fulldata.top_application_uses === null ? (
                <div
                  className="emptyblock"
                >
                  <Empty description={false} />
                </div>
              ) : (
                <div
                  className={dataname > 10 ? "n_value sn_value" : " n_value"}
                >
                  {value}
                </div>
              )}
            </div>
            <div className="n_icon">
              <Tooltip title={infotitle}>
                <InfoCircleFilled />
              </Tooltip>
            </div>
          </div>
          {fulldata && fulldata.top_application_uses === null ? null : (
            <div className="n_less">{bottomtext}</div>
          )}
        </div>
      )}
    </>
  );
};

ApplicationTopApp.propTypes = {
  getList: PropTypes.func,
};

export default ApplicationTopApp;
