import React, { useEffect } from "react";
import sLogoai from "../../assets/images/logo_ai.png";
import { redirectToPortal } from "utils/helper";
import { SIGNIN } from "route/constant";

const Forbidden = () => {
  useEffect(() => {
    setTimeout(() => {
      redirectToPortal(SIGNIN);
    }, 5000);
  }, []);

  return (
    <div className="auth_layout">
      <div className="signup_blcok setpass_blcok">
        <div className="s_top">
          <div className=" cir_logo">
            <div className="logo_ai">
              <img src={sLogoai} />
            </div>
          </div>
          <center>
            <div>We360.ai</div>
            <br />
            <div> Oops! Unauthorized access detected.</div>
          </center>
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
