import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const fetchTenantSettings = (payload) => {
  return {
    type: actionTypes.FETCH_TENANT_SETTINGS,
    payload,
  };
};

export const fetchTenantSettingsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TENANT_SETTINGS_SUCCESS,
    payload,
  };
};

export const fetchTenantSettingsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TENANT_SETTINGS_FAILED,
    payload,
  };
};

export const updateTenantSettings = (payload) => {
  return {
    type: actionTypes.UPDATE_TENANT_SETTINGS,
    payload,
  };
};

export const updateTenantSettingsSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_TENANT_SETTINGS_SUCCESS,
    payload,
  };
};

export const updateTenantSettingsFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_TENANT_SETTINGS_FAILED,
    payload,
  };
};

export const setTenantSettingsErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_TENANT_SETTINGS_ERROR_MESSAGE,
    payload,
  };
};
