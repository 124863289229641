import * as actionTypes from "./actionTypes";
import { ActivitySummaryAction } from "../action";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchSubscriptionsLoading: true,
  subscriptionsData: {},
  fetchSubscriptionSuccess: false,
  fetchSubscriptionDetail: false,
  fetchInvoicesLoading: true,
  invoicesList: [],
  billingErrorMessage: "",
  upgradeModalOpen: false,
  pagination: initialPagination,
  initiateSubscriptionLoading: false,
  planGroupData: [],
  planGroupLoading: false,
  sendInvoiceLoadingInvoiceID: null,
  initiateSubscriptionData: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        fetchSubscriptionsLoading: true,
        billingErrorMessage: "",
        fetchSubscriptionFailed: false,
      };

    case actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        fetchSubscriptionsLoading: false,
        subscriptionsData: action.payload,
        fetchSubscriptionSuccess: true,
      };
    }

    case actionTypes.FETCH_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        fetchSubscriptionsLoading: false,
        subscriptionsData: {},
        billingErrorMessage: action.payload,
        fetchSubscriptionFailed: true,
      };

    case actionTypes.FETCH_INVOICES:
      return {
        ...state,
        fetchInvoicesLoading: true,
        billingErrorMessage: "",
      };

    case actionTypes.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        fetchInvoicesLoading: false,
        invoicesList: action.payload.list.map((invoice) => invoice.invoice),
      };

    case actionTypes.FETCH_INVOICES_FAILED:
      return {
        ...state,
        fetchInvoicesLoading: false,
        invoicesList: [],
        billingErrorMessage: action.payload,
      };

    case actionTypes.SEND_INVOICE_VIA_EMAIL:
      return {
        ...state,
        sendInvoiceLoadingInvoiceID: action.payload.invoiceID,
        billingErrorMessage: "",
      };

    case actionTypes.SEND_INVOICE_VIA_EMAIL_SUCCESS:
      return {
        ...state,
        fetchInvoicesLoading: null,
      };

    case actionTypes.SEND_INVOICE_VIA_EMAIL_FAILED:
      return {
        ...state,
        fetchInvoicesLoading: null,
        billingErrorMessage: action.payload,
      };

    case actionTypes.INITIATE_SUBSCRIPTION:
      return {
        ...state,
        initiateSubscriptionLoading: true,
        billingErrorMessage: "",
        initiateSubscriptionData: null,
      };

    case actionTypes.INITIATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        initiateSubscriptionLoading: false,
        initiateSubscriptionData: action.payload,
      };

    case actionTypes.INITIATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        initiateSubscriptionLoading: false,
        initiateSubscriptionData: null,
        billingErrorMessage: action.payload,
      };

    case actionTypes.FETCH_PLAN_GROUP:
      return {
        ...state,
        planGroupLoading: true,
        billingErrorMessage: "",
        planGroupData: [],
      };

    case actionTypes.FETCH_PLAN_GROUP_SUCCESS: {
      return {
        ...state,
        planGroupLoading: false,
        planGroupData: action.payload.items.reduce(
          (acc, plan) => ({
            ...acc,
            [plan.plan_type.id]: {
              plan_type: plan.plan_type,
              plans: {
                ...acc[plan.plan_type.id]?.plans,
                [plan.amount ? plan.subscription_frequency : "free"]: plan,
              },
            },
          }),
          {}
        ),
      };
    }

    case actionTypes.FETCH_PLAN_GROUP_FAILED:
      return {
        ...state,
        planGroupLoading: false,
        planGroupData: [],
        billingErrorMessage: action.payload,
      };

    case actionTypes.SET_BILLING_ERROR_MESSAGE:
      return {
        ...state,
        billingErrorMessage: action.payload,
      };

    case actionTypes.ON_UPGRADE_MODAL_OPEN:
      return {
        ...state,
        upgradeModalOpen: true,
      };

    case actionTypes.ON_UPGRADE_MODAL_CLOSE:
      return {
        ...state,
        upgradeModalOpen: false,
      };

    default:
      return state;
  }
};
