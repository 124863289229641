function GetTreeNode(treelist, parentId, level, levels) {
  let nodeTree = null;
  if (level > levels) {
    return nodeTree;
  }
  nodeTree = treelist
    .filter(
      (trlist) =>
        trlist.parent?.id === parentId || (!parentId && !trlist.parent?.id)
    )
    .map((item) => ({
      title: item.name,
      value: item.id,
      children: GetTreeNode(treelist, item.id, level + 1, levels),
    }));

  return nodeTree;
}

function GetTreeNodeByKeys(treelist, parentId, level, levels) {
  let nodeTree = null;
  if (level > levels) {
    return nodeTree;
  }
  nodeTree = treelist
    .filter(
      (trlist) =>
        trlist.parent?.id === parentId || (!parentId && !trlist.parent?.id)
    )
    .map((item) => {
      const childNode = GetTreeNodeByKeys(treelist, item.id, level + 1, levels);
      return {
        key: item.id,
        title: item.name,
        children: (childNode || []).length === 0 ? null : childNode,
      };
    });

  return nodeTree;
}

export { GetTreeNode, GetTreeNodeByKeys };
