import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import { Layout, Typography, Tag, Space, Tooltip, Avatar } from "antd";

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";

import { Upload } from "antd";
import ImgCrop from "antd-img-crop";
import { getAvatarUrl } from "utils/transport";
import { useSelector } from "react-redux";
import { AuthContext } from "contexts/authContext";
const { Paragraph, Text, Title } = Typography;

const Profileinfo = (props) => {
  const { coreApiUrl } = useContext(AuthContext);
  const { UserDetaildata } = props;
  const { user_map_data } = useSelector((state) => state.users);

  const users = user_map_data[UserDetaildata?.id];

  const [ellipsis, setEllipsis] = useState(true);
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "Avatar",
      status: "done",
      url: getAvatarUrl(coreApiUrl, UserDetaildata?.id),
    },
  ]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div className="profileinfo">
      <div className="p_pic">
        <Avatar
          shape="square"
          size={64}
          icon={!users?.avatar_uri && <UserOutlined />}
          src={
            users?.avatar_uri === "@we360"
              ? getAvatarUrl(coreApiUrl, UserDetaildata?.id)
              : users?.avatar_uri
          }
        />
      </div>
      <div className="p_info">
        <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
          <Title
            className="mb-0"
            level={3}
            style={ellipsis ? { width: "90%", "font-size": "20px" } : undefined}
            ellipsis={
              ellipsis
                ? {
                    tooltip:
                      UserDetaildata &&
                      UserDetaildata.first_name +
                        " " +
                        UserDetaildata.last_name,
                  }
                : false
            }
          >
            {UserDetaildata &&
              UserDetaildata.first_name + " " + UserDetaildata.last_name}
          </Title>
          <Text
            style={ellipsis ? { width: "90%" } : undefined}
            ellipsis={
              ellipsis
                ? { tooltip: UserDetaildata && UserDetaildata.email }
                : false
            }
          >
            {/* allisongouse@gmail.com */}
            {UserDetaildata && UserDetaildata.email}
          </Text>
          {/* <div className="flex item-center">
            <Tooltip title={"Role"}>
              <Tag color="processing" style={{ textAlign: "center" }}>
                Marketing
                {"-"}
              </Tag>
            </Tooltip>
            <Tooltip title={"Designation"}>
              <Tag color="success" style={{ textAlign: "center" }}>
                UI/Ux Desinger
                {"-"}
              </Tag>
            </Tooltip>
          </div> */}
        </Space>
      </div>
    </div>
  );
};

Profileinfo.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default Profileinfo;
