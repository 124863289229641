import * as actionType from "./actionTypes";

export const SignupCreateInitial = () => {
  return {
    type: actionType.SIGNUP_CREATE_INITIAL,
  };
};

export const SignupCreateProcess = (data) => {
  return {
    type: actionType.SIGNUP_CREATE_PROCESS,
    data,
  };
};

export const SignupCreateSuccess = (data) => {
  return {
    type: actionType.SIGNUP_CREATE_SUCCESS,
    data,
  };
};

export const SignupCreateFailed = (data) => {
  return {
    type: actionType.SIGNUP_CREATE_FAILED,
    data,
  };
};

export const SignupVerifyTokenInitial = () => {
  return {
    type: actionType.SIGNUP_VERIFY_TOKEN_INITIAL,
  };
};

export const SignupVerifyTokenProcess = (data) => {
  return {
    type: actionType.SIGNUP_VERIFY_TOKEN_PROCESS,
    data,
  };
};

export const SignupVerifyTokenSuccess = (data) => {
  return {
    type: actionType.SIGNUP_VERIFY_TOKEN_SUCCESS,
    data,
  };
};

export const SignupVerifyTokenFailed = (data) => {
  return {
    type: actionType.SIGNUP_VERIFY_TOKEN_FAILED,
    data,
  };
};

export const SignupGoogleVerifyTokenInitial = () => {
  return {
    type: actionType.SIGNUP_GOOGLE_VERIFY_TOKEN_INITIAL,
  };
};

export const SignupGoogleVerifyTokenProcess = (data) => {
  return {
    type: actionType.SIGNUP_GOOGLE_VERIFY_TOKEN_PROCESS,
    data,
  };
};

export const SignupGoogleVerifyTokenSuccess = (data) => {
  return {
    type: actionType.SIGNUP_GOOGLE_VERIFY_TOKEN_SUCCESS,
    data,
  };
};

export const SignupGoogleVerifyTokenFailed = (data) => {
  return {
    type: actionType.SIGNUP_GOOGLE_VERIFY_TOKEN_FAILED,
    data,
  };
};

export const SignupGetTokenInitial = () => {
  return {
    type: actionType.SIGNUP_GET_TOKEN_INITIAL,
  };
};

export const SignupGetTokenProcess = (data) => {
  return {
    type: actionType.SIGNUP_GET_TOKEN_PROCESS,
    data,
  };
};

export const SignupGetTokenSuccess = (data) => {
  return {
    type: actionType.SIGNUP_GET_TOKEN_SUCCESS,
    data,
  };
};

export const SignupGetTokenFailed = (data) => {
  return {
    type: actionType.SIGNUP_GET_TOKEN_FAILED,
    data,
  };
};

export const SignupSetPasswordInitial = () => {
  return {
    type: actionType.SIGNUP_SET_PASSWORD_INITIAL,
  };
};

export const SignupSetPasswordProcess = (data) => {
  return {
    type: actionType.SIGNUP_SET_PASSWORD_PROCESS,
    data,
  };
};

export const SignupSetPasswordSuccess = (data) => {
  return {
    type: actionType.SIGNUP_SET_PASSWORD_SUCCESS,
    data,
  };
};

export const SignupSetPasswordFailed = (data) => {
  return {
    type: actionType.SIGNUP_SET_PASSWORD_FAILED,
    data,
  };
};

export const SetDomainInitial = () => {
  return {
    type: actionType.SET_DOMAIN_INITIAL,
  };
};

export const SetDomainProcess = (data) => {
  return {
    type: actionType.SET_DOMAIN_PROCESS,
    data,
  };
};

export const SetDomainSuccess = (data) => {
  return {
    type: actionType.SET_DOMAIN_SUCCESS,
    data,
  };
};

export const SetDomainFailed = (data) => {
  return {
    type: actionType.SET_DOMAIN_FAILED,
    data,
  };
};

export const CheckDomainInitial = () => {
  return {
    type: actionType.CHECK_DOMAIN_INITIAL,
  };
};

export const CheckDomainProcess = (data) => {
  return {
    type: actionType.CHECK_DOMAIN_PROCESS,
    data,
  };
};

export const CheckDomainSuccess = (data) => {
  return {
    type: actionType.CHECK_DOMAIN_SUCCESS,
    data,
  };
};

export const CheckDomainFailed = (data) => {
  return {
    type: actionType.CHECK_DOMAIN_FAILED,
    data,
  };
};

export const SetResendEmailInitial = () => {
  return {
    type: actionType.SET_RESEND_EMAIL_INITIAL,
  };
};

export const SetResendEmailProcess = (data) => {
  return {
    type: actionType.SET_RESEND_EMAIL_PROCESS,
    data,
  };
};

export const SetResendEmailSuccess = (data) => {
  return {
    type: actionType.SET_RESEND_EMAIL_SUCCESS,
    data,
  };
};

export const SetResendEmailFailed = (data) => {
  return {
    type: actionType.SET_RESEND_EMAIL_FAILED,
    data,
  };
};
