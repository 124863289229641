/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { customKeyCloakConfig } from "../utils/auth/keycloack";
import { useDispatch, useSelector } from "react-redux";
import { usePostHog } from "posthog-js/react";
import axios from "axios";
import _ from "lodash";
import {
  TenantAction,
  UserAction,
  Wellness360MasterAction,
} from "redux/store/action";
import {
  BILLING,
  BILLING_ALLOWED_ROUTES,
  DASHBOARD,
  ONBOARDING,
  FORBIDDEN,
  ME,
  PORTAL_ALLOWED_ROUTES,
  REDIRECT_TO_PORTAL,
  RESERVED_DOMAINS,
  SETTINGS,
  SIGNIN,
  SIGNUP_INITIATE,
  SIGNUP_PHASE_8,
} from "route/constant";
import { redirectToPortal } from "utils/helper";
import {
  extractRoles,
  Client,
  AuthClient,
  raiseTicketURL,
} from "utils/transport";
import { roles, rolesPermissionLevel } from "constants/constant";
import { notification } from "antd";
import sLogoai from "../assets/images/logo_ai.png";
import {
  fetchPlanGroup,
  fetchSubscriptions,
  onUpgradeModalOpen,
  setBillingErrorMessage,
} from "redux/store/billing/action";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import UserDetail from "components/UserDetail";
import { fetchTaskActivities } from "redux/store/task-activities/action";
import GlobalTaskTimer from "common/GlobalTaskTimer";

export const AuthContext = React.createContext();

const AuthContextProvider = ({
  children,
  authRoute,
  requiredRole = roles.USER,
  homePage,
}) => {
  const navigate = useNavigate();
  var config = {};
  const dispatch = useDispatch();
  const posthog = usePostHog();
  const { medetails_failed, medetails_success, medetails_data } = useSelector(
    (state) => state.users
  );
  const { tenant_list_data, tenant_list_success } = useSelector(
    (state) => state.tenant
  );
  const { billingErrorMessage, fetchSubscriptionSuccess, subscriptionsData } =
    useSelector((state) => state.billing);
  const wellness360data = useSelector((state) => state.wellness360);

  const { open, startDate, endDate, tab, identityId } = useSelector(
    (state) => state.userDetail
  );
  const [baseCred, setBaseCred] = useState({});
  const [roleProcessingDone, setRoleProcessingDone] = useState(false);
  const [apiUrlsSetupDone, setApiUrlsSetupDone] = useState(false);
  const [authKeycloakUrl, setAuthKeycloakUrl] = useState(
    process.env.REACT_APP_AUTH_ENDPOINT
  );
  const [coreApiUrl, setCoreApiUrl] = useState(
    process.env.REACT_APP_CONTROL_ENDPOINT
  );
  const [keycloakRealm, setKeycloakRealm] = useState(
    process.env.REACT_APP_AUTH_REALM
  );

  const [errorKeycloak, setErrorKeycloak] = useState(false);
  const [KState, setKState] = useState({
    keycloak: null,
    authenticated: false,
  });
  const [userInfo, setuserInfo] = useState();
  const [role, setRole] = useState(roles.USER);

  const getTenantByID = async (tenantID) => {
    return axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        "discover/tenant_by_id?id=" +
        tenantID,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const getTenant = async (data) => {
    return axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        "discover/tenant_by_base_domain?baseDomain=" +
        data,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const fetchApiUrls = async () => {
    const params = new URLSearchParams(window.location.search.substring(1));
    const env = params.get("env") || process.env.REACT_APP_APP_ENV;

    return axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        `discover/shards_by_environment?environment=${env}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const fetchApiUrlsByShardId = async (shardID) => {
    return axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        `discover/shards/${shardID}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const fetchActiveTimer = (identityID) => {
    if (identityID) {
      dispatch(
        fetchTaskActivities({
          headers: getDefaultHeader(),
          body: {
            equals: {
              "created_by.id": identityID,
            },
            isNotNull: ["start_time"],
            isNull: ["end_time"],
            hints: {
              "equals.created_by.id": "uuid",
            },
          },
          isTimer: true,
        })
      );
    }
  };

  useEffect(() => {
    if (medetails_failed) {
      KeyCloaklogout(FORBIDDEN);
      dispatch(UserAction.MeDetailInitial());
    } else if (medetails_success) {
      if (
        (!medetails_data.tracking_settings.portal_access &&
          role !== roles.OWNER) ||
        !medetails_data.identity.is_active
      ) {
        KeyCloaklogout(FORBIDDEN);
      }
      const attachedGroupRole = medetails_data.attached_group_role;
      if (
        attachedGroupRole === "OWNER" &&
        rolesPermissionLevel[role] < rolesPermissionLevel[roles.MANAGER]
      ) {
        setRole(roles.MANAGER);
      }
      setRoleProcessingDone(true);
      fetchActiveTimer(medetails_data?.identity?.id);
    }
  }, [medetails_failed, medetails_success]);

  useEffect(() => {
    if (medetails_success && tenant_list_success && roleProcessingDone) {
      posthog?.identify(medetails_data.identity?.id, {
        // name: `${medetails_data.identity?.first_name} ${medetails_data.identity?.last_name}`,
        email: medetails_data.identity?.email,
        // gender: medetails_data.gender,
        // phone_number: medetails_data.phone_number,
        // date_of_birth: medetails_data.date_of_birth,
        // date_of_joining: medetails_data.date_of_joining,
        tenant_id: tenant_list_data.id,
        tenant_name: tenant_list_data.name,
        tenant_base_domain: tenant_list_data.base_domain,
        // tenant_created_date: tenant_list_data.created_date,
        // tenant_modified_date: tenant_list_data.modified_date,
        // designation: medetails_data.designation?.id,
        // attached_group_role: medetails_data.attached_group_role,
        // attached_group: medetails_data.attached_group?.id,
        // application_policy:
        //   medetails_data.attached_group?.application_policy?.id,
        // leave_policy: medetails_data.attached_group?.leave_policy?.id,
        // ...medetails_data.attached_group?.tracking_settings,
      });

      window.fcWidget.setExternalId(medetails_data.identity?.id);

      window.fcWidget.user.setProperties({
        externalId: medetails_data.identity?.id,
        firstName: medetails_data.identity?.first_name,
        lastName: medetails_data.identity?.last_name,
        email: medetails_data.identity?.email,
        cf_role: role,
        cf_company: tenant_list_data.name,
        cf_tenant_name: tenant_list_data.name,
        cf_tenant_id: tenant_list_data.id,
        cf_tenant_base_domain: tenant_list_data.base_domain,
      });
      window.fcWidget.user.setMeta({});
    }
  }, [medetails_success, tenant_list_success, roleProcessingDone]);

  const getBaseDomain = () => {
    let subdomain = null;
    const data = window.location.hostname.split(".");

    if (data.length > 2) {
      subdomain = data[0];
      if (RESERVED_DOMAINS.includes(subdomain)) {
        if (REDIRECT_TO_PORTAL.includes(subdomain)) {
          redirectToPortal();
        } else if (!PORTAL_ALLOWED_ROUTES.includes(window.location.pathname)) {
          navigate(SIGNUP_INITIATE);
        }
        return null;
      }
    } else {
      redirectToPortal();
    }
    return subdomain;
  };

  const getBaseCred = (subdomain, redirectPath) => {
    const checkBaseDomain = subdomain || getBaseDomain();

    if (checkBaseDomain) {
      getTenant(checkBaseDomain)
        .then((rs) => {
          if (rs.status == 200) {
            const responseSave = rs.data;
            // localStorage.setItem("rawData", JSON.stringify(rs.data))
            localStorage.setItem("TenantIdByBaseDomain", responseSave.tenantId);
            localStorage.setItem(
              "headers",
              JSON.stringify(responseSave.headers || {})
            );

            Client.defaults.baseURL = responseSave.apiGatewayUrl;
            AuthClient.defaults.baseURL = `${responseSave.apiKeycloakUrl}/realms/${responseSave.apiKeycloakRealm}/protocol/openid-connect`;
            Client.defaults.headers.post = responseSave.headers || {};

            setCoreApiUrl(responseSave.apiGatewayUrl);
            setAuthKeycloakUrl(responseSave.apiKeycloakUrl);
            setKeycloakRealm(responseSave.apiKeycloakRealm);
            setApiUrlsSetupDone(true);

            setBaseCred({ ...responseSave, redirectPath }); //setting subdomain triggers kc()
          } else {
            console.log("Base domain not found!", rs);

            redirectToPortal();
          }
        })
        .catch((er) => {
          console.log("Base domain not found!", er);

          redirectToPortal();
        });
    } else if (
      window.location.pathname === SIGNUP_INITIATE ||
      window.location.pathname === SIGNUP_PHASE_8
    ) {
      fetchApiUrls()
        .then((response) => {
          const length = response.data.length;
          const randomIndex = Math.ceil(Math.random() * length) - 1;
          const urlConfig = response.data[randomIndex];
          Client.defaults.baseURL = urlConfig.api_gateway_url;
          AuthClient.defaults.baseURL = `${urlConfig.keycloak_url}/realms/${urlConfig.keycloak_realm}/protocol/openid-connect`;
          Client.defaults.headers.post = urlConfig.headers || {};

          setCoreApiUrl(urlConfig.api_gateway_url);
          setAuthKeycloakUrl(urlConfig.keycloak_url);
          setKeycloakRealm(urlConfig.keycloak_realm);
          setApiUrlsSetupDone(true);
        })
        .catch((err) => {
          console.log(err);
          notification.error({ message: SOMETHING_WENT_WRONG });
        });
    }
  };

  useEffect(() => {
    getBaseCred();
  }, []);

  //check this and uncomment
  useEffect(() => {
    if (baseCred.tenantId && apiUrlsSetupDone) {
      kc();
    }
  }, [baseCred, apiUrlsSetupDone]);

  // client token append
  useEffect(() => {
    if (KState.keycloak) {
      Client.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${KState.keycloak.token}`;
        return config;
      });
    }
  }, [KState]);

  const getRedirectURI = () => {
    const hostArray = window.location.hostname.split(".");
    hostArray[0] = baseCred.baseDomain;
    let pathName = window.location.pathname;

    if (!pathName || PORTAL_ALLOWED_ROUTES.includes(pathName)) {
      pathName = baseCred.redirectPath || "/";
    }

    return `${window.location.protocol}//${hostArray.join(".")}${
      process.env.NODE_ENV === "development"
        ? `:${process.env.REACT_APP_PORT}`
        : ""
    }${pathName}`;
  };

  const setCookies = (token = KState?.keycloak?.token) => {
    const date = new Date(); // Now
    date.setDate(date.getDate() + 30);

    const domainAndPort = window.location.origin
      .split(".")
      .slice(-2)
      .join(".")
      .split(":");
    document.cookie = `token=${token};expires=${date};Domain=.${
      domainAndPort[0]
    }${domainAndPort.length < 2 ? ";secure" : ""}`;
  };

  const kc = () => {
    if (baseCred.tenantId && apiUrlsSetupDone) {
      const baseCred_tenantID = baseCred.tenantId;

      try {
        config = customKeyCloakConfig(
          baseCred_tenantID,
          authKeycloakUrl,
          keycloakRealm
        );
        const options = {
          onLoad: "login-required",
          checkLoginIframe: false,
        };

        options.redirectUri = getRedirectURI();

        // var url = window.location.href.split(baseCred.baseDomain+".").join("")+"login/callback";
        config
          .init(options)
          // .login({redirectUrl:url})
          .then((authenticated) => {
            setKState({ keycloak: config, authenticated });
            const extractedRole = extractRoles(config.token);
            setRole(extractedRole);
            if (extractedRole === roles.OWNER) {
              dispatch(
                fetchPlanGroup({
                  headers: { Authorization: `Bearee ${config.token}` },
                })
              );
            }

            const data = {
              clientId: config.clientId,
              token: config.token,
            };

            setCookies(config.token);

            dispatch(UserAction.MeDetailProcess(data));
            dispatch(
              fetchSubscriptions({
                headers: { Authorization: `Bearer ${config.token}` },
              })
            );
            dispatch(TenantAction.TenantListProcess(data));

            dispatch(Wellness360MasterAction.Wellness360ListProcess(data));

            if (authenticated) {
              userDetails();
            }
          })
          .catch((ex) => {
            setErrorKeycloak(true);
          });
      } catch (ex) {
        console.log("Error in CLient id", ex);
      }
    }
  };

  useEffect(() => {
    config.onTokenExpired = () => {
      config
        .updateToken(50)
        .then((refreshed) => {
          if (refreshed) {
            setKState({ keycloak: config, authenticated: refreshed });
            setCookies(config.token);
          }
        })
        .catch((ex) => {
          setErrorKeycloak(true);
          console.error("Failed to refresh token " + new Date() + ex);
        });
    };
  }, [config]);

  const userDetails = () => {
    config
      .loadUserInfo()
      .then((userInfo) => {
        if (userInfo) {
          setuserInfo(userInfo);
        }
      })
      .catch((ex) => {
        console.log("Error in loading profile", ex);
      });
  };

  const keyCloakLogin = (subdomain, redirectPath = "/") => {
    try {
      return getBaseCred(subdomain, redirectPath);
    } catch (e) {
      console.error("Keycloak_login Authentication error", e);
    }
  };

  const KeyCloaklogout = (path = SIGNIN) => {
    if (KState.keycloak) {
      KState.keycloak.createLogoutUrl = function () {
        return (
          KState.keycloak.endpoints.logout() +
          "?id_token_hint=" +
          KState.keycloak.idToken +
          "&post_logout_redirect_uri=" +
          encodeURIComponent(`${window.location.origin}${path}`)
        );
      };
      KState.keycloak.logout({
        redirectUri: encodeURIComponent(window.location.href),
      });
    }
  };

  useEffect(() => {
    if (KState.authenticated && roleProcessingDone) {
      if (homePage) {
        if (role === roles.USER) {
          navigate(ME);
        } else {
          navigate(DASHBOARD);
        }
      } else if (
        rolesPermissionLevel[requiredRole] > rolesPermissionLevel[role]
      ) {
        notification.warning({
          message: "Unauthorized Access!!",
          description:
            "You don't have the permission to access that page, please contact your admin",
        });

        navigate(ME);
      }
    }

    if (roleProcessingDone) {
      const data = {
        clientId: KState.keycloak.clientId,
        token: KState.keycloak.token,
        role,
      };
      dispatch(UserAction.UserListProcess(data));
    }
  }, [requiredRole, roleProcessingDone, KState.authenticated]);

  useEffect(() => {
    if (
      fetchSubscriptionSuccess &&
      wellness360data.wellness360_list_success &&
      subscriptionsData?.activeUsers < 4 &&
      roleProcessingDone &&
      role === roles.OWNER &&
      !(
        wellness360data?.wellness360_list_data?.prompts[
          "tenant:onboard_organization_Setting"
        ] &&
        wellness360data?.wellness360_list_data?.prompts[
          "tenant:onboard_complete"
        ]
      )
    ) {
      navigate(ONBOARDING);
    }
  }, [
    roleProcessingDone,
    fetchSubscriptionSuccess,
    subscriptionsData,
    wellness360data.wellness360_list_success,
  ]);

  const getDefaultHeader = () => {
    if (KState.keycloak) {
      return { Authorization: `Bearer ${KState.keycloak.token}` };
    }
    return {};
  };

  const getDomainAvailability = async (value) => {
    return axios({
      // Endpoint to send files
      url:
        process.env.REACT_APP_CONTROL_CENTER_ENDPOINT +
        "discover/is_domain_available?baseDomain=" +
        value,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        // Add any auth token here
        ContentType: "application/json",
      },
    });
  };

  const doesDomainExist = async (value) => {
    try {
      const { data } = await getDomainAvailability(value);

      if (data.success) {
        return Promise.reject(new Error("Domain does not exist!!"));
      }

      return Promise.resolve();
    } catch (e) {
      notification.error({
        message: (
          <div>
            Something went wrong, please contact support{" "}
            <a href={raiseTicketURL} target="_blank" rel="noreferrer">
              here
            </a>
          </div>
        ),
      });
    }
  };

  const isDomainAvailable = async (value) => {
    try {
      const { data } = await getDomainAvailability(value);

      if (data.success) {
        return Promise.resolve();
      }

      return Promise.reject(new Error("Domain not available!!"));
    } catch (e) {
      notification.error({
        message: (
          <div>
            Something went wrong please contact support{" "}
            <a href={raiseTicketURL} target="_blank" rel="noreferrer">
              here
            </a>
          </div>
        ),
      });
    }
  };

  useEffect(() => {
    if (billingErrorMessage) {
      notification.error({
        message: "Uh Oh!",
        description: billingErrorMessage,
      });
      dispatch(setBillingErrorMessage(""));
    }
  }, [billingErrorMessage]);

  useEffect(() => {
    if (
      subscriptionsData &&
      !BILLING_ALLOWED_ROUTES.includes(window.location.pathname)
    ) {
      if (new Date(subscriptionsData.expiryDate).getTime() < Date.now()) {
        if (role === roles.OWNER) {
          notification.warning({
            message:
              "Your existing subscription has expired, Please join a plan to continue!",
          });
          navigate(BILLING);
          dispatch(onUpgradeModalOpen());
        } else {
          notification.warning({
            message:
              "Your existing subscription has expired, Please conatct your admin",
          });
        }
      }
      if (subscriptionsData.activeUsers > subscriptionsData.totalAllowedUsers) {
        if (role === roles.OWNER) {
          notification.warning({
            message:
              "You have exceeded your license limit, Please deactivate some users or add more licenses to continue",
          });
          navigate(SETTINGS);
        } else {
          notification.warning({
            message:
              "Your organization have exceeded their license limit, Please conatct your admin",
          });
        }
      }
    }
  }, [subscriptionsData, role, window.location.pathname]);

  const getDefaultScreenText = () => {
    if (role === roles.OWNER) {
      if (new Date(subscriptionsData.expiryDate).getTime() < Date.now()) {
        return "Your existing subscription has expired, Please join a plan to continue!";
      }
      if (subscriptionsData.activeUsers > subscriptionsData.totalAllowedUsers) {
        return "You have exceeded your license limit, Please deactivate some users or add more licenses to continue";
      }
    } else {
      if (new Date(subscriptionsData.expiryDate).getTime() < Date.now()) {
        return "Your existing subscription has expired, Please contact your admin";
      }
      if (subscriptionsData.activeUsers > subscriptionsData.totalAllowedUsers) {
        return "Your organization have exceeded their license limit, Please conatct your admin";
      }
    }

    return "A step towards productivity";
  };

  useEffect(() => {
    if (errorKeycloak) {
      kc();
    }
  }, [errorKeycloak]);

  return (
    <AuthContext.Provider
      value={{
        keyCloakLogin,
        getTenant,
        getTenantByID,
        fetchApiUrlsByShardId,
        KState,
        userInfo,
        KeyCloaklogout,
        role,
        getDefaultHeader,
        setCookies,
        isDomainAvailable,
        apiUrlsSetupDone,
        authKeycloakUrl,
        coreApiUrl,
        keycloakRealm,
        doesDomainExist,
      }}
    >
      {(fetchSubscriptionSuccess &&
        (new Date(subscriptionsData.expiryDate).getTime() > Date.now() ||
          BILLING_ALLOWED_ROUTES.includes(window.location.pathname)) &&
        (subscriptionsData.activeUsers <= subscriptionsData.totalAllowedUsers ||
          BILLING_ALLOWED_ROUTES.includes(window.location.pathname)) &&
        KState.authenticated &&
        roleProcessingDone &&
        rolesPermissionLevel[requiredRole] <= rolesPermissionLevel[role]) ||
      authRoute ? (
        children
      ) : (
        <center>
          <div className="auth_layout">
            <div
              className="signup_blcok setpass_blcok"
              style={{ marginTop: "-80px" }}
            >
              <div
                className="s_top"
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "center",
                  border: "0px solid #f00",
                }}
              >
                <div className=" cir_logo">
                  <div className="logo_ai">
                    <img src={sLogoai} />
                  </div>
                </div>
                <center style={{ marginTop: "25px" }}>
                  <div style={{ marginBottom: "15px", fontWeight: "600" }}>
                    We360.ai
                  </div>

                  <div>{getDefaultScreenText()}</div>
                </center>
              </div>
            </div>
          </div>
        </center>
      )}
      {open && (
        <UserDetail
          open={open}
          identityId={identityId}
          sDate={startDate}
          eDate={endDate}
          tab={tab}
        />
      )}
      <GlobalTaskTimer />
    </AuthContext.Provider>
  );
};
export default AuthContextProvider;
