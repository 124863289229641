import axios from "axios";
import logger from "../logs";
import meta from "./meta.json";
import { roles } from "constants/constant";

const Client = axios.create({
  baseURL: process.env.REACT_APP_CONTROL_ENDPOINT,
  timeout: 60000,
  headers: { ...JSON.parse(localStorage.getItem("headers") || "{}") },
});

const RawHttpClient = axios.create({
  baseURL: process.env.REACT_APP_CONTROL_ENDPOINT,
  timeout: 1200000,
  headers: { "Content-Type": "application/octet-stream" },
  responseType: "blob",
});

const ContcrolClient = axios.create({
  baseURL: process.env.REACT_APP_CONTROL_CENTER_ENDPOINT,
  timeout: 60000,
});

const AuthClient = axios.create({
  baseURL: process.env.REACT_APP_KEYCLOAK_BASE_URL,
  timeout: 60000,
});

//interceptor to parse the request and attach endpoint
Client.interceptors.request.use(
  function (config) {
    // logger.log("Request interceptors", config)
    // Do something before request is sent
    // config.headers.Authorization = `Bearer ${getToken()}`
    // config.method = `POST`;
    config.url = getEndpoint(config.url);
    // config.url= "v2/UserProfileApi"

    // logger.log("Modified Request interceptors", config);
    return config;
  },
  function (error) {
    // Do something with request error
    logger.error("Error in axios client", error);
    return Promise.reject(error);
  }
);

ContcrolClient.interceptors.request.use(
  function (config) {
    // logger.log("Request interceptors", config)
    // Do something before request is sent
    // config.headers.Authorization = `Bearer ${getToken()}`
    // config.method = `POST`;
    config.url = getEndpoint(config.url);
    // config.url= "v2/UserProfileApi"

    // logger.log("Modified Request interceptors", config);
    return config;
  },
  function (error) {
    // Do something with request error
    logger.error("Error in axios client", error);
    return Promise.reject(error);
  }
);

//interceptor to detect if error occurred and check if need to log
ContcrolClient.interceptors.response.use(
  function (response) {
    // If the request succeeds, we don't have to do anything and just return the response
    // logger.log("Axios response in interceptor", response);
    return response;
  },
  function (error) {
    logger.error("Axios response error in interceptor", error);
    // const errorResponse = error.response
    // if (isTokenExpiredError(errorResponse)) {
    // return resetTokenAndReattemptRequest(error)
    // }
    // If the error is due to other reasons, we just throw it back to axios
    return Promise.reject(error);
  }
);

function getEndpoint(endpoint_constant) {
  let endpoint = meta[endpoint_constant];
  if (!endpoint) {
    return endpoint_constant;
  }
  return endpoint;
}

const getGoogleURL = (authKeycloakUrl, keycloakRealm, origin) =>
  `${authKeycloakUrl}/realms/${keycloakRealm}/protocol/openid-connect/auth?client_id=signup&redirect_uri=${origin}%2Fcomplete-google-setup&response_type=code&scope=openid&kc_idp_hint=google`;

export const getWebsocketPullEndpoint = (coreApiUrl) =>
  `${coreApiUrl}/websockets/subscribe/me_public`.replace("http", "ws");

export const getLogoUrl = (coreApiUrl) =>
  `${coreApiUrl}/api/v1/me/tenant_logo/`;

export const getAvatarUrl = (coreApiUrl, identityID) =>
  `${coreApiUrl}/api/v1/me/avatars/${identityID}`;

export const getScreenshotUrl = (coreApiUrl, identityID, imagePath) =>
  `${coreApiUrl}/api/v1/analytics/screenshots/${identityID}/single/?imageId=${imagePath}`;

export const getAttachmentUrl = (coreApiUrl, taskID, attachmentId) =>
  `${coreApiUrl}/api/v1/tasks/${taskID}/attachments/${attachmentId}`;

export const getScreenshotUrlThumbnail = (coreApiUrl, identityID, imagePath) =>
  `${coreApiUrl}/api/v1/analytics/screenshots/${identityID}/thumbs/?imageId=${imagePath}`;

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

const extractRoles = (token) => {
  try {
    const { resource_access, azp } = JSON.parse(atob(token.split(".")[1]));
    if (
      resource_access?.[azp]?.roles &&
      resource_access[azp].roles.includes(roles.OWNER)
    ) {
      return roles.OWNER;
    }
  } catch (e) {
    console.error(e);
  }
  return roles.USER;
};

const getCountryFromIP = async () => {
  try {
    const { data } = await axios.get("https://ipapi.co/json/");
    return data.country_name;
  } catch (error) {
    console.log(error);
  }
  return "";
};

const getInfoFromIP = async (ip) => {
  try {
    const { data } = await axios.get(`https://ipapi.co/${ip}/json/`);
    return data;
  } catch (error) {
    console.log(error);
  }

  return { ip };
};

export const raiseTicketURL =
  "https://we360v2.freshdesk.com/support/tickets/new";

export {
  Client,
  RawHttpClient,
  ContcrolClient,
  AuthClient,
  getGoogleURL,
  parseJwt,
  extractRoles,
  getCountryFromIP,
  getInfoFromIP,
};
