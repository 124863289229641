import React from "react";

export const Livestream = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="currentColor"
    >
      <path d="M8.8,8.7c-0.7,0.7-0.7,1.9,0,2.6c0.7,0.7,1.9,0.7,2.6,0c0.7-0.7,0.7-1.9,0-2.6C10.6,8,9.5,8,8.8,8.7z" />
      <path
        d="M13.9,6.1c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3c1.4,1.4,1.4,3.7,0,5.1c-0.4,0.4-0.4,0.9,0,1.3
		c0.2,0.2,0.4,0.3,0.6,0.3c0.2,0,0.5-0.1,0.6-0.3C16,11.7,16,8.3,13.9,6.1z"
      />
      <path
        d="M7.5,6.1c-0.4-0.4-0.9-0.4-1.3,0c-1,1-1.6,2.4-1.6,3.9s0.6,2.8,1.6,3.9c0.2,0.2,0.4,0.3,0.6,0.3
		c0.2,0,0.5-0.1,0.6-0.3c0.4-0.4,0.4-0.9,0-1.3c-1.4-1.4-1.4-3.7,0-5.1C7.8,7.1,7.8,6.5,7.5,6.1z"
      />
      <path
        d="M16.5,3.6c-0.4-0.4-0.9-0.4-1.3,0c-0.4,0.4-0.4,0.9,0,1.3c2.8,2.8,2.8,7.4,0,10.3c-0.4,0.4-0.4,0.9,0,1.3
		c0.2,0.2,0.8,0.6,1.3,0C19.7,12.6,20,7.1,16.5,3.6z"
      />
      <path
        d="M4.9,4.9c0.4-0.4,0.4-0.9,0-1.3C4.6,3.2,4,3.2,3.6,3.6c-3.5,3.5-3.5,9.2,0,12.8c0.5,0.5,1.1,0.2,1.3,0
		c0.4-0.4,0.4-0.9,0-1.3C2.1,12.3,2.1,7.7,4.9,4.9z"
      />
    </svg>
  );
};
