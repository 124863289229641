import * as actionTypes from "./actionTypes";

//* LIST *//

export const Wellness360ListInitital = () => {
  return {
    type: actionTypes.WELLNESS360_LIST_INITIAL,
  };
};
export const Wellness360ListProcess = (data) => {
  return {
    type: actionTypes.WELLNESS360_LIST_PROCESS,
    data,
  };
};

export const Wellness360ListSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_LIST_SUCCESS,
    data,
  };
};

export const Wellness360ListFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_LIST_FAILED,
    data,
  };
};

//* CREATE *//

export const WellnessCreateInitial = () => {
  return {
    type: actionTypes.WELLNESS360_CREATE_INITIAL,
  };
};
export const WellnessCreateProcess = (data) => {
  return {
    type: actionTypes.WELLNESS360_CREATE_PROCESS,
    data,
  };
};
export const WellnessCreateSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_CREATE_SUCCESS,
    data,
  };
};
export const WellnessCreateFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_CREATE_FAILED,
    data,
  };
};

//* EDIT *//

export const WellnessEditInitial = () => {
  return {
    type: actionTypes.WELLNESS360_EDIT_INITIAL,
  };
};
export const WellnessEditProcess = (data) => {
  return {
    type: actionTypes.WELLNESS360_EDIT_PROCESS,
    data,
  };
};
export const WellnessEditSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_EDIT_SUCCESS,
    data,
  };
};
export const WellnessEditFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_EDIT_FAILED,
    data,
  };
};
