import React, { useState, useEffect } from "react";
import "./index.scss";
import { DownloadOutlined } from "@ant-design/icons";
import { Segmented, Avatar, Button, Tooltip } from "antd";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import "./../../theme/_theme.scss";

import Notification from "./../../components/Notification";
const InnerHeader = (props) => {
  const {
    headingicon,
    headingname,
    iconbgColor,
    iconcolor,
    FirstswicthName,
    SecondswicthName,
    FirstClickswicth,
    check,
    SecondClickswicth,
    ExportDownload,
    BtnName,
    BtnClick,
    downbtnstatus,
    notifystatus,
    segmentArray,
    onSegmentChange,
    children,
  } = props;

  const [shownotify, setShownotify] = useState(notifystatus);
  const [downbtns, setDownbtns] = useState(downbtnstatus);
  const [segmentOption, setSegmentOption] = useState([]);
  const clickTab = (val) => {
    if (onSegmentChange) {
      onSegmentChange(val);
      return;
    }
    if (val === FirstswicthName) {
      FirstClickswicth();
    } else if (val === SecondswicthName) {
      SecondClickswicth();
    } else if (val === BtnName) {
      BtnClick();
    }
  };

  useEffect(() => {
    const ops = segmentArray?.map((element) => ({
      ...element,
      label: element.label || String(element.value),
      value: String(element.value),
    }));
    setSegmentOption(ops);
  }, []);

  return (
    <>
      {/* <Alert message="Success Tips" type="warning" showIcon /> */}
      {/* color = warning:'#faad14' , danger :#ff4d4f'', info :'1677ff', notify:'#ffffff' if opicity add background color end '20'  */}
      {shownotify ? (
        <Notification
          boxmargin={"10px 0 25px "}
          n_icon={<InfoCircleOutlined />}
          iconcolor={"#333333"}
          background={"#ffffff50"}
          borderColor={"#ffffff"}
          bor
          n_text={
            "This is a demo project with dummy data. When youre ready, head on over to the  ingestion wizard to get started with your own data."
          }
          close_icon={<CloseOutlined />}
        />
      ) : null}

      <div className="inner_header">
        <div className="icon_b">
          <Avatar
            shape="square"
            style={{ color: iconcolor, backgroundColor: iconbgColor }}
            icon={headingicon}
          />
          <h1 className="capitalize"> {headingname}</h1>
        </div>
        <div className="flex">
          {children}
          {segmentArray?.length > 0 ? (
            <div className="ml-3 up_b">
              <Segmented
                options={segmentOption}
                value={check}
                onChange={clickTab}
              />
            </div>
          ) : null}

          {downbtns ? (
            <div className="ml-3">
              <Tooltip title="Download">
                <Button
                  type="primary"
                  ghost
                  icon={<DownloadOutlined />}
                  onClick={ExportDownload}
                />
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default InnerHeader;
