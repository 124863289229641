import React from "react";

export const Analytics = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M10.77,1.38c0.34,0.03,0.66,0.14,0.91,0.37c0.24,0.23,0.38,0.5,0.44,0.83c0.05,0.31,0.03,0.61,0.03,0.92
		c0,4.51,0,9.02,0,13.53c0,0.25-0.03,0.49-0.12,0.71c-0.09,0.24-0.26,0.44-0.47,0.6c-0.23,0.17-0.49,0.25-0.77,0.28
		c-0.18,0-0.37-0.01-0.55-0.01c-0.35,0-0.69,0-1.04,0.01c-0.49-0.01-0.86-0.26-1.13-0.65c-0.16-0.22-0.23-0.47-0.23-0.75
		c0-0.03,0-0.07,0-0.1c0-4.75,0-9.5,0-14.24c0-0.3,0.05-0.59,0.22-0.84c0.28-0.42,0.67-0.65,1.18-0.67
		C9.75,1.38,10.26,1.38,10.77,1.38z"
      />
      <path
        d="M4.29,5.69c0.52,0.05,0.94,0.27,1.2,0.74c0.07,0.13,0.12,0.28,0.15,0.43c0.03,0.21,0.04,0.42,0.04,0.63
		c0,3.21,0,6.43,0,9.64c0,0.34-0.08,0.65-0.3,0.93c-0.21,0.27-0.48,0.44-0.81,0.52c-0.08,0.02-0.16,0.03-0.24,0.04
		c-0.18,0-0.37-0.01-0.55-0.01c-0.33,0-0.67,0.01-1,0.01c-0.28,0.01-0.53-0.08-0.75-0.24c-0.35-0.25-0.58-0.57-0.63-1.01
		c-0.01-0.12-0.01-0.24-0.01-0.35c0-3.27,0-6.54,0-9.81c0-0.19,0.01-0.39,0.07-0.56c0.07-0.22,0.21-0.41,0.38-0.58
		C2.1,5.83,2.41,5.7,2.77,5.69C3.28,5.69,3.79,5.69,4.29,5.69z"
      />
      <path
        d="M17.31,10.01c0.12,0.03,0.25,0.05,0.36,0.09c0.46,0.16,0.75,0.48,0.88,0.94c0.04,0.14,0.07,0.29,0.07,0.44
		c0,1.9,0.01,3.79,0,5.69c0,0.56-0.25,1.01-0.76,1.29c-0.18,0.1-0.38,0.15-0.58,0.17c-0.53,0-1.06,0-1.59,0
		c-0.39,0.01-0.71-0.17-0.97-0.43c-0.27-0.27-0.41-0.62-0.4-1.02c0.01-0.49,0-0.98,0-1.47c0-1.39,0-2.79,0-4.18
		c0-0.16,0-0.32,0.05-0.48c0.2-0.59,0.67-1.01,1.32-1.04C16.23,10.01,16.77,10.01,17.31,10.01z"
      />
      <path
        d="M17.31,10.01c-0.54,0-1.08,0-1.62,0c0.04-0.01,0.08-0.03,0.13-0.03c0.45,0,0.9,0,1.35,0
		C17.21,9.98,17.26,10,17.31,10.01z"
      />
    </svg>
  );
};
