import { put } from "redux-saga/effects";
import { ActivityReportAction } from "../../action";
import { Client } from "../../../../utils/transport";
import { hms, hmsOnlyNumber } from "utils/timeConverter";
import dayjs from "dayjs";
import { ExportCSV } from "utils/export-csv";
import offlineValue from "utils/getOfflineTime";

const ExportData = (fileName, data, colunms, filterDate, teams) => {
  let result = [];
  const generatedDate =
    "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");

  const generatedData = [
    fileName,
    "Teams: - " + teams.join(",").replaceAll(",", "/"),
    "Date:-" + filterDate,
    generatedDate,
    colunms.join(),
  ];

  data?.forEach((record) => {
    const offlinetime = offlineValue(
      record?.punch_duration,
      record?.online_duration,
      record?.break_duration
    );

    const addColunmsValues = [
      !record?.first_name && !record?.last_name
        ? "--"
        : record?.first_name + " " + record?.last_name,
      // hms(record?.punch_duration),
      hmsOnlyNumber(record?.online_duration),
      // hmsOnlyNumber(offlinetime),
      hmsOnlyNumber(record?.active_duration),
      hmsOnlyNumber(record?.idle_duration),
      hmsOnlyNumber(record?.break_duration),
      !record.active_percent
        ? 0 + "%"
        : record.active_percent === 100
        ? 99.99 + "%"
        : record.active_percent + "%" || 0 + "%",
    ];

    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};

const getDownloadCSV = (payload, data) => {
  const teamIds = payload.bodyData.group_id.split(",");
  const selectTeamNames = payload.teamsList
    .filter((item) => teamIds.includes(item.id))
    .map((item) => item.name);

  const csvData = data?.data;
  const date =
    dayjs(payload?.bodyData?.start_date).format("YYYY-MM-DD") +
    " To " +
    dayjs(payload?.bodyData?.end_date).format("YYYY-MM-DD");
  const colunms = [
    "Employee",
    "Online time (hh:mm:ss)",
    "Active time (hh:mm:ss)",
    "Idle time (hh:mm:ss)",
    "Break time (hh:mm:ss)",
    "Activity %",
  ];
  const exdata = ExportData(
    "Activity Employee List",
    csvData,
    colunms,
    date,
    selectTeamNames
  );
  ExportCSV("Activity_Employee_List_", exdata);
};

export function* GetActivityReportData(data) {
  const config = {
    method: "POST",
    url: "query/productivity/productivity_employee_list",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ActivityReportAction.ActivityReportSuccess(response.data));
    } else {
      yield put(ActivityReportAction.ActivityReportFailed(response));
    }
  } catch (err) {
    yield put(ActivityReportAction.ActivityReportFailed(err));
  }
}

export function* GetActivityDownloadReportData(data) {
  const config = {
    method: "POST",
    url: "query/productivity/productivity_employee_list",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ActivityReportAction.ActivityReportDownloadSuccess());
      getDownloadCSV(data, response.data);
    }
  } catch (err) {
    yield put(ActivityReportAction.ActivityReportDownloadFailed(err));
  }
}
