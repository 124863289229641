import * as actionTypes from "./actionTypes";
const initialState = {
  //*GLOBAL*//

  //* LIST *//

  wellness360_list_loading: false,
  wellness360_list_success: false,
  wellness360_list_failed: false,
  wellness360_list_data: "",
  wellness360_list_message: "",

  //* CREATE *//

  wellness360_create_loading: false,
  wellness360_create_success: false,
  wellness360_create_failed: false,
  wellness360_create_data: [],
  wellness360_create_message: "",

  //* EDIT *//

  wellness360_edit_loading: false,
  wellness360_edit_success: false,
  wellness360_edit_failed: false,
  wellness360_edit_data: [],
  wellness360_edit_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.WELLNESS360_LIST_INITIAL:
      return {
        ...state,
        wellness360_list_loading: true,
        wellness360_list_success: false,
        wellness360_list_failed: false,
        wellness360_list_data: "",
        wellness360_list_message: "",
      };
    case actionTypes.WELLNESS360_LIST_PROCESS:
      return {
        ...state,
        wellness360_list_loading: true,
        wellness360_list_success: false,
        wellness360_list_failed: false,
        wellness360_list_data: "",
        wellness360_list_message: "",
      };
    case actionTypes.WELLNESS360_LIST_SUCCESS:
      return {
        ...state,
        wellness360_list_loading: false,
        wellness360_list_success: true,
        wellness360_list_failed: false,
        wellness360_list_data: action.data,
        wellness360_list_message: "",
      };
    case actionTypes.WELLNESS360_LIST_FAILED:
      return {
        ...state,
        wellness360_list_loading: false,
        wellness360_list_success: false,
        wellness360_list_failed: true,
        wellness360_list_data: "",
        wellness360_list_message: "",
      };

    case actionTypes.WELLNESS360_CREATE_INITIAL:
      return {
        ...state,
        wellness360_create_loading: false,
        wellness360_create_success: false,
        wellness360_create_failed: false,
        wellness360_create_data: [],
        wellness360_create_message: "",
      };

    case actionTypes.WELLNESS360_CREATE_PROCESS:
      return {
        ...state,
        wellness360_create_loading: true,
        wellness360_create_success: false,
        wellness360_create_failed: false,
        wellness360_create_data: [],
        wellness360_create_message: "",
      };

    case actionTypes.WELLNESS360_CREATE_SUCCESS:
      var res = action.data;

      var createddata = {
        id: res.id,

        created_by_id_details: {
          id: res.created_by.id,
          email: res.created_by.email,
          first_name: res.created_by.first_name,
          last_name: res.created_by.last_name,
        },
        last_modified_by_id_details: {
          id: res.last_modified_by.id,
          email: res.last_modified_by.email,
          first_name: res.last_modified_by.first_name,
          last_name: res.last_modified_by.last_name,
        },
        last_modified_date: res.last_modified_date,
        created_date: res.created_date,
        read_only: res.read_only,
        min_punch_time: res.min_punch_time,
        max_punch_time: res.max_punch_time,
        timezone: res.timezone,
      };

      return {
        ...state,
        wellness360_create_loading: false,
        wellness360_create_success: true,
        wellness360_create_failed: false,
        wellness360_create_data: res,
        wellness360_list_data: createddata,
        wellness360_create_message: "",
      };

    case actionTypes.WELLNESS360_CREATE_FAILED:
      return {
        ...state,
        wellness360_create_loading: false,
        wellness360_create_success: false,
        wellness360_create_failed: true,
        wellness360_error_message: action.data,
        wellness360_create_data: [],
        wellness360_create_message: "",
      };

    case actionTypes.WELLNESS360_EDIT_INITIAL:
      return {
        ...state,
        wellness360_edit_loading: false,
        wellness360_edit_success: false,
        wellness360_edit_failed: false,
        wellness360_edit_data: [],
        wellness360_edit_message: "",
      };

    case actionTypes.WELLNESS360_EDIT_PROCESS:
      return {
        ...state,
        wellness360_edit_loading: true,
        wellness360_edit_success: false,
        wellness360_edit_failed: false,
        wellness360_edit_data: [],
        wellness360_edit_message: "",
      };

    case actionTypes.WELLNESS360_EDIT_SUCCESS:
      return {
        ...state,
        wellness360_edit_loading: false,
        wellness360_edit_success: true,
        wellness360_edit_failed: false,
        wellness360_edit_data: action.data,
        wellness360_list_data: action.data,
        wellness360_edit_message: "",
      };

    case actionTypes.WELLNESS360_EDIT_FAILED:
      // const fetchLeaveslist = [...state.wellness360_list_data];
      // const findIndexLeave = fetchLeaveslist.findIndex(item => item.id == action.data.id);
      // fetchLeaveslist[findIndexLeave] = action.data;
      // const fetchMyLeavelist = [...state.my_leave_list_data];
      // const findIndexMyLeave = fetchMyLeavelist.findIndex(item => item.id == action.data.id);
      // fetchMyLeavelist[findIndexMyLeave] = action.data;
      return {
        ...state,
        wellness360_edit_loading: false,
        wellness360_edit_success: true,
        wellness360_edit_failed: false,
        wellness360_edit_data: action.data,
        wellness360_edit_message: "",
      };

    default:
      return state;
  }
};
