import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import Chart from "./../../chart";
import { Skeleton, Typography, Tooltip, Empty, message } from "antd";

import dayjs from "dayjs";

import { useSelector } from "react-redux";
import { h, onlyHoursNumber } from "utils/timeConverter";

import calculatePercentage from "utils/func_percentage";
import { GetMaximumValue, GetMinimumValue } from "utils/getMinimunMaximumValue";

const { Title } = Typography;

const Cardskeleton = () => {
  return (
    <>
      <div className="charts marginT">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const AttendanceDetailChart = () => {
  const [loading, setLoading] = useState(true);
  const attendancedetailed = useSelector((state) => state.attendancedetailed);
  const [attendanceTrendData, setAttendanceTrendData] = useState();
  const [chartOptionData, setChartOptionData] = useState();

  useEffect(() => {
    if (
      attendancedetailed?.attendance_detailed_attendance_trend_graph_success ===
      true
    ) {
      if (
        (
          attendancedetailed?.attendance_detailed_attendance_trend_graph_data ||
          []
        ).length > 0
      ) {
        setLoading(true);
        const Present = [];
        const Absent = [];
        const AttendancePer = [];
        const AvgWorkHour = [];
        const avgWorkValues = [];
        const formetedDated = [];
        const dated = [];
        let fullData = {};
        let maxAvgValue = 0;
        let minAvgValue = 0;
        let maxTotalUser = 0;

        const totalUser = [];

        attendancedetailed?.attendance_detailed_attendance_trend_graph_data?.forEach(
          (item) => {
            formetedDated.push(dayjs(item.date).format("DD/MM"));
            dated.push(dayjs(item.date));

            Present.push(item.present);
            Absent.push(item.absent);
            AttendancePer.push(calculatePercentage(item.present, item.total));
            AvgWorkHour.push(
              !item.average_wrk_time ? 0 : h(item.average_wrk_time)
            );
            avgWorkValues.push(onlyHoursNumber(item?.average_wrk_time));
            totalUser.push(!item.total ? 0 : item.total);
          }
        );
        maxTotalUser = GetMaximumValue(totalUser) + 4;
        maxAvgValue = GetMaximumValue(avgWorkValues) + 4;
        minAvgValue = GetMinimumValue(avgWorkValues);

        fullData = {
          formetedDated: formetedDated,
          dated: dated,
          Present: Present,
          Absent: Absent,
          AttendancePer: AttendancePer,
          AvgWorkHour: AvgWorkHour,
        };

        const appusages = {
          series: [
            {
              name: "Present",
              type: "bar",
              data: Present,
            },

            {
              name: "Absent",
              type: "bar",
              data: Absent,
            },
            {
              data: AttendancePer,
              name: "Attendance %",
              type: "line",
            },

            {
              data: AvgWorkHour,
              name: "Avg. working hours",
              type: "line",
            },
          ],
        };

        const seriesLength = (appusages?.series || []).length;

        const optimalColumnWidthPercent =
          formetedDated?.length === 1
            ? 30 / (10 * Math.exp(-seriesLength / 3))
            : formetedDated?.length < 11
            ? 70 / (10 * Math.exp(-seriesLength / 3))
            : 100 / (5 * Math.exp(-seriesLength / 3));

        const chartOptions = {
          options: {
            chart: {
              height: 400,

              redrawOnParentResize: true,
              // stacked: true,

              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
              type: "line",
            },
            colors: ["#7B61FF", "#CFD8DC", "#FFB800", "#00B5B5"],
            dataLabels: {
              enabled: false,
              // enabledOnSeries: [2]
            },
            noData: {
              align: "center",
              text: "No data available at the moment",
              verticalAlign: "middle",
            },
            plotOptions: {
              bar: {
                //   borderRadius: 8,
                horizontal: false,
                columnWidth: optimalColumnWidthPercent + "%",
              },
            },
            stroke: {
              width: [0, 0, 3, 3],
              curve: "smooth",
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: formetedDated,
              data: fullData,
              labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: true,
                trim: true,
              },
              axisBorder: {
                show: true,
                offsetY: 2,
              },
            },
            yaxis: [
              {
                seriesName: "Present",
                opposite: false,
                max: maxTotalUser,
                min: 0,
                tickAmount: 4,

                axisTicks: {
                  show: true,
                },

                axisBorder: {
                  show: true,
                  color: "#37C6AC",
                },
                labels: {
                  style: {
                    colors: "37C6AC",
                  },
                  formatter: function (val) {
                    return parseInt(val);
                  },
                },
              },
              {
                seriesName: "Absent",
                show: false,
                max: maxTotalUser,
                min: 0,
                tickAmount: 5,

                opposite: false,
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: "#CFD8DC",
                },
                labels: {
                  style: {
                    colors: "#CFD8DC",
                  },
                  formatter: function (val) {
                    return parseInt(val);
                  },
                },
              },
              {
                seriesName: "Attendance %",
                show: true,
                max: 100,
                min: 0,
                tickAmount: 5,
                opposite: true,
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: "#7B61FF",
                },
                title: {
                  text: "Attendance %",
                  rotate: 90,
                },

                labels: {
                  style: {
                    colors: "#FFB800",
                  },

                  formatter: function (val) {
                    return val + "%";
                  },
                },
              },

              {
                seriesName: "Avg working hours",
                opposite: true,
                max: maxAvgValue,
                min: minAvgValue >= 0 ? 0 : minAvgValue - 4,
                tickAmount: 4,
                axisTicks: {
                  show: true,
                },
                title: {
                  text: "Avg Working hours ",
                  rotate: 90,
                },
                axisBorder: {
                  show: true,
                  color: "#00B5B5",
                },
                labels: {
                  style: {
                    colors: "#00B5B5",
                  },
                  formatter: function (val) {
                    return parseInt(val);
                  },
                },
              },
            ],

            legend: {
              position: "top",
              horizontalAlign: "center",

              formatter: function (label) {
                return '<div class="legenddata">' + label + "</div>";
              },
            },

            tooltip: {
              enabled: true,
              theme: "light",

              custom: function ({ dataPointIndex, w }) {
                const date1 = dayjs(
                  w.config.xaxis.data.dated[dataPointIndex]
                ).format("DD-MMM-YYYY");

                const present = w.globals.seriesNames[0];
                const presentdata = w.globals.series[0][dataPointIndex];
                const absent = w.globals.seriesNames[1];
                const absentdata = w.globals.series[1][dataPointIndex];
                const attendance = w.globals.seriesNames[2];
                const attendancedata = w.globals.series[2][dataPointIndex];
                const workinghour = w.globals.seriesNames[3];
                const workinghouredata = w.globals.series[3][dataPointIndex];

                return (
                  '<div class="bartooltip">' +
                  date1 +
                  '</div><div class="w_data">' +
                  '</div></div><div class="w_data"><div class="colordot" style="background:' +
                  w.globals.colors[0] +
                  '"></div>' +
                  present +
                  '<div class="w_value">' +
                  presentdata +
                  '</div></div><div class="w_data"><div class="colordot" style="background:' +
                  w.globals.colors[1] +
                  '"></div>' +
                  absent +
                  '<div class="w_value">' +
                  absentdata +
                  '</div></div><div class="w_data"><div class="colordot" style="background:' +
                  w.globals.colors[2] +
                  '"></div>' +
                  attendance +
                  '<div class="w_value">' +
                  attendancedata +
                  '</div></div><div class="w_data"><div class="colordot" style="background:' +
                  w.globals.colors[3] +
                  '"></div>' +
                  workinghour +
                  '<div class="w_value">' +
                  workinghouredata +
                  "</div></div>"
                );
              },
            },

            states: {
              hover: { filter: { type: "dark", value: 0.5 } },
              active: { filter: { type: "none", value: 0 } },
            },

            series: [
              {
                data: appusages,
              },
            ],
          },
        };
        setAttendanceTrendData(appusages);
        setChartOptionData(chartOptions);
        setLoading(false);
      } else {
        setAttendanceTrendData();
        setChartOptionData();
        setLoading(false);
      }
    } else if (
      attendancedetailed?.attendance_detailed_attendance_trend_graph_failed
    ) {
      message.error(
        attendancedetailed?.attendance_detailed_attendance_trend_graph_message
          ?.message
      );
      setAttendanceTrendData();
      setLoading(false);
    }
  }, [
    attendancedetailed?.attendance_detailed_attendance_trend_graph_success,
    attendancedetailed?.attendance_detailed_attendance_trend_graph_failed,
  ]);

  return (
    <>
      {loading === true ? (
        <Cardskeleton />
      ) : (
        <div className="charts marginT">
          <div className="chart_header">
            <Title level={2}>{"Attendance Trends"}</Title>
            <Tooltip
              placement="rightBottom"
              title="Displays the Present/Absent figures, Attendance % and Working time. Hover over the graph for more details."
            >
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div
            className="chartContainer all_tool"
            id="chart"
            style={{ marginTop: "-20px" }}
          >
            {/* chart */}
            {attendanceTrendData ? (
              <Chart
                options={chartOptionData?.options}
                series={attendanceTrendData.series}
                type="line"
                height={306}
              />
            ) : (
              <div style={{ height: "271px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

AttendanceDetailChart.propTypes = {
  getList: PropTypes.func,
};

export default AttendanceDetailChart;
