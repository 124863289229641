import { put, call } from "redux-saga/effects";
import { TeamAction } from "../action";
import { Client } from "../../../utils/transport";

export function* CreateTeam(data) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "TEAM_PHASE_1",
    method: "POST",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamCreateSuccess(response));
    } else {
      yield put(TeamAction.TeamCreateFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.TeamCreateFailed(err));
  }
}

export function* UpdateTeam(data) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "api/v1/groups/" + data.data.BodyData.id,
    method: "PUT",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamUpdateSuccess(response.data));
    } else {
      yield put(TeamAction.TeamUpdateFailed(response.data));
    }
  } catch (err) {
    yield put(TeamAction.TeamUpdateFailed(err));
  }
}

export function* TeamList(data) {
  let config = {
    method: "GET",
    url: "TEAM_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      // "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamListSuccess(response.data));
    } else {
      yield put(TeamAction.TeamListFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.TeamListFailed(err));
  }
}

export function* DeleteTeam(data) {
  var IsChild = data.data.IsChild;
  var url = "";
  if (IsChild) {
    url = "api/v1/groups/" + data.data.BodyData.id;
  } else {
    url = "api/v1/groups/" + data.data.BodyData.id;
  }

  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: url,
    method: "DELETE",
    data: "",
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamDeleteSuccess(response.data));
    } else {
      yield put(TeamAction.TeamDeleteFailed(response.data));
    }
  } catch (err) {
    yield put(TeamAction.TeamDeleteFailed(err));
  }
}

export function* UpdateMemberTeam(data) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "/api/v1/user_infos/" + data.data.BodyData.identity.id,
    method: "put",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamMemberSuccess(response));
    } else {
      yield put(TeamAction.TeamMemberFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.TeamMemberFailed(err));
  }
}

export function* UpdateManagerTeam(data) {
  //
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "/api/v1/user_infos/" + data.data.BodyData.identity.id,
    method: "put",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamManegerSuccess(response));
    } else {
      yield put(TeamAction.TeamManegerFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.TeamManegerFailed(err));
  }
}

export function* UpdateUserSetting(data) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "/api/v1/user_infos/" + data.data.BodyData.id,
    method: "put",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.UpdateUserSettingSuccess(response));
    } else {
      yield put(TeamAction.UpdateUserSettingFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.UpdateUserSettingFailed(err));
  }
}
