function logger() {
  const environment = process.env.REACT_APP_APP_ENV || "PRODUCTION";

  console.log("Error logger is in " + environment + " mode");

  if (environment == "PRODUCTION") {
    console.log("%cStop! 🤫", "color:red; font-size:50px");
    console.log(
      '%c👉 This is a browser feature intended for developers. If someone told you to copy and paste something here to enable a We360 feature or "hack" someone\'s account, it is a scam and will give them access to your We360 account.',
      "color:blue; font-size:30px"
    );

    console.log = function () {};
    console.error = function () {};
    console.warn = function () {};
  }

  const write = (type = "LOG", message) => {
    switch (type) {
      case "LOG":
        console.log(...message);
        break;
      case "ERROR":
        console.error(...message);
        break;
      case "WARN":
        console.warn(...message);
        break;
      default:
        console.log(...message);
        break;
    }
  };

  const log = (...message) => {
    if (environment == "DEVELOPMENT") write("LOG", message);
  };

  const warning = (message) => {
    if (environment == "DEVELOPMENT") write("WARNING", message);
  };

  const error = (message) => {
    if (environment == "DEVELOPMENT") write("ERROR", message);
  };

  return { log, warning, error };
}

export default logger();
