import * as actionTypes from "./actionTypes";

const initialState = {
  breaks_report_processing: false,
  breaks_report_success: false,
  breaks_report_failed: false,
  breaks_report_data: [],
  breaks_report_message: "",
  breakReportMaster: [],

  breaks_download_report_processing: false,
  breaks_download_report_success: false,
  breaks_download_report_failed: false,
  breaks_download_report_data: [],
  breaks_download_report_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.BREAKS_REPORT_INITIAL:
      return {
        ...state,
        breaks_report_processing: false,
        breaks_report_success: false,
        breaks_report_failed: false,
        breaks_report_data: [],
        breaks_report_message: "",
        breakReportMaster: [],
      };
    case actionTypes.BREAKS_REPORT_PROCESS:
      return {
        ...state,
        breaks_report_processing: true,
        breaks_report_success: false,
        breaks_report_failed: false,
        breaks_report_data: [],
        breaks_report_message: "",
        breakReportMaster: [],
      };
    case actionTypes.BREAKS_REPORT_SUCCESS:
      return {
        ...state,
        breaks_report_processing: false,
        breaks_report_success: true,
        breaks_report_failed: false,
        breaks_report_data: action.data,
        breaks_report_message: "",
        breakReportMaster: action.data,
      };
    case actionTypes.BREAKS_REPORT_FAILED:
      return {
        ...state,
        breaks_report_processing: false,
        breaks_report_success: false,
        breaks_report_failed: true,
        breaks_report_message: action.data,
        breaks_report_data: [],
        breakReportMaster: [],
      };

    case actionTypes.BREAKS_DOWNLOAD_REPORT_INITIAL:
      return {
        ...state,
        breaks_download_report_processing: false,
        breaks_download_report_success: false,
        breaks_download_report_failed: false,
        breaks_download_report_data: [],
        breaks_download_report_message: "",
      };
    case actionTypes.BREAKS_DOWNLOAD_REPORT_PROCESS:
      return {
        ...state,
        breaks_download_report_processing: true,
        breaks_download_report_success: false,
        breaks_download_report_failed: false,
        breaks_download_report_data: [],
        breaks_download_report_message: "",
      };
    case actionTypes.BREAKS_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        breaks_download_report_processing: false,
        breaks_download_report_success: true,
        breaks_download_report_failed: false,
        breaks_download_report_data: action.data,
        breaks_download_report_message: "",
      };
    case actionTypes.BREAKS_DOWNLOAD_REPORT_FAILED:
      return {
        ...state,
        breaks_download_report_processing: false,
        breaks_download_report_success: false,
        breaks_download_report_failed: true,
        breaks_download_report_message: action.data,
        breaks_download_report_data: [],
      };

    default:
      return state;
  }
};
