import dayjs from "dayjs";

export const ALERT_TYPE_LABEL: Record<AlertType, string> = {
  APP_NAME_SINGLE_INSTANCE: "Application",
  APP_URL_SINGLE_INSTANCE: "URL",
  OVERTIME_BREAK: "Overtime Break",
};

export const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 30 Days",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Last 90 Days",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
];
