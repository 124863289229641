import { put } from "redux-saga/effects";
import { BreakReportAction } from "../../action";
import { Client } from "../../../../utils/transport";

export function* GetBreakReportData(data) {
  let config = {
    method: "POST",
    url: "query/reports/break_report",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(BreakReportAction.BreaksReportSuccess(response.data));
    } else {
      yield put(BreakReportAction.BreaksReportFailed(response));
    }
  } catch (err) {
    yield put(BreakReportAction.BreaksReportFailed(err));
  }
}

export function* GetBreakDownloadReportData(data) {
  let config = {
    method: "POST",
    url: "query/reports/break_report",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(BreakReportAction.BreaksDownloadReportSuccess(response.data));
    } else {
      yield put(BreakReportAction.BreaksDownloadReportFailed(response));
    }
  } catch (err) {
    yield put(BreakReportAction.BreaksDownloadReportFailed(err));
  }
}
