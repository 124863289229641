const translation = {
  attendance: "Attendance",
  Summary: "Summary",
  Detailed: "Detailed",
  todaysAttendance: "Today's Attendance",
  "Attendance Trends": "Attendance Trends",
  "Break Trends": "Break Trends",
  "Late Arrival Tendency": "Late Arrival Tendency",
  "Late Arrival Tendency": "Late Arrival Tendency",
  "Attendance %": "Attendance %",
  "Late Arrivals": "Late Arrivals",
  "Total Break time": "Total Break time",
  "Total Working time": "Total Working time",
  "First Name": "First Name",
  "Last Name": "Last Name",
  Email: "Email",
  Country: "Country",
  "Company Name": "Company Name",
  "Mobile No": "Mobile No.",
  "Start Free Trial": "Start Free Trial",
  "Other sign in options": "Other sign in options",
  "Continue with Google": "Continue with Google",
  "Are you new?": "Are you new?",
  "Sign up for free trial": "Sign up for free trial",
  "Join The Productive Side": "Join The Productive Side",
  "Please Check Your Email": "Please Check Your Email",
  "We’ve sent a verification link to": "We’ve sent a verification link to",
  "Click on that verification link to validate your email ID.":
    "Click on that verification link to validate your email ID.",
  "Go TO YOur Mail": "Go TO YOur Mail",
  "Take your team’s productive journey to the next level.":
    "Take your team’s productive journey to the next level.",
  Next: "Next",
  Pervious: "Pervious",
  Submit: "Submit",
  Help: "Help",
  Privacy: "Privacy",
  Terms: "Terms",
  "Thanks for your time": "Thanks for your time",
  "Set Password": "Set Password",
  "Set Domain": "Set Domain",
  Done: "Done",
  Password: "Password",
  "Confirm Password": "Confirm Password",
  "Link Expired": "Link Expired",
  "Look Link the email Verification Link has Expired not to worry, we can send you another one":
    "Look Link the email Verification Link has Expired not to worry, we can send you another one",
  "Send Link Again": "Send Link Again",
};

export default translation;
