import { put } from "redux-saga/effects";
import { Client } from "../../../utils/transport";
import {
  Wellness360ListFailed,
  Wellness360ListSuccess,
  WellnessCreateFailed,
  WellnessCreateSuccess,
  WellnessEditFailed,
  WellnessEditSuccess,
} from "./action";

export function* GetWellness360List(data) {
  let config = {
    method: "GET",
    url: "WELLNESS_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(Wellness360ListSuccess(response.data));
    } else {
      yield put(Wellness360ListFailed(response));
    }
  } catch (err) {
    yield put(Wellness360ListFailed(err));
  }
}

export function* CreateWellness360(data) {
  let config = {
    method: "POST",
    url: "WELLNESS_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(WellnessCreateSuccess(response.data));
    } else {
      yield put(WellnessCreateFailed(response));
    }
  } catch (err) {
    yield put(WellnessCreateFailed(err));
  }
}
export function* EditWellness360(data) {
  // var path = "admin/settings/" + data.id;
  let config = {
    method: "PUT",
    url: "WELLNESS_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(WellnessEditSuccess(response.data));
    } else {
      yield put(WellnessEditFailed(response));
    }
  } catch (err) {
    yield put(WellnessEditFailed(err));
  }
}
