import React, { useEffect, useState, useContext } from "react";
import "./index.scss";
import { DatePicker, Button, message, Tooltip, Checkbox } from "antd";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { DownloadOutlined, ReloadOutlined } from "@ant-design/icons";
import TeamTree from "./components/teamtree";
import { AuthContext } from "contexts/authContext";
import { UserAction, TeamAction } from "../../redux/store/action";
import UserTree from "./components/usertree";
import { GetTreeNode } from "utils/getTreeNodes";
import { roles } from "constants/constant";
import { FiSettings } from "react-icons/fi";
import { SETTINGS } from "route/constant";
import { useNavigate } from "react-router-dom";
import CheckboxComponent from "./components/checkbox";
dayjs.extend(customParseFormat);
const { RangePicker, MonthPicker } = DatePicker;

const rangePresets = [
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 14 Days",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Last 31 Days",
    value: [dayjs().add(-31, "d"), dayjs()],
  },
];

const Filter = (props) => {
  const {
    modelName,
    teamshow,
    usershow,
    monthshow,
    rangeshow,
    btnDownload,
    ExportDownload,
    ClickReload,
    btnReload,
    onSelectDate,
    filterDataPass,
    startDate,
    endDate,
    dateRange,
    monthpikershow = false,
    OnSelctMonth,
    loadingbtn = false,
    userName = "",
    selectTeam,
    btnAlertSettings,
    singleSelect,
    selectedUserIds,
    children,
    doNotLoadOnTeamChange,
    isAppUrl,
    checkboxOptions,
    checkboxDefaultValue,
    appUrlOnChaneType,
  } = props || {};

  const {
    team_list_success,
    team_list_data,
    team_list_failed,
    team_list_message,
  } = useSelector((state) => state.team);
  const { KState, role } = useContext(AuthContext);
  const [teamTreeData, setTeamTreeData] = useState([]);
  const {
    medetails_data,
    user_list_success,
    user_list_data,
    user_list_failed,
    team_wise_user_list_message,
    team_wise_user_list_success,
    team_wise_user_list_data,
  } = useSelector((state) => state.users);

  const [userNodes, setuserNodes] = useState([]);
  const [isReload, setisReload] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const [allUserNames, setallUserNames] = useState([]);
  const [sDate, setsDate] = useState(dayjs().subtract(6, "day"));
  const [eDate, seteDate] = useState(dayjs());
  const [isActive, setActive] = useState(false);
  const rangActive = false;
  const dispatch = useDispatch();

  const GetTeamList = () => {
    if (KState && KState.keycloak) {
      const data = {
        clientId: KState.keycloak.clientId,
        token: KState.keycloak.token,
      };
      dispatch(TeamAction.TeamListProcess(data));
    }
  };

  const GetUserList = () => {
    if (KState && KState.keycloak) {
      const data = {
        clientId: KState.keycloak.clientId,
        token: KState.keycloak.token,
        role,
      };

      dispatch(UserAction.UserListProcess(data));
    }
  };

  // team data
  useEffect(() => {
    dispatch(TeamAction.TeamListInitial());
    dispatch(UserAction.TeamWiseUserListInitial());
    GetTeamList();
    GetUserList();
  }, []);

  useEffect(() => {
    if (team_list_success) {
      setisReload(false);
      if ((team_list_data || []).length > 0) {
        const groupID = medetails_data?.attached_group?.id;

        const treeGroupData =
          role === roles.OWNER
            ? GetTreeNode(team_list_data)
            : [
                {
                  title: medetails_data?.attached_group?.name,
                  value: groupID,
                  children: GetTreeNode(team_list_data, groupID),
                },
              ];

        setTeamTreeData(treeGroupData);
      }
    } else if (team_list_failed) {
      setisReload(false);
      message.error(team_list_message?.message);
    }
  }, [team_list_success, team_list_data, team_list_failed, team_list_message]);

  const GetTeamWiseUser = (keycloak, teamIds = []) => {
    const bodyData = {
      groups: (teamIds || []).length > 0 ? teamIds.join(",") : "",
    };
    dispatch(UserAction.TeamWiseUserListProcess(keycloak.token, bodyData));
  };

  const getUserTreeNode = (userList, type) => {
    const ulist = [];
    const uName = [];
    const uIds = [];
    let id = "";
    let fullname = "";
    if (type === "load") {
      userList.forEach((item) => {
        id = item?.identity?.id;
        fullname = item?.identity?.first_name + " " + item?.identity?.last_name;
        uIds.push(id);
        uName.push(fullname);
        ulist.push({
          title: fullname,
          children: null,
          value: id,
        });
      });
    } else if (type === "filter") {
      userList.forEach((item) => {
        id = item?.identity_id;
        fullname = item?.first_name + " " + item?.last_name;
        uIds.push(id);
        uName.push(fullname);
        ulist.push({
          title: fullname,
          children: null,
          value: id,
        });
      });
    }
    setUserIds(uIds);
    setuserNodes(ulist);
    setallUserNames(uName);
    setisReload(false);
  };

  useEffect(() => {
    if (user_list_success === true) {
      if ((user_list_data || []).length > 0) {
        getUserTreeNode(user_list_data, "load");
      } else if (user_list_failed === true) {
        message.error(team_wise_user_list_message?.message);
        setUserIds([]);
        setuserNodes([]);
        setallUserNames([]);
        setisReload(false);
      }
    }
  }, [
    user_list_success,
    user_list_failed,
    team_wise_user_list_message,
    user_list_failed,
  ]);

  useEffect(() => {
    if (team_wise_user_list_success === true) {
      if ((team_wise_user_list_data || []).length > 0) {
        getUserTreeNode(team_wise_user_list_data, "filter");
      } else if ((team_wise_user_list_data || []).length === 0) {
        setallUserNames([]);
        setUserIds([]);
        setuserNodes([]);
      }
    } else {
      setuserNodes([]);
    }
  }, [team_wise_user_list_success, team_wise_user_list_data]);

  const selectUsers = (val) => {
    filterDataPass && filterDataPass(val);
  };

  const selectteam = (selectedTeam) => {
    selectTeam(selectedTeam);
    if ((selectedTeam || []).length > 0) {
      dispatch(UserAction.TeamWiseUserListInitial());
      GetTeamWiseUser(KState.keycloak, selectedTeam);
    } else if ((selectedTeam || []).length === 0) {
      getUserTreeNode(user_list_data, "load");
    }
  };

  const onDateChange = (date, dateStrings) => {
    setActive(!isActive);
    onSelectDate(date || dayjs(), dateStrings || dayjs().toISOString());
  };

  const onDateRangeChange = (dates, dateStrings) => {
    const startDate = dayjs(_.nth(dateStrings, 0));
    const endDate = dayjs(_.nth(dateStrings, 1));
    const Daydiff = dayjs(endDate).diff(dayjs(startDate), "days");
    if (Daydiff > 31) {
      message.warning("Max select date range 31 days");
    } else {
      setsDate(startDate);
      seteDate(endDate);
      if (dates) {
        dateRange(startDate, endDate);
      }
    }
  };

  const onReload = () => {
    setisReload(true);
    const sdate = dayjs().subtract(6, "d");
    const edate = dayjs();
    setsDate(sdate);
    seteDate(edate);
    ClickReload(true);
    dispatch(TeamAction.TeamListInitial());
    GetTeamList();
  };

  function disabledDate(current) {
    const weekStart = dayjs().subtract(1, "year");
    const weekEnd =
      modelName === "timeline" || modelName === "screenshot"
        ? dayjs().add(1, "day")
        : dayjs();
    return !(weekStart.isBefore(current) && weekEnd.isAfter(current));
  }

  const onMonthChange = (monthDate) => {
    const sdate = dayjs(monthDate || Date.now()).startOf("month");
    const edate = dayjs(monthDate || Date.now()).endOf("month");
    OnSelctMonth(sdate, edate);
  };

  const navigate = useNavigate();
  const handleAction = () => {
    navigate(SETTINGS, { state: { id: 6, pathName: "alerts" } });
  };

  const appUrlOnChange = (value, event) => {
    appUrlOnChaneType(value);
  };
  return (
    <div className="filter">
      <div className="filter_data ">
        <div className="f_start">
          {teamshow ? (
            (teamTreeData || []).length > 0 ? (
              <TeamTree
                datateam={teamTreeData}
                selectTeam={(Team) => selectteam(Team)}
                isReload={isReload && isReload}
              />
            ) : null
          ) : null}

          {usershow && (
            <UserTree
              filterDataPass={(Users) => selectUsers(Users)}
              userNodes={userNodes && userNodes}
              allUserNames={allUserNames && allUserNames}
              userIds={userIds && userIds}
              isReload={singleSelect ? false : isReload}
              userName={userName}
              singleSelect={singleSelect}
              selectedUserIds={selectedUserIds}
            />
          )}
          {isAppUrl && (
            <div className="pl-5">
              <CheckboxComponent
                options={checkboxOptions}
                defaultValue={checkboxDefaultValue}
                onChange={appUrlOnChange}
              />
            </div>
          )}
        </div>

        <div className="f_end gap-3">
          {children}
          {monthshow ? (
            <DatePicker
              inputReadOnly
              defaultValue={sDate}
              value={startDate}
              disabledDate={disabledDate}
              format="YYYY-MM-DD "
              style={{ width: 180 }}
              className={isActive ? "cal_active" : null}
              onChange={onDateChange}
              clearIcon={null}
            />
          ) : null}
          {rangeshow ? (
            <RangePicker
              inputReadOnly
              defaultValue={[sDate, eDate]}
              value={[startDate, endDate]}
              presets={rangePresets}
              onChange={onDateRangeChange}
              className={rangActive ? "rang_active" : null}
              disabledDate={disabledDate}
              dateRender={(current) => {
                const date = current.date();
                return date <= 31 ? (
                  <div className="ant-picker-cell-inner">{date}</div>
                ) : null;
              }}
              clearIcon={null}
            />
          ) : null}

          {monthpikershow ? (
            <MonthPicker
              inputReadOnly
              defaultValue={sDate}
              value={startDate}
              disabledDate={disabledDate}
              format="MMM"
              style={{ width: 180 }}
              // bordered={false}
              className={isActive ? "cal_active" : null}
              onChange={onMonthChange}
              clearIcon={null}
            />
          ) : null}

          {/* {sortby ? <SortBy /> : null} */}
          {btnDownload ? (
            <Tooltip title="Download">
              <Button
                loading={loadingbtn}
                icon={<DownloadOutlined />}
                onClick={ExportDownload}
              />
            </Tooltip>
          ) : null}
          {btnReload ? (
            <Tooltip title="Reload">
              <Button
                type="primary"
                ghost
                icon={<ReloadOutlined />}
                onClick={onReload}
              />
            </Tooltip>
          ) : null}

          {btnAlertSettings ? (
            <Tooltip title="Go to alert rules">
              <Button
                type="primary"
                ghost
                icon={<FiSettings />}
                onClick={handleAction}
              />
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Filter;
