const LOGIN = "/login/callback";
const SIGNUP_INITIATE = "/signup";
const SIGNIN = "/signin";

const HOME = "/we360";

const SIGNUP_PHASE_1 = "/emailverification";
const SIGNUP_PHASE_2 = "/congratulations";
const SIGNUP_PHASE_3 = "/emailalreadyverify";
const SIGNUP_PHASE_4 = "/linkexpired";
const SIGNUP_PHASE_5 = "/setpassword/:token";
const SIGNUP_PHASE_6 = "/accountsetup";
const SIGNUP_PHASE_7 = "/complete-setup/";
const SIGNUP_PHASE_8 = "/complete-google-setup";

const PORTAL_ALLOWED_ROUTES = [
  SIGNUP_INITIATE,
  SIGNIN,
  SIGNUP_PHASE_7,
  SIGNUP_PHASE_8,
];

const RESERVED_DOMAINS = ["portal", "app", "assets", "login"];
const REDIRECT_TO_PORTAL = ["assets"];

const DASHBOARD = "/dashboard";
const ONBOARDING = "/onboarding";
const ATTENDANCE = "/attendance";
const LIVESTREAM = "/livestream";
const WELLNESS = "/wellness360";
const ACTIVITY = "/activity";
const PRODUCTIVITY = "/productivity";
const TIMELINE = "/timeline";
const APPLICATION = "/application";
const SETTINGS = "/settings";
const FIELD = "/field";
const ALERTS = "/alerts";
const REPORTS = "/reports";
const ACCOUNT = "/account";
const BILLING = "/billing";
const ME = "/user-detail";
const PROJECTS = "/projects";
const TASK = "/tasks";
const DOWNLOAD = "/download";

const USER_DASHBOARD = "/user/dashboard";
const USER_ATTENDANCE = "/user/attendance";
const USER_WELLNESS = "/user/wellness360";
const USER_ACTIVITY = "/user/activity";
const USER_PRODUCTIVITY = "/user/productivity";
const USER_APPLICATION = "/user/application";

const DESIGNATION = "api/v1/designations/";

// error
const FORBIDDEN = "/403";
const NOT_FOUND = "/404";
const BILLING_ALLOWED_ROUTES = [SETTINGS, BILLING, ACCOUNT, DOWNLOAD];
const SCREENSHOT = "/screenshot";
export {
  LOGIN,
  SIGNUP_INITIATE,
  SIGNIN,
  SIGNUP_PHASE_1,
  SIGNUP_PHASE_2,
  SIGNUP_PHASE_3,
  SIGNUP_PHASE_4,
  SIGNUP_PHASE_5,
  SIGNUP_PHASE_6,
  SIGNUP_PHASE_7,
  SIGNUP_PHASE_8,
  DASHBOARD,
  ONBOARDING,
  ATTENDANCE,
  LIVESTREAM,
  ACTIVITY,
  WELLNESS,
  PRODUCTIVITY,
  TIMELINE,
  APPLICATION,
  SETTINGS,
  FIELD,
  ALERTS,
  REPORTS,
  ACCOUNT,
  DESIGNATION,
  HOME,
  RESERVED_DOMAINS,
  REDIRECT_TO_PORTAL,
  FORBIDDEN,
  NOT_FOUND,
  PORTAL_ALLOWED_ROUTES,
  // COOKIE_PATHS,
  ME,
  BILLING,
  USER_DASHBOARD,
  USER_ATTENDANCE,
  USER_WELLNESS,
  USER_ACTIVITY,
  USER_PRODUCTIVITY,
  USER_APPLICATION,
  PROJECTS,
  TASK,
  BILLING_ALLOWED_ROUTES,
  SCREENSHOT,
  DOWNLOAD,
};
