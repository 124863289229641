import * as actionTypes from "./actionTypes";

const initialState = {
  signup_create_processing: false,
  signup_create_success: false,
  signup_create_failed: false,
  signup_create_data: [],
  signup_create_message: "",

  signup_verify_token_processing: false,
  signup_verify_token_success: false,
  signup_verify_token_failed: false,
  signup_verify_token_data: [],
  signup_verify_token_message: "",

  signup_google_verify_token_processing: false,
  signup_google_verify_token_success: false,
  signup_google_verify_token_failed: false,
  signup_google_verify_token_data: [],
  signup_google_verify_token_message: "",

  signup_get_token_processing: false,
  signup_get_token_success: false,
  signup_get_token_failed: false,
  signup_get_token_data: [],
  signup_get_token_message: "",

  signup_set_password_processing: false,
  signup_set_password_success: false,
  signup_set_password_failed: false,
  signup_set_password_data: [],
  signup_set_password_message: "",

  set_domain_processing: false,
  set_domain_success: false,
  set_domain_failed: false,
  set_domain_data: [],
  set_domain_message: "",

  check_domain_processing: false,
  check_domain_success: false,
  check_domain_failed: false,
  check_domain_data: [],
  check_domain_message: "",

  set_resend_email_processing: false,
  set_resend_email_success: false,
  set_resend_email_failed: false,
  set_resend_email_data: [],
  set_resend_email_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SIGNUP_CREATE_INITIAL:
      return {
        ...state,
        signup_create_processing: false,
        signup_create_success: false,
        signup_create_failed: false,
        signup_create_data: [],
        signup_create_message: "",
      };
    case actionTypes.SIGNUP_CREATE_PROCESS:
      return {
        ...state,
        signup_create_processing: true,
        signup_create_success: false,
        signup_create_failed: false,
        signup_create_data: [],
        signup_create_message: "",
      };

    case actionTypes.SIGNUP_CREATE_SUCCESS:
      return {
        ...state,
        signup_create_processing: false,
        signup_create_success: true,
        signup_create_failed: false,
        signup_create_data: action.data,
        signup_create_message: "",
      };
    case actionTypes.SIGNUP_CREATE_FAILED:
      return {
        ...state,
        signup_create_processing: false,
        signup_create_success: false,
        signup_create_failed: true,
        signup_create_data: [],
        signup_create_message: action.data,
      };

    case actionTypes.SIGNUP_VERIFY_TOKEN_INITIAL:
      return {
        ...state,
        signup_verify_token_processing: false,
        signup_verify_token_success: false,
        signup_verify_token_failed: false,
        signup_verify_token_data: [],
        signup_verify_token_message: "",
      };
    case actionTypes.SIGNUP_VERIFY_TOKEN_PROCESS:
      return {
        ...state,
        signup_verify_token_processing: true,
        signup_verify_token_success: false,
        signup_verify_token_failed: false,
        signup_verify_token_data: [],
        signup_verify_token_message: "",
      };

    case actionTypes.SIGNUP_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        signup_verify_token_processing: false,
        signup_verify_token_success: true,
        signup_verify_token_failed: false,
        signup_verify_token_data: action.data,
        signup_verify_token_message: "",
      };
    case actionTypes.SIGNUP_VERIFY_TOKEN_FAILED:
      return {
        ...state,
        signup_verify_token_processing: false,
        signup_verify_token_success: false,
        signup_verify_token_failed: true,
        signup_verify_token_data: [],
        signup_verify_token_message: action.data,
      };

    case actionTypes.SIGNUP_GOOGLE_VERIFY_TOKEN_INITIAL:
      return {
        ...state,
        signup_google_verify_token_processing: false,
        signup_google_verify_token_success: false,
        signup_google_verify_token_failed: false,
        signup_google_verify_token_data: [],
        signup_google_verify_token_message: "",
      };
    case actionTypes.SIGNUP_GOOGLE_VERIFY_TOKEN_PROCESS:
      return {
        ...state,
        signup_google_verify_token_processing: true,
        signup_google_verify_token_success: false,
        signup_google_verify_token_failed: false,
        signup_google_verify_token_data: [],
        signup_google_verify_token_message: "",
      };

    case actionTypes.SIGNUP_GOOGLE_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        signup_google_verify_token_processing: false,
        signup_google_verify_token_success: true,
        signup_google_verify_token_failed: false,
        signup_google_verify_token_data: action.data,
        signup_google_verify_token_message: "",
      };
    case actionTypes.SIGNUP_GOOGLE_VERIFY_TOKEN_FAILED:
      return {
        ...state,
        signup_google_verify_token_processing: false,
        signup_google_verify_token_success: false,
        signup_google_verify_token_failed: true,
        signup_google_verify_token_data: [],
        signup_google_verify_token_message: action.data,
      };

    case actionTypes.SIGNUP_GET_TOKEN_INITIAL:
      return {
        ...state,
        signup_get_token_processing: false,
        signup_get_token_success: false,
        signup_get_token_failed: false,
        signup_get_token_data: [],
        signup_get_token_message: "",
      };
    case actionTypes.SIGNUP_GET_TOKEN_PROCESS:
      return {
        ...state,
        signup_get_token_processing: true,
        signup_get_token_success: false,
        signup_get_token_failed: false,
        signup_get_token_data: [],
        signup_get_token_message: "",
      };

    case actionTypes.SIGNUP_GET_TOKEN_SUCCESS:
      return {
        ...state,
        signup_get_token_processing: false,
        signup_get_token_success: true,
        signup_get_token_failed: false,
        signup_get_token_data: action.data,
        signup_get_token_message: "",
      };
    case actionTypes.SIGNUP_GET_TOKEN_FAILED:
      return {
        ...state,
        signup_get_token_processing: false,
        signup_get_token_success: false,
        signup_get_token_failed: true,
        signup_get_token_data: [],
        signup_get_token_message: action.data,
      };

    case actionTypes.SIGNUP_SET_PASSWORD_INITIAL:
      return {
        ...state,
        signup_set_password_processing: false,
        signup_set_password_success: false,
        signup_set_password_failed: false,
        signup_set_password_data: [],
        signup_set_password_message: "",
      };
    case actionTypes.SIGNUP_SET_PASSWORD_PROCESS:
      return {
        ...state,
        signup_set_password_processing: true,
        signup_set_password_success: false,
        signup_set_password_failed: false,
        signup_set_password_data: [],
        signup_set_password_message: "",
      };

    case actionTypes.SIGNUP_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        signup_set_password_processing: false,
        signup_set_password_success: true,
        signup_set_password_failed: false,
        signup_set_password_data: action.data,
        signup_set_password_message: "",
      };
    case actionTypes.SIGNUP_SET_PASSWORD_FAILED:
      return {
        ...state,
        signup_set_password_processing: false,
        signup_set_password_success: false,
        signup_set_password_failed: true,
        signup_set_password_data: [],
        signup_set_password_message: action.data,
      };
    case actionTypes.SET_DOMAIN_INITIAL:
      return {
        ...state,
        set_domain_processing: false,
        set_domain_success: false,
        set_domain_failed: false,
        set_domain_data: [],
        set_domain_message: "",
      };
    case actionTypes.SET_DOMAIN_PROCESS:
      return {
        ...state,
        set_domain_processing: true,
        set_domain_success: false,
        set_domain_failed: false,
        set_domain_data: [],
        set_domain_message: "",
      };

    case actionTypes.SET_DOMAIN_SUCCESS:
      return {
        ...state,
        set_domain_processing: false,
        set_domain_success: true,
        set_domain_failed: false,
        set_domain_data: action.data,
        set_domain_message: "",
      };
    case actionTypes.SET_DOMAIN_FAILED:
      return {
        ...state,
        set_domain_processing: false,
        set_domain_success: false,
        set_domain_failed: true,
        set_domain_data: [],
        set_domain_message: action.data,
      };

    case actionTypes.CHECK_DOMAIN_INITIAL:
      return {
        ...state,
        check_domain_processing: false,
        check_domain_success: false,
        check_domain_failed: false,
        check_domain_data: [],
        check_domain_message: "",
      };
    case actionTypes.CHECK_DOMAIN_PROCESS:
      return {
        ...state,
        check_domain_processing: true,
        check_domain_success: false,
        check_domain_failed: false,
        check_domain_data: [],
        check_domain_message: "",
      };

    case actionTypes.CHECK_DOMAIN_SUCCESS:
      return {
        ...state,
        check_domain_processing: false,
        check_domain_success: true,
        check_domain_failed: false,
        check_domain_data: action.data,
        check_domain_message: "",
      };
    case actionTypes.CHECK_DOMAIN_FAILED:
      return {
        ...state,
        check_domain_processing: false,
        check_domain_success: false,
        check_domain_failed: true,
        check_domain_data: [],
        check_domain_message: action.data,
      };

    case actionTypes.SET_RESEND_EMAIL_INITIAL:
      return {
        ...state,
        set_resend_email_processing: false,
        set_resend_email_success: false,
        set_resend_email_failed: false,
        set_resend_email_data: [],
        set_resend_email_message: "",
      };
    case actionTypes.SET_RESEND_EMAIL_PROCESS:
      return {
        ...state,
        set_resend_email_processing: true,
        set_resend_email_success: false,
        set_resend_email_failed: false,
        set_resend_email_data: [],
        set_resend_email_message: "",
      };

    case actionTypes.SET_RESEND_EMAIL_SUCCESS:
      return {
        ...state,
        set_resend_email_processing: false,
        set_resend_email_success: true,
        set_resend_email_failed: false,
        set_resend_email_data: action.data,
        set_resend_email_message: "",
      };
    case actionTypes.SET_RESEND_EMAIL_FAILED:
      return {
        ...state,
        set_resend_email_processing: false,
        set_resend_email_success: false,
        set_resend_email_failed: true,
        set_resend_email_data: [],
        set_resend_email_message: action.data,
      };
    default:
      return state;
  }
};
