import _ from "lodash";
import { getFormattedTime } from "./timeConverter";

const formatters = {
  TIME: {
    value: function (val) {
      return getFormattedTime(val);
    },
    total: function (w) {
      const total = _.sumBy(w.globals.seriesTotals);
      return getFormattedTime(total);
    },

    legend: function (label, opts) {
      const value = opts.w.globals.series[opts.seriesIndex];
      return (
        '<div class="legenddata"><div class="datapoint">' +
        getFormattedTime(value) +
        "</div>" +
        label +
        "</div>"
      );
    },
  },
  NUMBER: {
    value: function (val) {
      return val;
    },
    total: function (w) {
      return _.sumBy(w.globals.seriesTotals);
    },

    legend: function (label, opts) {
      const value = opts.w.globals.series[opts.seriesIndex];
      return (
        '<div class="legenddata"><div class="datapoint">' +
        value +
        "</div>" +
        label +
        "</div>"
      );
    },
  },
};

const plotOptionsSchema = {
  pie: { donut: { labels: { name: {}, value: {}, total: {} } } },
};

export const getDonutChartOptions = ({
  options = {
    chart: {},
    stroke: {},
    plotOptions: plotOptionsSchema,
    labels: {},
    colors: {},
    tooltip: {},
    legend: {},
    dataLabels: {},
    states: {},
  },
  type = "TIME",
  legendPosition = "bottom",
}) => {
  const {
    chart,
    stroke,
    plotOptions = plotOptionsSchema,
    labels,
    colors,
    tooltip,
    legend,
    dataLabels,
    states,
  } = options;
  const { pie = plotOptionsSchema.pie } = plotOptions;
  const { donut = plotOptionsSchema.pie.donut } = pie;
  const { labels: donutLabels = plotOptionsSchema.pie.donut.labels } = donut;
  const { name, value, total } = donutLabels;

  return {
    options: {
      ...options,
      chart: {
        type: "donut",
        ...chart,
      },
      stroke: {
        show: true,
        width: 1,
        ...stroke,
      },

      plotOptions: {
        pie: {
          customScale: legendPosition === "bottom" ? 0.9 : 1,
          ...pie,
          donut: {
            size: legendPosition === "bottom" ? "80%" : "82%",
            ...donut,
            labels: {
              show: true,
              ...donutLabels,
              name: {
                show: true,
                fontSize: "0.6rem",
                fontWeight: 500,
                offsetY: 25,
                formatter: function (label) {
                  return label;
                },
                ...name,
              },

              value: {
                show: true,
                fontSize: "25px",
                fontWeight: 800,
                offsetY: -20,
                formatter: formatters[type].value,
                ...value,
              },

              total: {
                showAlways: false,
                show: true,
                fontSize: "1.1rem",
                fontWeight: "bold",
                color: "#000",
                formatter: formatters[type].total,
                ...total,
              },
            },
          },
        },
      },

      labels,
      colors,
      tooltip: {
        enabled: true,
        theme: "light",

        custom: function (opts) {
          const xname = opts.w.globals.labels[opts.seriesIndex];
          const percentage = opts.w.globals.seriesPercent[opts.seriesIndex][0];

          return (
            '<div class="wtooltip"> <div class="colordot" style="background:' +
            opts.w.globals.colors[opts.seriesIndex] +
            '"></div>' +
            xname +
            '<div class="w_value">' +
            percentage.toFixed(2) +
            "%" +
            "</div></div>"
          );
        },
        ...tooltip,
      },

      legend: {
        position: legendPosition,
        horizontalAlign: "center",

        formatter: formatters[type].legend,
        ...legend,
      },
      dataLabels: { enabled: false, ...dataLabels },
      states: {
        hover: { filter: { type: "dark", value: 0.5 } },
        active: { filter: { type: "none", value: 0 } },
        ...states,
      },
    },
  };
};
