const Texts = {
  CHECK_API_CALL: "Check api call",
  GET_NOTIFCATION: "Get notification",
  attendance: "Attendance",
};

export const SOMETHING_WENT_WRONG = "Something Went Wrong, Please try Again!";

export const LIVESTREAM_CONNECTION_ERROR =
  "There was some issue while establishing the connection. Please check your network connection or try reloading the page.";

export default Texts;
