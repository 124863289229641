import React, { useContext, useEffect, useState } from "react";
import "../index.scss";

import {
  Button,
  Segmented,
  Typography,
  Alert,
  Modal,
  notification,
} from "antd";

import { BsCheck, BsX } from "react-icons/bs";
import ChangeUsage from "./changeusage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ACCOUNT } from "route/constant";
import {
  fetchInvoices,
  fetchSubscriptions,
  fetchSubscriptionsSuccess,
  initiateSubscription,
  onUpgradeModalClose,
} from "redux/store/billing/action";
import { getFeatures } from "utils/billingUtils";
import { AuthContext } from "contexts/authContext";

const { Title } = Typography;

const UpgradePlan = () => {
  const dispatch = useDispatch();
  const {
    subscriptionsData,
    initiateSubscriptionLoading,
    initiateSubscriptionData,
    planGroupData,
  } = useSelector((state) => state.billing);

  const expired = new Date(subscriptionsData.expiryDate) < Date.now();
  const { tenant_list_data } = useSelector((state) => state.tenant);

  const navigate = useNavigate();

  const billingOptions = ["Monthly", "Yearly"];
  const { getDefaultHeader } = useContext(AuthContext);

  const [billingCycle, setBillingCycle] = useState("Yearly");
  // const [currency, setCurrency] = useState("INR");
  const [selectedPlan, setSelectedPlan] = useState(subscriptionsData.plan);

  const [openModal, setOpenModal] = useState(false);
  const [licenseCount, setLicenseCount] = useState(
    subscriptionsData.totalAllowedUsers
  );

  const onBillingCycleChange = (billingCycle) => {
    setBillingCycle(billingCycle);
  };

  // const onCurrencyChange = (checked) => {
  //   setCurrency(checked ? "INR" : "USD");
  // };

  const verifyBillingAddress = () => {
    if (!tenant_list_data.billing_address?.country) {
      dispatch(onUpgradeModalClose());
      navigate(ACCOUNT);
      notification.warning({ message: "Please update your address" });
      return false;
    }
    return true;
  };

  const openSubscriptionModal = (plan = subscriptionsData.plan) => {
    if (verifyBillingAddress()) {
      setSelectedPlan(plan);
      setOpenModal(true);
    }
  };

  const closeSubscriptionModal = () => {
    setSelectedPlan(subscriptionsData.plan);
    setOpenModal(false);
  };

  const onConfirmSubscriptionPlan = (
    selectedPlanID = selectedPlan.id,
    userLicenseCount = licenseCount
  ) => {
    if (verifyBillingAddress()) {
      dispatch(
        initiateSubscription({
          headers: getDefaultHeader(),
          body: {
            plan_id: selectedPlanID,
            quantity: userLicenseCount,
          },
        })
      );
    }
  };

  useEffect(() => {
    if (initiateSubscriptionData && !initiateSubscriptionLoading) {
      // Make this configurable
      const cbInstance = window.Chargebee.init({
        site: "we360",
      });
      cbInstance.openCheckout({
        hostedPage: () => {
          return new Promise((resolve) => resolve(initiateSubscriptionData));
        },
        error: (error) => {
          console.log(error);
          notification.error({
            message: "There was some Error during checkout, Please try again!!",
          });
        },
        step: function (step) {
          console.log("step", step);
          if (step === "thankyou_screen") {
            cbInstance.closeAll();
          }
        },
        success: function (hostedPageId) {
          console.log("success", hostedPageId);
          notification.success({
            message: "Subscription Updated Successfully!!",
            description:
              "Please wait 30 seconds while we are setting things up.",
          });
          dispatch(onUpgradeModalClose());
          dispatch(
            fetchSubscriptionsSuccess({
              ...subscriptionsData,
              plan: selectedPlan,
              totalAllowedUsers: licenseCount,
            })
          );

          setTimeout(() => {
            dispatch(fetchSubscriptions({ headers: getDefaultHeader() }));
            dispatch(fetchInvoices({ headers: getDefaultHeader() }));
          }, 30000);
        },
        close: function () {
          console.log("closed");
        },
      });
      setOpenModal(false);
    }
  }, [initiateSubscriptionLoading, initiateSubscriptionLoading]);

  const planSortFunction = (planTypeIdA, planTypeIdB) => {
    return (
      planGroupData[planTypeIdA].plan_type.display_order -
      planGroupData[planTypeIdB].plan_type.display_order
    );
  };

  return (
    <div className="up_b">
      {subscriptionsData.billingStatus === "PENDING" && (
        <div className="flex items-center  justify-center w-[60%]  rounded-[10px] my-[25px] mx-auto">
          <Alert
            message="There is problem with your billing, please clear due invoices to avoid account suspension"
            type="warning"
            showIcon
            closable
          />
        </div>
      )}

      <div className="bg-white p-8 w-[60%]  rounded-[10px] my-[25px] mx-auto">
        <div className="flex items-center text-slate-500 text-[25px] font-bold">
          {expired ? "Expired Pan:" : "Current Plan:"}
          <span className="text-indigo-500 ml-3">
            {subscriptionsData.plan.name}
          </span>
        </div>
        <div className="flex items-center text-slate-500 text-[15px] font-semibold">
          <div className="flex items-center">
            License usage:{" "}
            <span className="text-slate-700 ml-3 font-bold">{`${subscriptionsData.activeUsers}/${subscriptionsData.totalAllowedUsers}`}</span>
          </div>
          {!expired && subscriptionsData.billingStatus !== "IN_TRIAL" && (
            <div
              className="my-[25px] ml-[10px] px-[15px] py-[5px] hover:cursor-pointer rounded-[5px] bg-slate-100 hover:bg-indigo-400 text-indigo-500 hover:text-white"
              onClick={() => openSubscriptionModal()}
            >
              Change Usage
            </div>
          )}
        </div>
        {/* <div className="flex items-center text-slate-500 text-[15px] font-bold">
          Your Key Account Manager:{" "}
          <span className="text-slate-700 ml-3">{`${tenant_list_data.contact_first_name} ${tenant_list_data.contact_last_name}`}</span>
        </div> */}
      </div>

      <div className="toggle">
        <Segmented
          options={billingOptions}
          onChange={onBillingCycleChange}
          value={billingCycle}
        />
      </div>

      <div className="upgrad_block">
        <div className="upgrad_box">
          {Object.keys(planGroupData)
            .sort(planSortFunction)
            .map((planTypeId) => {
              const plan =
                planGroupData[planTypeId].plans[billingCycle] ||
                planGroupData[planTypeId].plans.free;
              const planType = planGroupData[planTypeId].plan_type;

              const isFree = !!planGroupData[planTypeId].plans.free;

              const planFeaturesLabel = getFeatures(
                planType.plan_features.features
              );

              return (
                <div
                  key={planTypeId}
                  className={plan.mostPopular ? "popular shadow" : ""}
                >
                  {plan.mostPopular && (
                    <div className="p_text">Most Popular</div>
                  )}
                  <div
                    className={`price_t ${plan.mostPopular ? "m-0" : "shadow"}`}
                  >
                    <Title level={3}>{planType.name}</Title>

                    <p className="txt w-11/12">
                      {planType.plan_features.description}
                    </p>
                    <div className="free price">
                      <div className="price_txt" id="free">
                        <span>{plan.currency_code === "USD" ? `$` : `₹`}</span>{" "}
                        {billingCycle === "Yearly"
                          ? (plan.amount / 12).toLocaleString("en-US")
                          : plan.amount.toLocaleString("en-US")}
                      </div>
                      <div className="p_user">{`/user/month`}</div>
                    </div>
                    <Button
                      loading={
                        initiateSubscriptionLoading && !openModal && isFree
                      }
                      block
                      type={
                        expired
                          ? plan.index
                            ? "primary"
                            : "default"
                          : subscriptionsData.plan.plan_type.display_order >
                            planType.display_order
                          ? "default"
                          : "primary"
                      }
                      className={"sub_btn"}
                      onClick={() => {
                        if (
                          planType.plan_features.features.MAX_USERS.data
                            .endRange < subscriptionsData.activeUsers
                        ) {
                          notification.warning({
                            message: (
                              <>
                                Please reduce active users to{" "}
                                {
                                  planType.plan_features.features.MAX_USERS.data
                                    .endRange
                                }{" "}
                                first, Go to Settings page
                              </>
                            ),
                          });
                        } else {
                          if (isFree) {
                            onConfirmSubscriptionPlan(
                              plan.id,
                              planType.plan_features.features.MAX_USERS.data
                                .endRange
                            );
                            setSelectedPlan(plan);
                            setLicenseCount(
                              planType.plan_features.features.MAX_USERS.data
                                .endRange
                            );
                          } else {
                            openSubscriptionModal(plan);
                          }
                        }
                      }}
                    >
                      {subscriptionsData.billingStatus === "IN_TRIAL" || expired
                        ? isFree
                          ? "Join For Free"
                          : `Join ${planType.name}`
                        : subscriptionsData.plan.id === plan.id
                        ? "Change Usage"
                        : subscriptionsData.plan.plan_type.display_order <
                          planType.display_order
                        ? "Upgrade Plan"
                        : "Downgrade Plan"}
                    </Button>

                    <ul className="feature_p">
                      {planType.plan_features.current.map((featureKey) => {
                        const feature =
                          planType.plan_features.features[featureKey];
                        return (
                          <li
                            key={`${planTypeId}_core_${featureKey}`}
                            className={feature.data ? "check" : "uncheck"}
                          >
                            {feature.data ? <BsCheck /> : <BsX />}{" "}
                            {planFeaturesLabel[featureKey]?.label}
                          </li>
                        );
                      })}
                    </ul>
                    <Title level={5}>Comming Soon</Title>
                    <ul className="feature_p">
                      {planType.plan_features.upcoming.map((featureKey) => {
                        const feature =
                          planType.plan_features.features[featureKey];
                        return (
                          <li
                            key={`${planTypeId}_core_${featureKey}`}
                            className={feature.data ? "check" : "uncheck"}
                          >
                            {feature.data ? <BsCheck /> : <BsX />}{" "}
                            {planFeaturesLabel[featureKey]?.label}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <Modal
        // title=""
        centered
        open={openModal}
        // onOk={() => setOpenModal(false)}
        onCancel={closeSubscriptionModal}
        footer={[
          <Button key="back" onClick={closeSubscriptionModal}>
            Cancel
          </Button>,
          <Button
            disabled={
              licenseCount === subscriptionsData.totalAllowedUsers &&
              subscriptionsData.plan.id === selectedPlan.id
            }
            key="submit"
            type="primary"
            onClick={() => {
              onConfirmSubscriptionPlan();
            }}
            loading={initiateSubscriptionLoading}
          >
            Confirm
          </Button>,
        ]}
      >
        <ChangeUsage
          selectedPlan={selectedPlan}
          setValue={setLicenseCount}
          value={licenseCount}
        />
      </Modal>
    </div>
  );
};

export default UpgradePlan;
