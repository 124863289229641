//* WELLNESS360 Master START*/
//* LIST *//

export const WELLNESS360_LIST_INITIAL = "WELLNESS360_LIST_INITIAL";
export const WELLNESS360_LIST_PROCESS = "WELLNESS360_LIST_PROCESS";
export const WELLNESS360_LIST_SUCCESS = "WELLNESS360_LIST_SUCCESS";
export const WELLNESS360_LIST_FAILED = "WELLNESS360_LIST_FAILED";

//* CREATE *//

export const WELLNESS360_CREATE_INITIAL = "WELLNESS360_CREATE_INITIAL";
export const WELLNESS360_CREATE_PROCESS = "WELLNESS360_CREATE_PROCESS";
export const WELLNESS360_CREATE_SUCCESS = "WELLNESS360_CREATE_SUCCESS";
export const WELLNESS360_CREATE_FAILED = "WELLNESS360_CREATE_FAILED";

//* EDIT *//

export const WELLNESS360_EDIT_INITIAL = "WELLNESS360_EDIT_INITIAL ";
export const WELLNESS360_EDIT_PROCESS = "WELLNESS360_EDIT_PROCESS";
export const WELLNESS360_EDIT_SUCCESS = "WELLNESS360_EDIT_SUCCESS";
export const WELLNESS360_EDIT_FAILED = "WELLNESS360_EDIT_FAILED";

//* WELLNESS360_ END */

//*  WELLNESS360 Summary Healthy Employees *//

export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_INITIAL =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_INITIAL";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PROCESS =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PROCESS";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_SUCCESS =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_SUCCESS";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_FAILED =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_FAILED";
