import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const fetchTimelineTable = (payload) => {
  return {
    type: actionTypes.FETCH_TIMELINE_TABLE,
    payload,
  };
};

export const fetchTimelineTableSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TIMELINE_TABLE_SUCCESS,
    payload,
  };
};

export const fetchTimelineTableFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TIMELINE_TABLE_FAILED,
    payload,
  };
};

export const setHourlyActivityProductivityLoading = (payload) => {
  return {
    type: actionTypes.SET_HOURLY_ACTIVITY_PRODUCTIVITY_LOADING,
    payload,
  };
};

export const setHourlySessionBreakdown = (payload) => {
  return {
    type: actionTypes.SET_HOURLY_SESSION_BREAKDOWN,
    payload,
  };
};

export const fetchHourlyActivityProductivity = (payload) => {
  return {
    type: actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY,
    payload,
  };
};

export const fetchHourlyActivityProductivitySuccess = (payload) => {
  return {
    type: actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_SUCCESS,
    payload,
  };
};

export const fetchHourlyActivityProductivityFailed = (payload) => {
  return {
    type: actionTypes.FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_FAILED,
    payload,
  };
};

export const fetchPowerLogs = (payload) => {
  return {
    type: actionTypes.FETCH_POWER_LOGS,
    payload,
  };
};

export const fetchPowerLogsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_POWER_LOGS_SUCCESS,
    payload,
  };
};

export const fetchPowerLogsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_POWER_LOGS_FAILED,
    payload,
  };
};

export const fetchSessionAppLogs = (payload) => {
  return {
    type: actionTypes.FETCH_SESSION_APP_LOG,
    payload,
  };
};

export const fetchSessionAppSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_SESSION_APP_LOG_SUCCESS,
    payload,
  };
};

export const fetchSessionAppFailed = (payload) => {
  return {
    type: actionTypes.FETCH_SESSION_APP_LOG_FAILED,
    payload,
  };
};
