import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Badge, Layout, Menu } from "antd";
import { useTranslation } from "react-i18next";

import { SettingsM } from "./../../components/icons/setting_m";
import { Attendance } from "./../../components/icons/attendance";
import { Dashboard } from "./../../components/icons/dashboard";
import { Wellness } from "./../../components/icons/wellness";
import { Livestream } from "./../../components/icons/livestream";
import { Activity } from "./../../components/icons/activity";
import { Productivity } from "./../../components/icons/productivity";
import { Timeline } from "./../../components/icons/timeline";
import { Application } from "./../../components/icons/application";
import { Reports } from "./../../components/icons/reports";
import { Project } from "./../../components/icons/project";
import logolight from "./../../assets/images/logo-light.png";
import { Alerts } from "components/icons/alerts";
import { TbLiveView } from "react-icons/tb";
import { MdScreenshotMonitor } from "react-icons/md";
import { Realtime } from "components/icons/RealTime";
import { Analytics } from "components/icons/Analytics";

import "./index.scss";
import {
  ACTIVITY,
  ALERTS,
  APPLICATION,
  ATTENDANCE,
  DASHBOARD,
  LIVESTREAM,
  ME,
  PRODUCTIVITY,
  REPORTS,
  SETTINGS,
  TIMELINE,
  WELLNESS,
  PROJECTS,
  FIELD,
  SCREENSHOT,
  BILLING_ALLOWED_ROUTES,
} from "route/constant";
import { roles, rolesPermissionLevel } from "constants/constant";
import { AuthContext } from "contexts/authContext";
import { AiOutlineUser } from "react-icons/ai";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { pathToFeatureKeyMap } from "utils/billingUtils";
import { useSelector } from "react-redux";

const { Sider } = Layout;

const Sidebar = (props) => {
  const { t } = useTranslation();
  const { silderBtn } = props;
  const [collapsed, setCollapsed] = useState(false);

  const { role } = useContext(AuthContext);

  const { subscriptionsData } = useSelector((state) => state.billing);

  const clickSilder = (val) => {
    setCollapsed(val);
    silderBtn(val);
  };

  const getFilteredMenuItems = (item) =>
    rolesPermissionLevel[item.requiredRole] <= rolesPermissionLevel[role] &&
    (!item.path ||
      BILLING_ALLOWED_ROUTES.includes(item.path) ||
      subscriptionsData?.plan?.plan_type?.plan_features?.ui
        .feature_disable_mode !== "HIDE" ||
      subscriptionsData?.plan?.plan_type?.plan_features?.features?.[
        pathToFeatureKeyMap[item.path]
      ]?.data) &&
    (!item.children || item.children.length > 0);

  const items = [
    // {
    //   path: "/some-path",
    //   label: "a menu item",
    //   icon: <Icon type="fire" />,
    // },
    {
      icon: <Dashboard />,
      label: t("dashboard"),
      path: DASHBOARD,
      key: "dashboard",
      requiredRole: roles.MANAGER,
    },
    {
      path: ATTENDANCE,
      icon: <Attendance />,
      label: t("attendance"),
      key: "attendance",
      requiredRole: roles.MANAGER,
    },
    {
      icon: <Realtime />,
      label: "Real Time",
      key: "realtime",
      requiredRole: roles.MANAGER,
      children: [
        {
          path: LIVESTREAM,
          icon: <Livestream viewBox="0 0 24 24" />,
          label: "Livestream",
          key: "livestream",
          requiredRole: roles.MANAGER,
        },
        {
          path: FIELD,
          icon: <TbLiveView className="w-[18px] mr-3.5" />,
          key: "field",
          label: (
            <>
              {t("Field")}
              <Badge count="Beta" color="teal" offset={[10, 0]} size="small" />
            </>
          ),
          requiredRole: roles.MANAGER,
        },
      ].filter(getFilteredMenuItems),
    },
    {
      icon: <Analytics />,
      label: "Analytics",
      key: "analytics",
      requiredRole: roles.MANAGER,
      children: [
        {
          path: TIMELINE,
          icon: <Timeline viewBox="0 0 24 24" />,
          label: t("timeline"),
          key: "timeline",
          requiredRole: roles.MANAGER,
        },
        {
          path: ACTIVITY,
          icon: <Activity viewBox="0 0 24 24" />,
          label: t("activity"),
          key: "activity",
          requiredRole: roles.MANAGER,
        },
        {
          path: PRODUCTIVITY,
          icon: <Productivity viewBox="0 0 24 24" />,
          label: t("productivity"),
          key: "productivity",
          requiredRole: roles.MANAGER,
        },
        {
          path: SCREENSHOT,
          icon: <MdScreenshotMonitor className="w-[18px] mr-3.5" />,
          label: "Screenshots",
          key: "screenshots",
          requiredRole: roles.MANAGER,
        },
        {
          path: APPLICATION,
          icon: <Application viewBox="0 0 24 24" />,
          label: "Apps & URLs",
          key: "app&url",
          requiredRole: roles.MANAGER,
        },
        {
          path: WELLNESS,
          icon: <Wellness viewBox="0 0 24 24" />,
          label: "Wellness",
          key: "wellness",
          requiredRole: roles.MANAGER,
        },
      ].filter(getFilteredMenuItems),
    },
    {
      path: ALERTS,
      icon: <Alerts />,
      label: "Alerts",
      key: "alerts",
      requiredRole: roles.OWNER,
    },

    {
      path: REPORTS,
      icon: <Reports />,
      label: t("reports"),
      key: "reports",
      requiredRole: roles.MANAGER,
    },
    {
      path: PROJECTS,
      icon: <Project />,
      label: (
        <>
          Projects
          <Badge count="Beta" color="teal" offset={[10, 0]} size="small" />
        </>
      ),
      key: "project",
      requiredRole: roles.USER,
    },
    {
      path: ME,
      icon: <AiOutlineUser size={20} />,
      label: role === roles.USER ? "Me" : "User Detail",
      key: "me",
      requiredRole: roles.USER,
    },
    {
      path: SETTINGS,
      icon: <SettingsM />,
      label: t("settings"),
      key: "settings",
      requiredRole: roles.OWNER,
    },
  ].filter(getFilteredMenuItems);

  // Function to render menu items (including submenus if they exist)
  const renderMenuItem = (item) => {
    // If the item has children, render it as a submenu
    if (item.children && item.children.length > 0) {
      const submenuItems = item.children.map((subItem) =>
        renderMenuItem(subItem)
      );
      return (
        <Menu.SubMenu
          key={item.key}
          title={item.label}
          icon={item.icon}
          popupClassName="submenu-item"
        >
          {submenuItems}
        </Menu.SubMenu>
      );
    }

    // Otherwise, render it as a regular menu item
    return (
      <Menu.Item key={item.key} icon={item.icon}>
        <NavLink to={item.path}>{item.label}</NavLink>
      </Menu.Item>
    );
  };

  return (
    <Sider
      trigger={null}
      collapsed={collapsed}
      className="aside_slid"
      width={"220px"}
      collapsible
    >
      <div
        className="sider-logo mainlogo"
        style={{ "background-color": "#0e082e", cursor: "pointer" }}
      >
        <img src={logolight} />

        {collapsed ? (
          <div
            style={{
              color: "white",
              float: "right",
              paddingLeft: "5px",
              fontSize: "25px",
            }}
          >
            <RiMenuUnfoldFill onClick={() => clickSilder(!collapsed)} />
          </div>
        ) : (
          <div
            style={{
              color: "white",
              float: "right",
              margin: "0 -20px 0 0",
              fontSize: "25px",
            }}
          >
            <RiMenuFoldFill onClick={() => clickSilder(!collapsed)} />
          </div>
        )}
      </div>

      <div className="side_content">
        <Menu
          mode="inline"
          className="menu-item"
          selectable={true}
          selectedKeys={[]}
        >
          {items.map((item) => renderMenuItem(item))}
        </Menu>
      </div>
    </Sider>
  );
};

Sidebar.propTypes = {
  showBackground: PropTypes.bool,
  silderbtn: PropTypes.bool,
};

export default Sidebar;
