import React from "react";

export const Realtime = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path  d="M3.78,10.68c0.36,0,0.71,0,1.06,0c0.33,0,0.61-0.19,0.71-0.48c0.09-0.27,0-0.6-0.27-0.78
		C5.14,9.33,4.95,9.3,4.78,9.29C4.45,9.27,4.13,9.28,3.8,9.28c0.14-2.72,2.65-5.32,5.52-5.53c0,0.36,0,0.72,0,1.09
		C9.31,5.3,9.64,5.52,9.93,5.57c0.33,0.06,0.69-0.23,0.74-0.61c0.04-0.25,0.04-0.5,0.05-0.75c0.01-0.15,0-0.3,0-0.46
		c2.78,0.25,5.23,2.56,5.53,5.52c-0.37,0-0.74,0-1.11,0c-0.46,0-0.73,0.4-0.71,0.74c0.02,0.33,0.33,0.65,0.66,0.66
		c0.38,0,0.77,0,1.15,0c-0.25,2.87-2.72,5.29-5.52,5.53c0-0.33,0-0.67,0-1c0-0.32-0.1-0.6-0.42-0.73c-0.44-0.17-0.94,0.01-0.97,0.6
		c-0.02,0.36,0,0.73-0.01,1.09c0,0.01,0,0.01-0.01,0.02C6.87,16.12,4.04,13.78,3.78,10.68z M9.32,8.79c0,0.4-0.02,0.8,0.01,1.2
		c0.01,0.16,0.09,0.35,0.2,0.47c0.46,0.49,0.93,0.96,1.42,1.42c0.1,0.1,0.25,0.18,0.38,0.19c0.31,0.03,0.57-0.11,0.7-0.41
		c0.12-0.27,0.1-0.53-0.16-0.81c-0.16-0.18-0.34-0.34-0.51-0.51c-0.21-0.21-0.5-0.39-0.61-0.64c-0.11-0.26-0.03-0.6-0.04-0.9
		c-0.01-0.42,0-0.85-0.05-1.27c-0.04-0.39-0.36-0.59-0.78-0.55C9.56,7,9.3,7.37,9.32,7.64C9.33,8.02,9.32,8.41,9.32,8.79z"/>
	<path d="M16.32,4.15c-0.02,0.31-0.03,0.6-0.07,0.88c-0.06,0.34-0.41,0.59-0.74,0.55c-0.33-0.04-0.6-0.35-0.6-0.69
		c0-0.78,0-1.55,0-2.33c0-0.43,0.26-0.72,0.7-0.72c0.76-0.01,1.51-0.01,2.27,0c0.31,0.01,0.6,0.11,0.72,0.51
		c0.08,0.28-0.01,0.56-0.29,0.76c-0.09,0.07-0.22,0.1-0.33,0.11c-0.17,0.02-0.35,0-0.56,0c0.17,0.22,0.32,0.41,0.47,0.6
		c0.6,0.75,1.07,1.58,1.43,2.47c0.29,0.71,0.49,1.46,0.59,2.22c0.09,0.65,0.12,1.32,0.09,1.98c-0.04,0.98-0.23,1.95-0.57,2.88
		c-0.33,0.91-0.78,1.76-1.36,2.53c-0.3,0.39-0.61,0.78-0.96,1.13c-0.73,0.72-1.55,1.34-2.46,1.81c-0.65,0.33-1.31,0.62-2.01,0.8
		c-0.52,0.13-1.05,0.21-1.58,0.28c-0.41,0.06-0.83,0.1-1.24,0.09c-0.87-0.02-1.72-0.16-2.56-0.4C6,19.25,4.87,18.67,3.86,17.88
		c-1.36-1.06-2.38-2.4-3.06-3.98c-0.3-0.7-0.54-1.43-0.63-2.2C0.09,11.06,0,10.42,0,9.78c0-0.85,0.14-1.69,0.38-2.52
		c0.29-1.04,0.75-2,1.35-2.9c0.45-0.68,0.99-1.29,1.59-1.83c1.13-1.02,2.44-1.76,3.91-2.16C7.84,0.21,8.47,0.14,9.1,0.05
		C9.39,0,9.69,0.01,9.99,0c0.41-0.01,0.73,0.29,0.73,0.68c0,0.36-0.34,0.72-0.7,0.72c-1.07-0.01-2.1,0.19-3.1,0.57
		C6.17,2.24,5.49,2.62,4.86,3.09c-0.8,0.6-1.48,1.32-2.03,2.16C2.29,6.06,1.89,6.93,1.66,7.87C1.53,8.41,1.47,8.98,1.44,9.54
		c-0.03,0.55-0.03,1.11,0.05,1.65c0.13,0.98,0.45,1.92,0.92,2.8c0.35,0.66,0.79,1.27,1.29,1.82c0.8,0.88,1.76,1.55,2.84,2.04
		c0.69,0.31,1.4,0.56,2.15,0.62c0.64,0.06,1.3,0.12,1.94,0.08c1.24-0.07,2.42-0.42,3.5-1.04c0.53-0.31,1.03-0.68,1.51-1.06
		c1.01-0.81,1.72-1.85,2.25-3.02c0.29-0.65,0.51-1.32,0.59-2.02c0.06-0.55,0.13-1.1,0.13-1.65c-0.02-1.13-0.26-2.21-0.72-3.25
		c-0.32-0.72-0.74-1.38-1.23-2C16.54,4.39,16.43,4.27,16.32,4.15z"/>
    </svg>
  );
};
