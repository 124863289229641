import { put } from "redux-saga/effects";
import { ProductivitySummaryAction } from "../action";
import { Client } from "../../../utils/transport";

export function* ProductivitySummaryWorking(data) {
  let config = {
    method: "POST",
    url: "query/productivity/total_working_time",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryWorkingTimeSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryWorkingTimeFailed(response)
      );
    }
  } catch (err) {
    yield put(
      ProductivitySummaryAction.ProductivitySummaryWorkingTimeFailed(err)
    );
  }
}

export function* GetProductivitySummaryCategory(data) {
  let config = {
    method: "POST",
    url: "query/productivity/top_category",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryTopCategorySuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryTopCategoryFailed(response)
      );
    }
  } catch (err) {
    yield put(
      ProductivitySummaryAction.ProductivitySummaryTopCategoryFailed(err)
    );
  }
}

export function* ProductivitySummaryAppUrl(data) {
  let config = {
    method: "POST",
    url: "query/activity/tops",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryTopAppUrlSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryTopAppUrlFailed(response)
      );
    }
  } catch (err) {
    yield put(
      ProductivitySummaryAction.ProductivitySummaryTopAppUrlFailed(err)
    );
  }
}

export function* ProductivitySummaryBreakDown(data) {
  let config = {
    method: "POST",
    url: "query/productivity/productivity_breakdown",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryProductivityBreakdownSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryProductivityBreakdownFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ProductivitySummaryAction.ProductivitySummaryProductivityBreakdownFailed(
        err
      )
    );
  }
}

export function* GetProductivitySummaryTopBottom(data) {
  let config = {
    method: "POST",
    url: "query/productivity/productivity_stats",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryTopBottomActiveTeamSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryTopBottomActiveTeamFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ProductivitySummaryAction.ProductivitySummaryTopBottomActiveTeamFailed(
        err
      )
    );
  }
}

export function* GetProductivitySummaryTeamWise(data) {
  let config = {
    method: "POST",
    url: "query/productivity/teamwise_productivity",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryTeamWiseProductivityBreakdownSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivitySummaryAction.ProductivitySummaryTeamWiseProductivityBreakdownFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ProductivitySummaryAction.ProductivitySummaryTeamWiseProductivityBreakdownFailed(
        err
      )
    );
  }
}
