import * as actionTypes from "./actionType";

const initialState = {
  team_create_processing: false,
  team_create_success: false,
  team_create_failed: false,
  team_create_data: [],
  team_create_message: "",

  team_list_processing: false,
  team_list_success: false,
  team_list_failed: false,
  team_list_data: [],
  team_list_message: "",

  team_update_processing: false,
  team_update_success: false,
  team_update_failed: false,
  team_update_data: [],
  team_update_message: "",

  team_delete_processing: false,
  team_delete_success: false,
  team_delete_failed: false,
  team_delete_data: [],
  team_delete_message: "",

  team_member_processing: false,
  team_member_success: false,
  team_member_failed: false,
  team_member_data: [],
  team_member_message: "",

  team_maneger_processing: false,
  team_maneger_success: false,
  team_maneger_failed: false,
  team_maneger_data: [],
  team_maneger_message: "",

  update_user_setting_processing: false,
  update_user_setting_success: false,
  update_user_setting_failed: false,
  update_user_setting_data: [],
  update_user_setting_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.TEAM_CREATE_INITIAL:
      return {
        ...state,
        team_create_processing: false,
        team_create_success: false,
        team_create_failed: false,
        team_create_data: [],
        team_create_message: "",
      };
    case actionTypes.TEAM_CREATE_PROCESS:
      return {
        ...state,
        team_create_processing: true,
        team_create_success: false,
        team_create_failed: false,
        team_create_data: [],
        team_create_message: "",
      };

    case actionTypes.TEAM_CREATE_SUCCESS:
      return {
        ...state,
        team_create_processing: false,
        team_create_success: true,
        team_create_failed: false,
        team_create_data: action.data,
        team_create_message: "",
      };
    case actionTypes.TEAM_CREATE_FAILED:
      return {
        ...state,
        team_create_processing: false,
        team_create_success: false,
        team_create_failed: true,
        team_create_data: [],
        team_create_message: action.data,
      };

    case actionTypes.TEAM_LIST_INITIAL:
      return {
        ...state,
        team_list_processing: false,
        team_list_success: false,
        team_list_failed: false,
        team_list_data: [],
        team_list_message: "",
      };
    case actionTypes.TEAM_LIST_PROCESS:
      return {
        ...state,
        team_list_processing: true,
        team_list_success: false,
        team_list_failed: false,
        team_list_data: [],
        team_list_message: "",
      };

    case actionTypes.TEAM_LIST_SUCCESS:
      return {
        ...state,
        team_list_processing: false,
        team_list_success: true,
        team_list_failed: false,
        team_list_data: action.data.data,
        team_list_message: "",
      };
    case actionTypes.TEAM_LIST_FAILED:
      return {
        ...state,
        team_list_processing: false,
        team_list_success: false,
        team_list_failed: true,
        team_list_data: [],
        team_list_message: action.data,
      };

    case actionTypes.TEAM_UPDATE_INITIAL:
      return {
        ...state,
        team_update_processing: false,
        team_update_success: false,
        team_update_failed: false,
        team_update_data: [],
        team_update_message: "",
      };
    case actionTypes.TEAM_UPDATE_PROCESS:
      return {
        ...state,
        team_update_processing: true,
        team_update_success: false,
        team_update_failed: false,
        team_update_data: [],
        team_update_message: "",
      };

    case actionTypes.TEAM_UPDATE_SUCCESS:
      return {
        ...state,
        team_update_processing: false,
        team_update_success: true,
        team_update_failed: false,
        team_update_data: action.data,
        team_update_message: "",
      };
    case actionTypes.TEAM_UPDATE_FAILED:
      return {
        ...state,
        team_update_processing: false,
        team_update_success: false,
        team_update_failed: true,
        team_update_data: [],
        team_update_message: action.data,
      };

    case actionTypes.TEAM_DELETE_INITIAL:
      return {
        ...state,
        team_delete_processing: false,
        team_delete_success: false,
        team_delete_failed: false,
        team_delete_data: [],
        team_delete_message: "",
      };
    case actionTypes.TEAM_DELETE_PROCESS:
      return {
        ...state,
        team_delete_processing: true,
        team_delete_success: false,
        team_delete_failed: false,
        team_delete_data: [],
        team_delete_message: "",
      };

    case actionTypes.TEAM_DELETE_SUCCESS:
      return {
        ...state,
        team_delete_processing: false,
        team_delete_success: true,
        team_delete_failed: false,
        team_delete_data: action.data,
        team_delete_message: "",
      };
    case actionTypes.TEAM_DELETE_FAILED:
      return {
        ...state,
        team_delete_processing: false,
        team_delete_success: false,
        team_delete_failed: true,
        team_delete_data: [],
        team_delete_message: action.data,
      };

    case actionTypes.TEAM_MEMBER_UPDATE_INITIAL:
      return {
        ...state,
        team_member_processing: false,
        team_member_success: false,
        team_member_failed: false,
        team_member_data: [],
        team_member_message: "",
      };
    case actionTypes.TEAM_MEMBER_UPDATE_PROCESS:
      return {
        ...state,
        team_member_processing: true,
        team_member_success: false,
        team_member_failed: false,
        team_member_data: [],
        team_member_message: "",
      };

    case actionTypes.TEAM_MEMBER_UPDATE_SUCCESS:
      return {
        ...state,
        team_member_processing: false,
        team_member_success: true,
        team_member_failed: false,
        team_member_data: action.data,
        team_member_message: "",
      };
    case actionTypes.TEAM_MEMBER_UPDATE_FAILED:
      return {
        ...state,
        team_member_processing: false,
        team_member_success: false,
        team_member_failed: true,
        team_member_data: [],
        team_member_message: action.data,
      };

    case actionTypes.TEAM_MANEGER_UPDATE_INITIAL:
      return {
        ...state,
        team_maneger_processing: false,
        team_maneger_success: false,
        team_maneger_failed: false,
        team_maneger_data: [],
        team_maneger_message: "",
      };
    case actionTypes.TEAM_MANEGER_UPDATE_PROCESS:
      return {
        ...state,
        team_maneger_processing: true,
        team_maneger_success: false,
        team_maneger_failed: false,
        team_maneger_data: [],
        team_maneger_message: "",
      };

    case actionTypes.TEAM_MANEGER_UPDATE_SUCCESS:
      return {
        ...state,
        team_maneger_processing: false,
        team_maneger_success: true,
        team_maneger_failed: false,
        team_maneger_data: action.data,
        team_maneger_message: "",
      };
    case actionTypes.TEAM_MANEGER_UPDATE_FAILED:
      return {
        ...state,
        team_maneger_processing: false,
        team_maneger_success: false,
        team_maneger_failed: true,
        team_maneger_data: [],
        team_maneger_message: action.data,
      };

    case actionTypes.UPDATE_USER_SETTING_INITIAL:
      return {
        ...state,
        update_user_setting_processing: false,
        update_user_setting_success: false,
        update_user_setting_failed: false,
        update_user_setting_data: [],
        update_user_setting_message: "",
      };
    case actionTypes.UPDATE_USER_SETTING_PROCESS:
      return {
        ...state,
        update_user_setting_processing: true,
        update_user_setting_success: false,
        update_user_setting_failed: false,
        update_user_setting_data: [],
        update_user_setting_message: "",
      };

    case actionTypes.UPDATE_USER_SETTING_SUCCESS:
      return {
        ...state,
        update_user_setting_processing: false,
        update_user_setting_success: true,
        update_user_setting_failed: false,
        update_user_setting_data: action.data,
        update_user_setting_message: "",
      };
    case actionTypes.UPDATE_USER_SETTING_FAILED:
      return {
        ...state,
        update_user_setting_processing: false,
        update_user_setting_success: false,
        update_user_setting_failed: true,
        update_user_setting_data: [],
        update_user_setting_message: action.data,
      };

    default:
      return state;
  }
};
