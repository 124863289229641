import * as actionTypes from "./actionTypes";

const searchApplicationUrl = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const initialState = {
  //*GLOBAL*//

  //* Application URL LIST *//

  applicationurl_list_processing: false,
  applicationurl_list_success: false,
  applicationurl_list_failed: false,
  applicationurl_list_data: [],
  applicationurlMaster: [],
  applicationurl_list_message: "",
  applicationurl_list_pagenation: {},

  //* Application POLICY MAPPING LIST *//

  application_policy_mapping_list_processing: false,
  application_policy_mapping_list_success: false,
  application_policy_mapping_list_failed: false,
  application_policy_mapping_list_data: [],
  application_policy_mapping_list_message: "",

  //* Application CAtegory LIST *//

  applicationcategory_list_processing: false,
  applicationcategory_list_success: false,
  applicationcategory_list_failed: false,
  applicationcategory_list_data: [],
  applicationcategory_list_message: [],

  //* APPLICATION URL EDIT *//

  applicationurl_edit_processing: false,
  applicationurl_edit_success: false,
  applicationurl_edit_failed: false,
  applicationurl_edit_data: [],
  applicationurl_edit_message: "",

  //* APPLICATION policy MAPPING EDIT *//

  application_policy_mapping_edit_processing: false,
  application_policy_mapping_edit_success: false,
  application_policy_mapping_edit_failed: false,
  application_policy_mapping_edit_data: [],
  application_policy_mapping_edit_message: "",

  //* APPLICATION policy MAPPING CREATE *//

  application_policy_mapping_create_processing: false,
  application_policy_mapping_create_success: false,
  application_policy_mapping_create_failed: false,
  application_policy_mapping_create_data: [],
  application_policy_mapping_create_message: "",

  //* APPLICATION POLICY SEARCH *//

  application_policy_search_processing: false,
  application_policy_search_success: false,
  application_policy_search_failed: false,
  application_policy_search_data: [],
  application_policy_search_message: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.APPLICATION_CATEGORY_LIST_INITIAL:
      return {
        ...state,
        applicationcategory_list_processing: true,
        applicationcategory_list_success: false,
        applicationcategory_list_failed: false,
        applicationcategory_list_data: [],
        applicationcategory_list_message: "",
      };
    case actionTypes.APPLICATION_CATEGORY_LIST_PROCESS:
      return {
        ...state,
        applicationcategory_list_processing: true,
        applicationcategory_list_success: false,
        applicationcategory_list_failed: false,
        applicationcategory_list_data: [],
        applicationcategory_list_message: "",
      };
    case actionTypes.APPLICATION_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        applicationcategory_list_processing: false,
        applicationcategory_list_success: true,
        applicationcategory_list_failed: false,
        applicationcategory_list_data: action.data.data,
        applicationcategory_list_message: "",
      };
    case actionTypes.APPLICATION_CATEGORY_LIST_FAILED:
      return {
        ...state,
        applicationcategory_list_processing: false,
        applicationcategory_list_success: false,
        applicationcategory_list_failed: true,
        applicationcategory_list_data: [],
        applicationcategory_list_message: "",
      };

    case actionTypes.APPLICATION_URL_LIST_INITIAL:
      return {
        ...state,
        applicationurl_list_processing: false,
        applicationurl_list_success: false,
        applicationurl_list_failed: false,
        applicationurl_list_data: [],
        applicationurlMaster: [],
        applicationurl_list_message: "",
      };
    case actionTypes.APPLICATION_URL_LIST_PROCESS:
      return {
        ...state,
        applicationurl_list_processing: true,
        applicationurl_list_success: false,
        applicationurl_list_failed: false,
        // applicationurl_list_data: [],
        applicationurlMaster: [],
        applicationurl_list_message: "",
      };
    case actionTypes.APPLICATION_URL_LIST_SUCCESS:
      return {
        ...state,
        applicationurl_list_processing: false,
        applicationurl_list_success: true,
        applicationurl_list_failed: false,
        applicationurl_list_data: action.data?.data,
        applicationurl_list_pagenation: action.data?.meta,
        applicationurlMaster: action.data?.data,
        applicationurl_list_message: "",
      };
    case actionTypes.APPLICATION_URL_LIST_FAILED:
      return {
        ...state,
        applicationurl_list_processing: false,
        applicationurl_list_success: false,
        applicationurl_list_failed: true,
        applicationurl_list_data: [],
        applicationurlMaster: [],
        applicationurl_list_message: "",
        applicationurl_list_pagenation: {},
      };
    case actionTypes.APPLICATION_POLICY_MAPPING_LIST_INITIAL:
      return {
        ...state,
        application_policy_mapping_list_processing: true,
        application_policy_mapping_list_success: false,
        application_policy_mapping_list_failed: false,
        application_policy_mapping_list_data: [],
        application_policy_mapping_list_data: "",
      };
    case actionTypes.APPLICATION_POLICY_MAPPING_LIST_PROCESS:
      return {
        ...state,
        application_policy_mapping_list_processing: true,
        application_policy_mapping_list_success: false,
        application_policy_mapping_list_failed: false,
        application_policy_mapping_list_data: [],
        application_policy_mapping_list_data: "",
      };
    case actionTypes.APPLICATION_POLICY_MAPPING_LIST_SUCCESS:
      return {
        ...state,
        application_policy_mapping_list_processing: false,
        application_policy_mapping_list_success: true,
        application_policy_mapping_list_failed: false,
        application_policy_mapping_list_data: action.data,
        application_policy_mapping_list_message: "",
      };
    case actionTypes.APPLICATION_POLICY_MAPPING_LIST_FAILED:
      return {
        ...state,
        application_policy_mapping_list_processing: false,
        application_policy_mapping_list_success: false,
        application_policy_mapping_list_failed: true,
        application_policy_mapping_list_data: [],
        application_policy_mapping_list_message: "",
      };

    case actionTypes.APPLICATION_URL_SEARCH:
      return {
        ...state,
        applicationurl_list_data: searchApplicationUrl(
          state.applicationurlMaster,
          action.data
        ),
      };
    case actionTypes.APPLICATION_URL_EDIT_INITIAL:
      return {
        ...state,
        applicationurl_edit_processing: false,
        applicationurl_edit_success: false,
        applicationurl_edit_failed: false,
        applicationurl_edit_data: [],
        applicationurl_edit_message: "",
      };
    case actionTypes.APPLICATION_URL_EDIT_PROCESS:
      return {
        ...state,
        applicationurl_edit_processing: true,
        applicationurl_edit_success: false,
        applicationurl_edit_failed: false,
        applicationurl_edit_data: [],
        applicationurl_edit_message: "",
      };

    case actionTypes.APPLICATION_URL_EDIT_SUCCESS:
      const fetchlist = [...state.applicationurl_list_data];
      const findIndex = fetchlist.findIndex(
        (item) => item.id == action.data.id
      );
      fetchlist[findIndex] = action.data;

      return {
        ...state,
        applicationurl_edit_processing: false,
        applicationurl_edit_success: true,
        applicationurl_edit_failed: false,
        applicationurl_edit_data: action.data,
        applicationurl_list_data: fetchlist,
        applicationurlMaster: fetchlist,
        applicationurl_edit_message: "",
      };

    case actionTypes.APPLICATION_URL_EDIT_FAILED:
      return {
        ...state,
        applicationurl_edit_processing: false,
        applicationurl_edit_success: false,
        applicationurl_edit_failed: true,
        applicationurl_edit_data: action.data,
        applicationurl_edit_message: "",
      };

    case actionTypes.APPLICATION_POLICY_MAPPING_CREATE_INITIAL:
      return {
        ...state,
        application_policy_mapping_create_processing: true,
        application_policy_mapping_create_success: false,
        application_policy_mapping_create_failed: false,
        application_policy_mapping_create_data: [],
        application_policy_mapping_create_message: "",
      };
    case actionTypes.APPLICATION_POLICY_MAPPING_CREATE_PROCESS:
      return {
        ...state,
        application_policy_mapping_create_processing: true,
        application_policy_mapping_create_success: false,
        application_policy_mapping_create_failed: false,
        application_policy_mapping_create_data: [],
        application_policy_mapping_create_message: "",
      };

    case actionTypes.APPLICATION_POLICY_MAPPING_CREATE_SUCCESS: {
      return {
        ...state,
        application_policy_mapping_create_processing: false,
        application_policy_mapping_create_success: true,
        application_policy_mapping_create_failed: false,
        application_policy_mapping_create_data: action.data,
        application_policy_mapping_list_data: {
          items: [
            ...state.application_policy_mapping_list_data.items,
            action.data,
          ],
        },
        application_policy_mapping_create_message: "",
      };
    }

    case actionTypes.APPLICATION_POLICY_MAPPING_CREATE_FAILED:
      return {
        ...state,
        application_policy_mapping_create_processing: false,
        application_policy_mapping_create_success: false,
        application_policy_mapping_create_failed: true,
        application_policy_mapping_create_data: action.data,
        application_policy_mapping_create_message: "",
      };

    case actionTypes.APPLICATION_POLICY_MAPPING_EDIT_INITIAL:
      return {
        ...state,
        application_policy_mapping_edit_processing: false,
        application_policy_mapping_edit_success: false,
        application_policy_mapping_edit_failed: false,
        application_policy_mapping_edit_data: [],
        application_policy_mapping_edit_message: "",
      };
    case actionTypes.APPLICATION_POLICY_MAPPING_EDIT_PROCESS:
      return {
        ...state,
        application_policy_mapping_edit_processing: true,
        application_policy_mapping_edit_success: false,
        application_policy_mapping_edit_failed: false,
        application_policy_mapping_edit_data: [],
        application_policy_mapping_edit_message: "",
      };

    case actionTypes.APPLICATION_POLICY_MAPPING_EDIT_SUCCESS: {
      let fetchlistd = state.application_policy_mapping_list_data;

      let findIndex1 = fetchlistd.items.findIndex(
        (item) => item.id == action.data.id
      );
      fetchlistd.items[findIndex1] = action.data;

      return {
        ...state,
        application_policy_mapping_edit_processing: false,
        application_policy_mapping_edit_success: true,
        application_policy_mapping_edit_failed: false,
        application_policy_mapping_edit_data: action.data,
        application_policy_mapping_list_data: fetchlistd,
        application_policy_mapping_edit_message: "",
      };
    }

    case actionTypes.APPLICATION_POLICY_MAPPING_EDIT_FAILED:
      return {
        ...state,
        application_policy_mapping_edit_processing: false,
        application_policy_mapping_edit_success: false,
        application_policy_mapping_edit_failed: true,
        application_policy_mapping_edit_data: action.data,
        application_policy_mapping_edit_message: "",
      };

    case actionTypes.APPLICATION_POLICY_SEARCH_INITIAL:
      return {
        ...state,
        application_policy_search_processing: false,
        application_policy_search_success: false,
        application_policy_search_failed: false,
        application_policy_search_data: [],
        application_policy_search_message: "",
      };

    case actionTypes.APPLICATION_POLICY_SEARCH_PROCESS:
      return {
        ...state,
        application_policy_search_processing: true,
        application_policy_search_success: false,
        application_policy_search_failed: false,
        application_policy_search_data: [],
        application_policy_search_message: "",
      };

    case actionTypes.APPLICATION_POLICY_SEARCH_SUCCESS:
      return {
        ...state,
        application_policy_search_processing: false,
        application_policy_search_success: true,
        application_policy_search_failed: false,
        application_policy_search_data: action.data,
        application_policy_search_message: "",
      };

    case actionTypes.APPLICATION_POLICY_SEARCH_FAILED:
      return {
        ...state,
        application_policy_search_processing: false,
        application_policy_search_success: false,
        application_policy_search_failed: true,
        application_policy_search_data: [],
        application_policy_search_message: action.data,
      };

    default:
      return state;
  }
};
