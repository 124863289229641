import { Dropdown } from "antd";
import React from "react";
import {
  BsFillChatRightTextFill,
  BsFillQuestionOctagonFill,
  BsQuestionCircle,
  BsTicketFill,
} from "react-icons/bs";
import { raiseTicketURL } from "utils/transport";

const Help = () => {
  const items = [
    {
      label: (
        <div
          className="flex items-center justify-start gap-4 h-8"
          onClick={() => {
            window.open(
              "https://we360v2.freshdesk.com/support/solutions",
              "_blank"
            );
          }}
        >
          <BsFillQuestionOctagonFill />
          <div>Documentations</div>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
      key: "1",
    },
    {
      label: (
        <div
          className="flex items-center justify-start gap-4 h-8"
          onClick={() => {
            window.open(raiseTicketURL, "_blank");
          }}
        >
          <BsTicketFill />
          <div>Raise a ticket</div>
        </div>
      ),
      key: "2",
    },
    {
      type: "divider",
      key: "3",
    },
    {
      label: (
        <div
          className="flex items-center justify-start gap-4 h-8"
          onClick={() => {
            // @ts-ignore
            window.fcWidget?.open({
              name: "Support",
              replyText: "",
            });
          }}
        >
          <BsFillChatRightTextFill />
          <div>Chat with us</div>
        </div>
      ),
      key: "4",
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <div className="flex gap-2 cursor-pointer items-center justify-center">
        <BsQuestionCircle size={23} />
      </div>
    </Dropdown>
  );
};

export default Help;
