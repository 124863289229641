export const FETCH_TENANT_SETTINGS = "FETCH_TENANT_SETTINGS";
export const FETCH_TENANT_SETTINGS_SUCCESS = "FETCH_TENANT_SETTINGS_SUCCESS";
export const FETCH_TENANT_SETTINGS_FAILED = "FETCH_TENANT_SETTINGS_FAILED";

export const UPDATE_TENANT_SETTINGS = "UPDATE_TENANT_SETTINGS";
export const UPDATE_TENANT_SETTINGS_SUCCESS = "UPDATE_TENANT_SETTINGS_SUCCESS";
export const UPDATE_TENANT_SETTINGS_FAILED = "UPDATE_TENANT_SETTINGS_FAILED";

export const SET_TENANT_SETTINGS_ERROR_MESSAGE =
  "SET_TENANT_SETTINGS_ERROR_MESSAGE";
