const translation = {
  dashboard: "Tableau de bord",
  attendance: "Présence",
  livestream: "Direct",
  wellness360: "Bien-être 360",
  activity: "Activité",
  productivity: "Productivité",
  timeline: "Calendrier",
  application: "Application",
  reports: "Rapports",
  alerts: "Alertes",
  settings: "Paramètres",
};

export default translation;
