import { Spin } from "antd";
import TaskTypeIcon from "common/TaskTypeIcon";
import Timer from "common/Timer";
import { AuthContext } from "contexts/authContext";
import React, { useContext } from "react";
import { BsStopCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateTaskActivity } from "redux/store/task-activities/action";
import { PROJECTS, TASK } from "route/constant";
import { antLoadingIcon } from "utils/commonUtils";

const GlobalTaskTimer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getDefaultHeader } = useContext(AuthContext);

  const {
    activeTimerActivityData,
    selectedTaskDrawer,
    activeTimerActivityLoading,
  }: {
    activeTimerActivityData?: TaskActivity;
    selectedTaskDrawer?: Task;
    activeTimerActivityLoading: boolean;
  } = useSelector(
    (state) =>
      // @ts-ignore
      state.taskActivities
  );

  const onTimerEnd = () => {
    const currentTime = new Date();
    dispatch(
      updateTaskActivity({
        id: activeTimerActivityData.id,
        header: getDefaultHeader(),
        body: {
          ...activeTimerActivityData,
          end_time: currentTime,
        },
        isTimer: true,
      })
    );
  };

  const navigateToTask = () => {
    if (activeTimerActivityData) {
      navigate(
        `${PROJECTS}/${activeTimerActivityData.task.project.id}${TASK}/${activeTimerActivityData.task.id}`
      );
    }
  };

  return (
    <>
      {activeTimerActivityData &&
        selectedTaskDrawer?.id !== activeTimerActivityData.task.id && (
          <div
            className="fixed p-2 px-4 rounded bottom-4 right-4 bg-gray-50 z-[9999] flex items-center gap-3 "
            style={{ boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.4)" }}
          >
            <div
              className="flex gap-2 items-center max-w-xs hover:max-w-7xl transition-all cursor-pointer"
              onClick={navigateToTask}
            >
              <TaskTypeIcon
                taskType={activeTimerActivityData.task.type}
                className="w-8"
              />
              <div className=" text-lg w-full text-ellipsis whitespace-nowrap overflow-hidden">
                {activeTimerActivityData.task.summary}
              </div>
            </div>

            <div className="border-l border-gray-900 w-0 h-10" />

            <div className="flex items-center justify-center gap-2 ">
              {activeTimerActivityLoading ? (
                <div className="flex justify-center items-center w-8 h-8 rounded-full cursor-not-allowed bg-red-500">
                  <Spin
                    className="mb-1 text-white"
                    indicator={antLoadingIcon}
                  />
                </div>
              ) : (
                <BsStopCircleFill
                  className="w-8 h-8 text-red-500 cursor-pointer"
                  onClick={onTimerEnd}
                />
              )}
              <div>
                <div className="text-teal-600 text-xs font-semibold">
                  Time Spent
                </div>

                <div className="text-xl">
                  <Timer
                    initialSeconds={
                      (Date.now() -
                        new Date(
                          activeTimerActivityData.start_time
                        ).getTime()) /
                      1000
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default GlobalTaskTimer;
