import React, { useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import _ from "lodash";
import Chart from "../../chart";
import { onlyHoursNumber } from "../../../utils/timeConverter";
import { useSelector } from "react-redux";
import { Skeleton, Typography, Tooltip, Empty } from "antd";

const { Title } = Typography;

const Cardskeleton = () => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "275px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const TotalAppusage = (props) => {
  const { applicationAppWorkingHoursTime, applicationAppCategoryList } =
    props || {};

  const { chartTitle, tooltipTitle, height, legend } = props || {};

  const appusages = {
    series: [{ data: applicationAppWorkingHoursTime }],
  };

  let maxValue = 0;
  let minValue = 0;

  maxValue =
    (applicationAppWorkingHoursTime || []).length > 0
      ? Math.max(...(applicationAppWorkingHoursTime || []))
      : 5;

  minValue =
    (applicationAppWorkingHoursTime || []).length > 0
      ? Math.min(...(applicationAppWorkingHoursTime || []))
      : 0;
  const applicationsummary = useSelector((state) => state.applicationsummary);

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: { show: false, tools: { download: false } },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: "80%",
        rangeBarGroupRows: true,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    colors: ["#AB9EE5"],
    fill: {
      type: "solid",
    },
    xaxis: {
      min: minValue >= 0 ? 0 : minValue - 10800,
      max: maxValue > 3659 ? maxValue + 10800 : 3660,
      tickAmount: 4,
      type: "categories",
      categories: applicationAppCategoryList || [],
      labels: {
        formatter: function (val) {
          return maxValue > 3659
            ? Math.floor(val / 3600) + "h"
            : parseFloat(Math.floor(val % 3600) / 60).toFixed(0) === "1"
            ? 60 + "m"
            : parseFloat(Math.floor(val % 3600) / 60).toFixed(0) + "m";
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },

    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#000"],
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex];
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      borderColor: "#E6E8F0",

      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
        lines: {
          show: false,
        },
      },
      yaxis: {
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: false,
        },
        lines: {
          show: false,
        },
      },
    },

    legend: {
      show: legend,
      position: "right",
    },
    tooltip: {
      enabled: true,
      theme: "light",

      custom: function (opts, val) {
        const xname = opts.w.globals.labels[opts.dataPointIndex];
        const total = _.sum(opts.w.globals.series[0]);
        const persetage =
          (opts.w.globals.series[0][opts.dataPointIndex] / total) * 100;

        return (
          '<div class="wtooltip"> <div class="colordot" style="background:' +
          opts.w.globals.colors[opts.seriesIndex] +
          '"></div>' +
          xname +
          '<div class="w_value">' +
          persetage.toFixed(0) +
          "%" +
          "</div></div>"
        );
      },
    },
  };

  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  return (
    <>
      {applicationsummary.application_summary_total_application_usage_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>{chartTitle}</Title>
            <Tooltip title={tooltipTitle}>
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div
            className="chart_tool legend_left"
            id="chart"
            style={{ marginTop: "-43px" }}
          >
            {/* chart */}
            {(
              applicationsummary?.application_summary_total_application_usage_data ||
              []
            ).length > 0 ? (
              <Chart
                options={chartOptions}
                series={appusages.series}
                type="bar"
                height={height}
              />
            ) : (
              <div style={{ height: "242px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

TotalAppusage.propTypes = {
  getList: PropTypes.func,
};

export default TotalAppusage;
