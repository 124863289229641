import React from "react";

import Task from "./../assets/images/task/type_task.svg";
import Epic from "./../assets/images/task/epic.svg";
import Bug from "./../assets/images/task/bug.svg";

export const taskTypeOptions: Array<{
  value: TaskType;
  label: React.ReactNode;
}> = [
  {
    value: "EPIC",
    label: (
      <div className="dd_type">
        <img src={Epic} /> Epic
      </div>
    ),
  },
  {
    value: "TASK",
    label: (
      <div className="dd_type">
        <img src={Task} /> Task
      </div>
    ),
  },
  {
    value: "BUG",
    label: (
      <div className="dd_type">
        <img src={Bug} /> Bug
      </div>
    ),
  },
];

export const getStatusColorStyle = (status?: TaskStatus) => {
  if (status?.first) {
    return {
      backgroundColor: "#dededec0",
      color: "#2d2d2d",
    };
  }
  if (status?.last) {
    return {
      backgroundColor: "#14b8a61a",
      color: "#14b8a6",
    };
  }
  return {
    backgroundColor: "#e1dcfa",
    color: "#7b61ff",
  };
};

export const getStatusMapFromList = (statusList: Array<TaskStatus>) => {
  const statusCount = statusList.length;
  return statusList.reduce(
    (acc, status, index) => ({
      ...acc,
      [status.id]: {
        ...status,
        first: index <= 1,
        last: index === statusCount - 1,
      },
    }),
    {}
  );
};

export const getFormattedDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "long",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };
  return `${date.toLocaleString("en-US", options)} `;
};

const timeUnits: { [key: string]: number } = {
  w: 5 * 8 * 60 * 60,
  d: 8 * 60 * 60,
  h: 60 * 60,
  m: 60,
};

export const convertSecondsToTaskEstimate = (seconds: number): string => {
  if (seconds < 60) {
    return "0m";
  }
  const week = Math.floor(seconds / timeUnits.w);
  const day = Math.floor((seconds % timeUnits.w) / timeUnits.d);
  const hours = Math.floor((seconds % timeUnits.d) / timeUnits.h);
  const minutes = Math.floor((seconds % timeUnits.h) / timeUnits.m);

  return `${week ? `${week}w ` : ""}${day ? `${day}d ` : ""}${
    hours ? `${hours}h ` : ""
  }${minutes ? `${minutes}m` : ""}`;
};

export const convertTaskEstimateToSeconds = (estimate: string): number => {
  const regex = /(\d+)(w|d|h|m)/g;
  let totalSeconds = 0;

  estimate.replace(regex, (_, value, unit) => {
    totalSeconds += parseInt(value) * timeUnits[unit];
    return "";
  });

  return totalSeconds;
};

export const getNextDayOfWeek = (date: Date, dayOfWeek: number): Date => {
  const resultDate = new Date(date.getTime());

  resultDate.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7));

  return resultDate;
};

export const taskFiltersConfig: { [key: string]: TaskFilterConfigUnit } = {
  type: {
    type: "OPTION",
    options: {
      is: {
        type: "MULTI_SELECT_TASK_TYPE",
        complete: true,
        filterKey: "contains",
        hints: {
          "contains.type": "we360_task_type",
        },
      },
    },
  },
  status: {
    type: "OPTION",
    options: {
      is: {
        type: "MULTI_SELECT_STATUS_TYPE",
        complete: true,
        filterKey: "contains",
        hints: { "contains.status": "uuid" },
      },
    },
  },
  "assignee.id": {
    type: "OPTION",
    options: {
      is: {
        type: "MULTI_SELECT_USER_TYPE",
        complete: true,
        filterKey: "contains",
        hints: { "contains.assignee.id": "uuid" },
      },
      is_set: {
        type: "VALUE",
        complete: true,
        filterKey: "isNotNull",
      },
      is_not_set: {
        type: "VALUE",
        complete: true,
        filterKey: "isNull",
      },
    },
  },
  "reporter.id": {
    type: "OPTION",
    options: {
      is: {
        type: "MULTI_SELECT_USER_TYPE",
        complete: true,
        filterKey: "contains",
        hints: { "contains.reporter.id": "uuid" },
      },
    },
  },
  duration_estimate_in_seconds: {
    type: "OPTION",
    options: {
      is_set: {
        type: "VALUE",
        complete: true,
        filterKey: "isNotNull",
      },
      is_not_set: {
        type: "VALUE",
        complete: true,
        filterKey: "isNull",
      },
      is_greater_than: {
        type: "TASK_ESTIMATE_INPUT",
        complete: true,
        filterKey: "greaterThanOrEqualTo",
      },
      is_less_than: {
        type: "TASK_ESTIMATE_INPUT",
        complete: true,
        filterKey: "lessThanOrEqualTo",
      },
      is_equal_to: {
        type: "TASK_ESTIMATE_INPUT",
        complete: true,
        filterKey: "equals",
      },
    },
  },
  created_date: {
    type: "OPTION",
    options: {
      is: {
        type: "OPTION",
        options: {
          Today: {
            type: "VALUE",
            complete: true,
            filterKey: ["lessThanOrEqualTo", "greaterThanOrEqualTo"],
            value: [
              new Date().setHours(23, 59, 59),
              new Date().setHours(0, 0, 0),
            ],
            hints: {
              "greaterThanOrEqualTo.created_date": "datetime",
              "lessThanOrEqualTo.created_date": "datetime",
            },
          },
          Yesterday: {
            type: "VALUE",
            complete: true,
            filterKey: ["lessThanOrEqualTo", "greaterThanOrEqualTo"],
            value: [
              new Date(Date.now() - 24 * 60 * 60 * 1000).setHours(23, 59, 59),
              new Date(Date.now() - 24 * 60 * 60 * 1000).setHours(0, 0, 0),
            ],
            hints: {
              "greaterThanOrEqualTo.created_date": "datetime",
              "lessThanOrEqualTo.created_date": "datetime",
            },
          },
          This_week: {
            type: "VALUE",
            complete: true,
            filterKey: ["lessThanOrEqualTo", "greaterThanOrEqualTo"],
            value: [
              getNextDayOfWeek(new Date(), 6).setHours(23, 59, 59),
              (() => {
                const date = getNextDayOfWeek(new Date(), 6);
                date.setDate(date.getDate() - 6);

                return date.setHours(0, 0, 0);
              })(),
            ],
            hints: {
              "greaterThanOrEqualTo.created_date": "datetime",
              "lessThanOrEqualTo.created_date": "datetime",
            },
          },
          Date_range: {
            type: "DATE_RANGE_PICKER",
            complete: true,
            filterKey: ["greaterThanOrEqualTo", "lessThanOrEqualTo"],
            hints: {
              "greaterThanOrEqualTo.created_date": "datetime",
              "lessThanOrEqualTo.created_date": "datetime",
            },
          },
        },
      },
    },
  },
  last_modified_date: {
    type: "OPTION",
    options: {
      is: {
        type: "OPTION",
        options: {
          Today: {
            type: "VALUE",
            complete: true,
            filterKey: ["lessThanOrEqualTo", "greaterThanOrEqualTo"],
            value: [
              new Date().setHours(23, 59, 59),
              new Date().setHours(0, 0, 0),
            ],
            hints: {
              "greaterThanOrEqualTo.last_modified_date": "datetime",
              "lessThanOrEqualTo.last_modified_date": "datetime",
            },
          },
          Yesterday: {
            type: "VALUE",
            complete: true,
            filterKey: ["lessThanOrEqualTo", "greaterThanOrEqualTo"],
            value: [
              new Date(Date.now() - 24 * 60 * 60 * 1000).setHours(23, 59, 59),
              new Date(Date.now() - 24 * 60 * 60 * 1000).setHours(0, 0, 0),
            ],
            hints: {
              "greaterThanOrEqualTo.last_modified_date": "datetime",
              "lessThanOrEqualTo.last_modified_date": "datetime",
            },
          },
          This_week: {
            type: "VALUE",
            complete: true,
            filterKey: ["lessThanOrEqualTo", "greaterThanOrEqualTo"],
            value: [
              getNextDayOfWeek(new Date(), 6).setHours(23, 59, 59),
              (() => {
                const date = getNextDayOfWeek(new Date(), 6);
                date.setDate(date.getDate() - 6);

                return date.setHours(0, 0, 0);
              })(),
            ],
            hints: {
              "greaterThanOrEqualTo.last_modified_date": "datetime",
              "lessThanOrEqualTo.last_modified_date": "datetime",
            },
          },
          Date_range: {
            type: "DATE_RANGE_PICKER",
            complete: true,
            filterKey: ["greaterThanOrEqualTo", "lessThanOrEqualTo"],
            hints: {
              "greaterThanOrEqualTo.last_modified_date": "datetime",
              "lessThanOrEqualTo.last_modified_date": "datetime",
            },
          },
        },
      },
    },
  },
  due_date: {
    disabled: true,
    type: "OPTION",
    options: {
      is: {
        type: "OPTION",
        options: {
          Overdue: {
            type: "VALUE",
            complete: true,
            filterKey: "lessThanOrEqualTo",
            value: new Date(Date.now() - 24 * 60 * 60 * 1000),
            hints: {
              "lessThanOrEqualTo.due_date": "date",
            },
          },
          Due_today: {
            type: "VALUE",
            complete: true,
            filterKey: "equals",
            value: new Date(),
            hints: {
              "equals.due_date": "date",
            },
          },
          Due_this_week: {
            type: "VALUE",
            complete: true,
            filterKey: ["lessThanOrEqualTo", "greaterThanOrEqualTo"],
            value: [
              getNextDayOfWeek(new Date(), 6),
              (() => {
                const date = getNextDayOfWeek(new Date(), 6);
                date.setDate(date.getDate() - 6);

                return date;
              })(),
            ],
            hints: {
              "greaterThanOrEqualTo.due_date": "date",
              "lessThanOrEqualTo.due_date": "date",
            },
          },
          Date_range: {
            type: "DATE_RANGE_PICKER",
            complete: true,
            filterKey: ["greaterThanOrEqualTo", "lessThanOrEqualTo"],
            hints: {
              "greaterThanOrEqualTo.due_date": "date",
              "lessThanOrEqualTo.due_date": "date",
            },
          },
        },
      },
      is_set: {
        type: "VALUE",
        complete: true,
        filterKey: "isNotNull",
      },
      is_not_set: {
        type: "VALUE",
        complete: true,
        filterKey: "isNull",
      },
    },
  },
  start_date: {
    disabled: true,
    type: "OPTION",
    options: {
      is: {
        type: "OPTION",
        options: {
          After_this_week: {
            type: "VALUE",
            complete: true,
            filterKey: "greaterThanOrEqualTo",
            value: getNextDayOfWeek(new Date(), 6),
            hints: {
              "greaterThanOrEqualTo.start_date": "date",
            },
          },

          Today: {
            type: "VALUE",
            complete: true,
            filterKey: "equals",
            value: new Date(),
            hints: {
              "equals.start_date": "date",
            },
          },
          This_week: {
            type: "VALUE",
            complete: true,
            filterKey: ["lessThanOrEqualTo", "greaterThanOrEqualTo"],
            value: [
              getNextDayOfWeek(new Date(), 6),
              (() => {
                const date = getNextDayOfWeek(new Date(), 6);
                date.setDate(date.getDate() - 6);

                return date;
              })(),
            ],
            hints: {
              "greaterThanOrEqualTo.start_date": "date",
              "lessThanOrEqualTo.start_date": "date",
            },
          },
          In_the_past: {
            type: "VALUE",
            complete: true,
            filterKey: "lessThanOrEqualTo",
            value: new Date(Date.now() - 24 * 60 * 60 * 1000),
            hints: {
              "lessThanOrEqualTo.start_date": "date",
            },
          },
          Date_range: {
            type: "DATE_RANGE_PICKER",
            complete: true,
            filterKey: ["greaterThanOrEqualTo", "lessThanOrEqualTo"],
            hints: {
              "greaterThanOrEqualTo.start_date": "date",
              "lessThanOrEqualTo.start_date": "date",
            },
          },
        },
      },
      is_set: {
        type: "VALUE",
        complete: true,
        filterKey: "isNotNull",
      },
      is_not_set: {
        type: "VALUE",
        complete: true,
        filterKey: "isNull",
      },
    },
  },
};

export const filterLabels = {
  type: "Task Type",
  last_modified_date: "Updated date",
  created_date: "Created Date",
  start_date: "Start Date",
  due_date: "Due Date",
  duration_estimate_in_seconds: "Estimate",
  "reporter.id": "Reporter (Created By)",
  "assignee.id": "Assignee",
  status: "Task Status",
};

export const validateTaskFilter = (filter) => {
  if (filter[0]) {
    let filterConfigUnit = taskFiltersConfig[filter[0]];
    for (let i = 1; i <= 3; i++) {
      if (filterConfigUnit.type !== "VALUE" && !filter[i] && filter[i] !== 0) {
        return false;
      }
      switch (filterConfigUnit.type) {
        case "OPTION":
          if (filterConfigUnit.options[filter[i]]) {
            filterConfigUnit = filterConfigUnit.options[filter[i]];
            break;
          }
          return false;

        case "VALUE":
          return true;

        case "MULTI_SELECT_STATUS_TYPE":
        case "MULTI_SELECT_TASK_TYPE":
        case "MULTI_SELECT_USER_TYPE":
          if (Array.isArray(filter[i]) && filter[i].length > 0) {
            return true;
          }
          return false;

        case "TASK_ESTIMATE_INPUT":
          if (typeof filter[i] === "number") {
            return true;
          }
          return false;

        case "DATE_RANGE_PICKER":
          if (Array.isArray(filter[i]) && filter[i].length === 2) {
            return true;
          }
          return false;

        default:
          return false;
      }
    }
  }
  return false;
};

const insertFilterInRequestBody = (
  body,
  filterKey: FilterKeysType | Array<FilterKeysType>,
  filterProperty,
  value,
  hints
) => {
  if (Array.isArray(filterKey)) {
    filterKey.forEach((filterKeySingle, index) => {
      body = insertFilterInRequestBody(
        body,
        filterKeySingle,
        filterProperty,
        value[index],
        {}
      );
    });
  } else {
    if (filterKey === "isNotNull" || filterKey === "isNull") {
      body[filterKey] = [...(body[filterKey] || []), filterProperty];
    } else {
      body[filterKey] = {
        ...(body[filterKey] || {}),
        [filterProperty]: ["created_date", "last_modified_date"].includes(
          filterProperty
        )
          ? new Date(value - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .replace(/Z/g, "000")
          : ["due_date", "start_date"].includes(filterProperty)
          ? new Date(
              new Date(value).getTime() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .split("T")[0]
          : value,
      };
    }
  }
  body.hints = { ...(body.hints || {}), ...(hints || {}) };

  return body;
};

export const getTaskSearchBody = (initialBody, filters) => {
  let body = { ...initialBody };
  filters.forEach((filter) => {
    let filterConfigUnit = taskFiltersConfig[filter[0]];
    for (let i = 1; i <= 3; i++) {
      if (filterConfigUnit.type !== "VALUE" && !filter[i] && filter[i] !== 0) {
        break;
      }
      switch (filterConfigUnit.type) {
        case "OPTION":
          filterConfigUnit = filterConfigUnit.options[filter[i]];
          break;

        case "VALUE":
          body = insertFilterInRequestBody(
            body,
            filterConfigUnit.filterKey,
            filter[0],
            filterConfigUnit.value,
            filterConfigUnit.hints
          );
          break;

        case "MULTI_SELECT_STATUS_TYPE":
        case "MULTI_SELECT_TASK_TYPE":
        case "MULTI_SELECT_USER_TYPE":
        case "TASK_ESTIMATE_INPUT":
        case "DATE_RANGE_PICKER":
          body = insertFilterInRequestBody(
            body,
            filterConfigUnit.filterKey,
            filter[0],
            filter[i],
            filterConfigUnit.hints
          );
          break;
      }
    }
  });

  return body;
};
