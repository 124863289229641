import React from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import Chart from "../../chart";
import { useSelector } from "react-redux";
import { Skeleton, Typography, Tooltip, Empty } from "antd";
import { getDonutChartOptions } from "utils/apexChartsUtil.tsx";

const { Title } = Typography;

const Cardskeleton = () => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "275px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const OnlineTimeBreakdown = (props) => {
  const { graphdata } = props || {};
  const { activity_summary_online_time_break_down_processing } = useSelector(
    (state) => state.activitysummary
  );
  const appusages = {
    series: [
      graphdata.total_active_time || [],
      graphdata.total_idle_duration || [],
    ],
  };

  const chartOptions = getDonutChartOptions({
    options: {
      labels: [
        "Active time",
        "Idle time",
        // "Break time"
      ],
      colors: [
        "#104ADF",
        "#B3B3B3",
        // '#FFB800'
      ],
    },
  });

  return (
    <>
      {activity_summary_online_time_break_down_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>Online Time Breakdown</Title>
            <Tooltip title="Displays the time spent by the employee as active, idle and break time.">
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div className="chartContainer chart_tool" id="chart">
            {/* chart */}
            {!graphdata?.total_active_time ? (
              <div style={{ height: "371px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            ) : (
              <Chart
                options={chartOptions.options}
                series={appusages?.series || []}
                type="donut"
                height={400}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

OnlineTimeBreakdown.propTypes = {
  getList: PropTypes.func,
};

export default OnlineTimeBreakdown;
