import "./../../../color.scss";

import styled from "styled-components";
export const Tabs = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const Tabshorizental = styled.div`
  overflow: hidden;
  display: flex;
`;

export const Tabh = styled.button`
  border: none;
  outline: 0px;
  cursor: pointer;
  width: 100%;
  position: relative;
  padding: 6px 20px;
  margin: 0 0px 0px;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 6px;
  text-align: left;
  border: ${(props) => (props.active ? "0px solid" : "0")};
  color: ${(props) => (props.active ? "#7b61ff" : "#333")};
  border-bottom: ${(props) => (props.active ? "none" : "")};
  background-color: ${(props) => (props.active ? "#e1dcfa" : "#fff")};

  transition: background-color 0.5s ease-in-out;

  :hover {
  }
  :focus {
    outline: 0;
  }
`;

export const Tab = styled.button`
  border: none;
  outline: 0px;
  cursor: pointer;
  width: 100%;
  position: relative;
  padding: 12px 20px;
  margin: 0 0px 5px;
  font-weight: 600;
  font-size: 0.9rem;
  border-radius: 6px;
  text-align: left;
  border: ${(props) => (props.active ? "0px solid" : "0")};
  color: ${(props) => (props.active ? "#7b61ff" : "#333")};
  border-bottom: ${(props) => (props.active ? "none" : "")};
  background-color: ${(props) => (props.active ? "#e1dcfa" : "#fff")};

  transition: background-color 0.5s ease-in-out;

  :hover {
  }
  :focus {
    outline: 0;
  }
`;
export const Content = styled.div`
  ${(props) => (props.active ? "" : "display:none")}
`;
