import { put } from "redux-saga/effects";
import { ApplicationReportAction } from "../../action";
import { Client } from "../../../../utils/transport";
import dayjs from "dayjs";
import { ExportCSV } from "utils/export-csv";
import { hms } from "utils/timeConverter";
import { SOMETHING_WENT_WRONG } from "constants/staticText.js";

const exportData = (payload, data) => {
  let result = [];
  const fileName = `Application & Url Report`;
  const appUrlType = `Apps/Urls Type : ${payload?.type_name
    ?.replaceAll(",", "/")
    .toUpperCase()}`;
  const date = `Date : ${dayjs(payload?.start_date).format(
    "DD-MM-YYYY"
  )} To ${dayjs(payload?.end_date).format("DD-MM-YYYY")} `;
  const header = ["Type", "Details", "Percent (%)", "Duration (hh:mm:ss)"];
  const Generated = "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");
  const topHeader = [fileName, appUrlType, date, Generated, header.join()];
  let mainData = "";
  data.map((record) => {
    const bodyData = [
      record?.type?.toUpperCase(),
      record?.app_name?.replace(
        /^(https?:\/\/)|(Https?:\/\/)|(<)|(>)|\/$/g,
        ""
      ),
      record?.prod_percent,
      hms(record?.productive_duration),
    ];
    mainData = bodyData.join(",");
    topHeader.push(mainData);
  });
  result = topHeader;
  return result;
};
const exportcsv = (payload, data) => {
  const exdata = exportData(payload, data);
  ExportCSV("Application URL Report", exdata);
};

export function* GetApplicationReportGraphData(action) {
  const config = {
    method: "POST",
    url: "query/application/app_url_usage",
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    yield put(
      ApplicationReportAction.ApplicationReportGraphSuccess(response.data?.data)
    );
  } catch (err) {
    yield put(
      ApplicationReportAction.ApplicationReportGraphFailed({
        error: SOMETHING_WENT_WRONG,
      })
    );
  }
}

export function* GetApplicationReportListData(action) {
  const config = {
    method: "POST",
    url: "query/application/app_url_usage",

    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    yield put(
      ApplicationReportAction.ApplicationReportListSuccess(response.data)
    );
  } catch (err) {
    yield put(
      ApplicationReportAction.ApplicationReportListFailed({
        error: SOMETHING_WENT_WRONG,
      })
    );
  }
}

export function* getApplicationReportExportListData(action) {
  const config = {
    method: "POST",
    url: "query/application/app_url_usage",

    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    exportcsv(action.payload.body, response?.data?.data);
    yield put(ApplicationReportAction.ApplicationReportExportListSuccess());
  } catch (err) {
    yield put(
      ApplicationReportAction.ApplicationReportExportListFailed({
        error: SOMETHING_WENT_WRONG,
      })
    );
  }
}
