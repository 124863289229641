import Labels from "./labels";
import Messages from "./messages";
import Modules from "./modules";

const translation = {
  ...Labels,
  ...Messages,
  ...Modules,
};

export default translation;
