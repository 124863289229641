import React, { useContext } from "react";

import { Avatar, Skeleton, Tooltip } from "antd";
import { getAvatarUrl } from "utils/transport";
import { AuthContext } from "contexts/authContext";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { openUserDetail } from "redux/store/user-detail/action";
import { stringToColor } from "utils/helper";

interface Props {
  ellipsis?: boolean;
  loading?: boolean;
  first_name: string;
  last_name: string;
  email?: string;
  avatar_uri: string;
  id: string;
  size?: "large" | "default" | "small";
  className?: string;
  nameClassName?: string;
  emailClassName?: string;
  tab?: number;
  startDate?: string;
  endDate?: string;
  hideUserDetailLink?: boolean;
  me?: boolean;
}

const UserInfo = ({
  loading,
  first_name: firstName,
  last_name: lastName,
  email,
  avatar_uri: avatar,
  id,
  ellipsis = false,
  size = "default",
  className = "",
  nameClassName,
  emailClassName,
  tab,
  startDate,
  endDate,
  hideUserDetailLink = false,
  me = false,
}: Props) => {
  const { coreApiUrl } = useContext(AuthContext);
  const {
    medetails_data,
  }: {
    medetails_data: User;
  } =
    // @ts-ignore
    useSelector((state) => state.users);

  const dispatch = useDispatch();
  const stringAvatar = (firstName = "", lastName = "") => ({
    style: {
      background: stringToColor(`${firstName} ${lastName}`),
      fontSize: size === "large" ? "16px" : size === "small" ? "10px" : "14px",
    },
    children: `${firstName.length ? firstName[0].toUpperCase() : ""}${
      lastName.length ? lastName[0].toUpperCase() : ""
    }`,
  });

  const onUserNameClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!hideUserDetailLink) {
      e.stopPropagation();
      dispatch(openUserDetail({ identityId: id, tab, startDate, endDate }));
    }
  };

  const getName = () => (
    <div
      className={`${nameClassName} user-name ${ellipsis ? "ellipsis-on" : ""} ${
        size === "large" ? "mb-[2px]" : ""
      } ${hideUserDetailLink ? "" : "hover:text-blue-950 hover:underline"} ${
        size === "small" ? "text-xs" : ""
      }`}
      onClick={onUserNameClick}
    >
      {medetails_data.identity.id === id && me
        ? "Me"
        : `${firstName || ""} ${lastName || ""}`}
    </div>
  );

  return (
    <div className={`user-detail ${className}`}>
      <div>
        {loading ? (
          <Skeleton.Avatar size={size} className="avatar" active />
        ) : avatar ? (
          <Avatar
            className="avatar"
            size={size}
            src={avatar === "@we360" ? getAvatarUrl(coreApiUrl, id) : avatar}
          />
        ) : (
          <Avatar
            className="avatar"
            size={size}
            {...stringAvatar(firstName, lastName)}
          />
        )}
      </div>
      <div className="user-info">
        {loading ? (
          <>
            <div>
              <Skeleton.Button
                size="small"
                active
                className={`skeleton-name  ${size === "large" ? "mb-1" : ""}`}
              />
            </div>

            {size === "large" && (
              <div>
                <Skeleton.Button
                  size="small"
                  active
                  className="skeleton-email"
                />
              </div>
            )}
          </>
        ) : ellipsis ? (
          <>
            <Tooltip
              title={`${firstName || ""} ${lastName || ""}`}
              destroyTooltipOnHide
            >
              {getName()}
            </Tooltip>
            {size === "large" && (
              <Tooltip title={email || firstName || ""} destroyTooltipOnHide>
                <div className={`${emailClassName} user-email ellipsis-on`}>
                  {email || firstName || ""}
                </div>
              </Tooltip>
            )}
          </>
        ) : (
          <>
            {getName()}
            {size === "large" && (
              <div className={`${emailClassName} user-email`}>{email}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
