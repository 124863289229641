import * as actionType from "./actionTypes";

export const UserCreateInitial = () => {
  return {
    type: actionType.USER_CREATE_INITIAL,
  };
};

export const UserCreateProcess = (data) => {
  return {
    type: actionType.USER_CREATE_PROCESS,
    data,
  };
};

export const UserCreateSuccess = (data) => {
  return {
    type: actionType.USER_CREATE_SUCCESS,
    data,
  };
};

export const UserCreateFailed = (data) => {
  return {
    type: actionType.USER_CREATE_FAILED,
    data,
  };
};
export const UserInviteCreateInitial = () => {
  return {
    type: actionType.USER_INVITE_CREATE_INITIAL,
  };
};

export const UserInviteCreateProcess = (data) => {
  return {
    type: actionType.USER_INVITE_CREATE_PROCESS,
    data,
  };
};

export const UserInviteCreateSuccess = (data) => {
  return {
    type: actionType.USER_INVITE_CREATE_SUCCESS,
    data,
  };
};

export const UserInviteCreateFailed = (data) => {
  return {
    type: actionType.USER_INVITE_CREATE_FAILED,
    data,
  };
};

export const UserListInitial = () => {
  return {
    type: actionType.USER_LIST_INITIAL,
  };
};

export const UserListProcess = (data) => {
  return {
    type: actionType.USER_LIST_PROCESS,
    data,
  };
};

export const UserListSuccess = (data) => {
  return {
    type: actionType.USER_LIST_SUCCESS,
    data,
  };
};

export const UserListFailed = (data) => {
  return {
    type: actionType.USER_LIST_FAILED,
    data,
  };
};

// field user
export const FieldUserListInitial = () => {
  return {
    type: actionType.FIELD_USER_LIST_INITIAL,
  };
};

export const FieldUserListProcess = (data) => {
  return {
    type: actionType.FIELD_USER_LIST_PROCESS,
    data,
  };
};

export const FieldUserListSuccess = (data) => {
  return {
    type: actionType.FIELD_USER_LIST_SUCCESS,
    data,
  };
};

export const FieldUserListFailed = (data) => {
  return {
    type: actionType.FIELD_USER_LIST_FAILED,
    data,
  };
};

export const MeDetailInitial = () => {
  return {
    type: actionType.ME_DETAILS_INITIAL,
  };
};

export const MeDetailProcess = (data) => {
  return {
    type: actionType.ME_DETAILS_PROCESS,
    data,
  };
};

export const MeDetailSuccess = (data) => {
  return {
    type: actionType.ME_DETAILS_SUCCESS,
    data,
  };
};

export const MeDetailFailed = (data) => {
  return {
    type: actionType.ME_DETAILS_FAILED,
    data,
  };
};
export const RoleUserListInitial = () => {
  return {
    type: actionType.ROLE_USER_LIST_INITIAL,
  };
};

export const RoleUserListProcess = (data) => {
  return {
    type: actionType.ROLE_USER_LIST_PROCESS,
    data,
  };
};

export const RoleUserListSuccess = (data) => {
  return {
    type: actionType.ROLE_USER_LIST_SUCCESS,
    data,
  };
};

export const RoleUserListFailed = (data) => {
  return {
    type: actionType.ROLE_USER_LIST_FAILED,
    data,
  };
};

export const UserUpdateInitial = () => {
  return {
    type: actionType.USER_UPDATE_INITIAL,
  };
};

export const UserUpdateProcess = (data) => {
  return {
    type: actionType.USER_UPDATE_PROCESS,
    data,
  };
};

export const UserUpdateSuccess = (data) => {
  return {
    type: actionType.USER_UPDATE_SUCCESS,
    data,
  };
};

export const UserUpdateFailed = (data) => {
  return {
    type: actionType.USER_UPDATE_FAILED,
    data,
  };
};

export const UserInfoUpdateInitial = () => {
  return {
    type: actionType.USER_INFO_UPDATE_INITIAL,
  };
};

export const UserInfoUpdateProcess = (data) => {
  return {
    type: actionType.USER_INFO_UPDATE_PROCESS,
    data,
  };
};

export const UserInfoUpdateSuccess = (data) => {
  return {
    type: actionType.USER_INFO_UPDATE_SUCCESS,
    data,
  };
};

export const UserInfoUpdateFailed = (data) => {
  return {
    type: actionType.USER_INFO_UPDATE_FAILED,
    data,
  };
};
export const UserListSearch = (data) => {
  return {
    type: actionType.USER_LIST_SEARCH,
    data,
  };
};
export const UserListSearchInitial = (data) => {
  return {
    type: actionType.USER_LIST_SEARCH_INITIAL,
    data,
  };
};

export const RoleUserUpdateInitial = () => {
  return {
    type: actionType.ROLE_USER_UPDATE_INITIAL,
  };
};

export const RoleUserUpdateProcess = (data) => {
  return {
    type: actionType.ROLE_USER_UPDATE_PROCESS,
    data,
  };
};

export const RoleUserUpdateSuccess = (data) => {
  return {
    type: actionType.ROLE_USER_UPDATE_SUCCESS,
    data,
  };
};

export const RoleUserUpdateFailed = (data) => {
  return {
    type: actionType.ROLE_USER_UPDATE_FAILED,
    data,
  };
};

export const TeamWiseUserListInitial = () => {
  return {
    type: actionType.TEAM_WISE_USER_LIST_INITIAL,
  };
};

export const TeamWiseUserListProcess = (token, bodyData) => {
  return {
    type: actionType.TEAM_WISE_USER_LIST_PROCESS,
    token,
    bodyData,
  };
};

export const TeamWiseUserListSuccess = (data) => {
  return {
    type: actionType.TEAM_WISE_USER_LIST_SUCCESS,
    data,
  };
};

export const TeamWiseUserListFailed = (data) => {
  return {
    type: actionType.TEAM_WISE_USER_LIST_FAILED,
    data,
  };
};

export const SetUploadAvatarInitial = () => {
  return {
    type: actionType.SET_UPLOAD_AVATAR_INITIAL,
  };
};

export const SetUploadAvatarProcess = (data) => {
  return {
    type: actionType.SET_UPLOAD_AVATAR_PROCESS,
    data,
  };
};

export const SetUploadAvatarSuccess = (data) => {
  return {
    type: actionType.SET_UPLOAD_AVATAR_SUCCESS,
    data,
  };
};

export const SetUploadAvatarFailed = (data) => {
  return {
    type: actionType.SET_UPLOAD_AVATAR_FAILED,
    data,
  };
};

export const GetUserIdsInitial = () => {
  return {
    type: actionType.GET_USER_IDS_INITIAL,
  };
};

export const GetUserIdsProcess = (data) => {
  return {
    type: actionType.GET_USER_IDS_PROCESS,
    data,
  };
};

export const GetUserIdsSuccess = (data) => {
  return {
    type: actionType.GET_USER_IDS_SUCCESS,
    data,
  };
};

export const GetUserIdsFailed = (data) => {
  return {
    type: actionType.GET_USER_IDS_FAILED,
    data,
  };
};

// field user by id
export const FieldUserByIdInitial = () => {
  return {
    type: actionType.FIELD_USER_BY_ID_INITIAL,
  };
};

export const FieldUserByIdProcess = (data) => {
  return {
    type: actionType.FIELD_USER_BY_ID_PROCESS,
    data,
  };
};

export const FieldUserByIdSuccess = (data) => {
  return {
    type: actionType.FIELD_USER_BY_ID_SUCCESS,
    data,
  };
};

export const FieldUserByIdFailed = (data) => {
  return {
    type: actionType.FIELD_USER_BY_ID_FAILED,
    data,
  };
};

// field user last location
export const FieldUserLastLocationInitial = () => {
  return {
    type: actionType.FIELD_USER_LAST_LOCATION_INITIAL,
  };
};

export const FieldUserLastLocationProcess = (data) => {
  return {
    type: actionType.FIELD_USER_LAST_LOCATION_PROCESS,
    data,
  };
};

export const FieldUserLastLocationSuccess = (data) => {
  return {
    type: actionType.FIELD_USER_LAST_LOCATION_SUCCESS,
    data,
  };
};

export const FieldUserLastLocationFailed = (data) => {
  return {
    type: actionType.FIELD_USER_LAST_LOCATION_FAILED,
    data,
  };
};

// User Bulk Import Validate
export const UserBulkImportValidateInitial = () => {
  return {
    type: actionType.USER_BULK_IMPORT_VALIDATE_INITIAL,
  };
};

export const UserBulkImportValidateProcess = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_VALIDATE_PROCESS,
    data,
  };
};

export const userNewListInitial = () => {
  return {
    type: actionType.USER_NEW_LIST_INITIAL,
  };
};

//
export const userNewListProcess = (data) => {
  return {
    type: actionType.USER_NEW_LIST_PROCESS,
    data,
  };
};

export const UserBulkImportValidateSuccess = () => {
  return {
    type: actionType.USER_BULK_IMPORT_VALIDATE_SUCCESS,
  };
};

export const UserBulkImportValidateFailed = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_VALIDATE_FAILED,
    data,
  };
};

export const userNewListSuccess = (data) => {
  return {
    type: actionType.USER_NEW_LIST_SUCCESS,
    data,
  };
};

// User Bulk Import
export const UserBulkImportInitial = () => {
  return {
    type: actionType.USER_BULK_IMPORT_INITIAL,
  };
};

export const UserBulkImportProcess = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_PROCESS,
    data,
  };
};

export const userNewListFailed = (data) => {
  return {
    type: actionType.USER_NEW_LIST_FAILED,
    data,
  };
};

export const UserBulkImportSuccess = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_SUCCESS,
    data,
  };
};

export const userSearchListInitial = () => {
  return {
    type: actionType.USER_SEARCH_LIST_INITIAL,
  };
};

//
export const userSearchListProcess = (data) => {
  return {
    type: actionType.USER_SEARCH_LIST_PROCESS,
    data,
  };
};

export const UserBulkImportFailed = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_FAILED,
    data,
  };
};

export const userSearchListSuccess = (data) => {
  return {
    type: actionType.USER_SEARCH_LIST_SUCCESS,
    data,
  };
};

// User Bulk Import
export const UserBulkImportGetJobStatusInitial = () => {
  return {
    type: actionType.USER_BULK_IMPORT_GET_JOB_STATUS_INITIAL,
  };
};

export const UserBulkImportGetJobStatusProcess = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_GET_JOB_STATUS_PROCESS,
    data,
  };
};

export const UserBulkImportGetJobStatusSuccess = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_GET_JOB_STATUS_SUCCESS,
    data,
  };
};

export const UserBulkImportGetJobStatusFailed = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_GET_JOB_STATUS_FAILED,
    data,
  };
};

// User Bulk Import
export const UserBulkImportLogsInitial = () => {
  return {
    type: actionType.USER_BULK_IMPORT_LOGS_INITIAL,
  };
};

export const UserBulkImportLogsProcess = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_LOGS_PROCESS,
    data,
  };
};

export const UserBulkImportLogsSuccess = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_LOGS_SUCCESS,
    data,
  };
};

export const UserBulkImportLogsFailed = (data) => {
  return {
    type: actionType.USER_BULK_IMPORT_LOGS_FAILED,
    data,
  };
};

export const userSearchListFailed = (data) => {
  return {
    type: actionType.USER_SEARCH_LIST_FAILED,
    data,
  };
};

export const deactivateUsers = (payload) => {
  return {
    type: actionType.DEACTIVATE_USERS,
    payload,
  };
};

export const deactivateUsersComplete = (payload) => {
  return {
    type: actionType.DEACTIVATE_USERS_COMPLETE,
    payload,
  };
};

export const activateUsers = (payload) => {
  return {
    type: actionType.ACTVATE_USERS,
    payload,
  };
};

export const activateUsersComplete = (payload) => {
  return {
    type: actionType.ACTVATE_USERS_COMPLETE,
    payload,
  };
};

export const ExportUserListInitial = () => {
  return {
    type: actionType.EXPORT_USER_LIST_INITIAL,
  };
};

export const ExportUserListProcess = (data) => {
  return {
    type: actionType.EXPORT_USER_LIST_PROCESS,
    data,
  };
};

export const ExportUserListSuccess = () => {
  return {
    type: actionType.EXPORT_USER_LIST_SUCCESS,
  };
};

export const ExportUserListFailed = (data) => {
  return {
    type: actionType.EXPORT_USER_LIST_FAILED,
    data,
  };
};
