import * as actionTypes from "./actionTypes";

const searchUsers = (dataset, search) => {
  if (search.length > 0) {
    const searchdata = _.filter(dataset, (list) => {
      // return list.identity.first_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
      //   ? true
      //   : false;

      return (
        (list.identity.first_name.toLowerCase().indexOf(search.toLowerCase()) >=
        0
          ? true
          : false) ||
        (list.identity.last_name.toLowerCase().indexOf(search.toLowerCase()) >=
        0
          ? true
          : false) ||
        (list.identity.email.toLowerCase().indexOf(search.toLowerCase()) >= 0
          ? true
          : false)
      );
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const initialState = {
  user_create_processing: false,
  user_create_success: false,
  user_create_failed: false,
  user_create_data: [],
  user_create_message: "",

  user_invite_create_processing: false,
  user_invite_create_success: false,
  user_invite_create_failed: false,
  user_invite_create_data: [],
  user_invite_create_message: "",

  user_list_processing: false,
  user_list_success: false,
  user_list_failed: false,
  user_list_data: [],
  user_map_data: {},
  user_list_message: "",
  userMaster: [],

  field_user_list_processing: false,
  field_user_list_success: false,
  field_user_list_failed: false,
  field_user_list_data: [],
  field_user_pagination: "",

  team_wise_user_list_processing: false,
  team_wise_user_list_success: false,
  team_wise_user_list_failed: false,
  team_wise_user_list_data: [],
  team_wise_user_list_message: "",

  role_user_list_processing: false,
  role_user_list_success: false,
  role_user_list_failed: false,
  role_user_list_data: [],
  role_user_list_message: "",
  roleUserMaster: [],

  user_update_processing: false,
  user_update_success: false,
  user_update_failed: false,
  user_update_data: [],
  user_update_message: "",

  user_info_update_processing: false,
  user_info_update_success: false,
  user_info_update_failed: false,
  user_info_update_data: [],
  user_info_update_message: "",

  role_user_update_processing: false,
  role_user_update_success: false,
  role_user_update_failed: false,
  role_user_update_data: [],
  role_user_update_message: "",

  //* Me DETAILS *//

  medetails_processing: false,
  medetails_success: false,
  medetails_failed: false,
  medetails_data: {},
  medetails_error_message: "",

  upload_avatar_processing: false,
  upload_avatar_success: false,
  upload_avatar_failed: false,
  upload_avatar_data: {},
  upload_avatar_message: "",

  get_user_ids_processing: false,
  get_user_ids_success: false,
  get_user_ids_failed: false,
  get_user_ids_data: {},
  get_user_ids_message: "",

  field_user_by_id_processing: false,
  field_user_by_id_success: false,
  field_user_by_id_failed: false,
  field_user_by_id_data: [],

  field_user_last_location_processing: false,
  field_user_last_location_success: false,
  field_user_last_location_failed: false,
  field_user_last_location_data: [],

  user_bulk_import_validate_processing: false,
  user_bulk_import_validate_success: false,
  user_bulk_import_validate_failed: false,
  user_bulk_import_validate_error_data: {},

  user_bulk_import_processing: false,
  user_bulk_import_success: false,
  user_bulk_import_data: false,
  user_bulk_import_failed: false,
  user_bulk_import_message: {},

  user_bulk_import_get_job_status_processing: false,
  user_bulk_import_get_job_status_success: false,
  user_bulk_import_get_job_status_data: false,
  user_bulk_import_get_job_status_failed: false,
  user_bulk_import_get_job_status_message: {},

  user_bulk_import_logs_processing: false,
  user_bulk_import_logs_success: false,
  user_bulk_import_logs_data: false,
  user_bulk_importlogs_failed: false,
  user_bulk_import_logs_message: {},
  user_new_list_processing: false,
  user_new_list_success: false,
  user_new_list_failed: false,
  user_new_list_data: [],

  user_search_list_processing: false,
  user_search_list_success: false,
  user_search_list_failed: false,
  user_search_list_data: [],
  user_search_list_data_master: [],
  user_search_list_message: "",

  deactivateUsersLoading: false,
  activateUsersLoading: false,
  deactivateUsersSuccess: false,
  activateUsersSuccess: false,

  export_user_list_processing: false,
  export_user_list_success: false,
  export_user_list_failed: false,
  export_user_list_error_msg: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.USER_CREATE_INITIAL:
      return {
        ...state,
        user_create_processing: false,
        user_create_success: false,
        user_create_failed: false,
        user_create_data: [],
        user_create_message: "",
      };
    case actionTypes.USER_CREATE_PROCESS:
      return {
        ...state,
        user_create_processing: true,
        user_create_success: false,
        user_create_failed: false,
        user_create_data: [],
        user_create_message: "",
      };

    case actionTypes.USER_CREATE_SUCCESS:
      return {
        ...state,
        user_create_processing: false,
        user_create_success: true,
        user_create_failed: false,
        user_create_data: action.data,
        userMaster: action.data,
        // user_list_data: [...state.user_list_data, action.data],
        // userMaster: [...state.userMaster, action.data],
        user_create_message: "",
      };
    case actionTypes.USER_CREATE_FAILED:
      return {
        ...state,
        user_create_processing: false,
        user_create_success: false,
        user_create_failed: true,
        user_create_data: [],
        user_create_message: action.data,
      };

    case actionTypes.USER_INVITE_CREATE_INITIAL:
      return {
        ...state,
        user_invite_create_processing: false,
        user_invite_create_success: false,
        user_invite_create_failed: false,
        user_invite_create_data: [],
        user_invite_create_message: "",
      };
    case actionTypes.USER_INVITE_CREATE_PROCESS:
      return {
        ...state,
        user_invite_create_processing: true,
        user_invite_create_success: false,
        user_invite_create_failed: false,
        user_invite_create_data: [],
        user_invite_create_message: "",
      };

    case actionTypes.USER_INVITE_CREATE_SUCCESS:
      return {
        ...state,
        user_invite_create_processing: false,
        user_invite_create_success: true,
        user_invite_create_failed: false,
        user_invite_create_data: action.data,
        userMaster: action.data,
        // user_list_data: [...state.user_list_data, action.data],
        // userMaster: [...state.userMaster, action.data],
        user_invite_create_message: "",
      };
    case actionTypes.USER_INVITE_CREATE_FAILED:
      return {
        ...state,
        user_invite_create_processing: false,
        user_invite_create_success: false,
        user_invite_create_failed: true,
        user_invite_create_data: [],
        user_invite_create_message: action.data,
      };

    case actionTypes.USER_LIST_INITIAL:
      return {
        ...state,
        user_list_processing: false,
        user_list_success: false,
        user_list_failed: false,
        // user_list_data: [],
        // userMaster: [],
        user_list_message: "",
      };
    case actionTypes.USER_LIST_PROCESS:
      return {
        ...state,
        user_list_processing: true,
        user_list_success: false,
        user_list_failed: false,
        user_list_data: [],
        userMaster: [],
        user_list_message: "",
      };

    case actionTypes.USER_LIST_SUCCESS:
      return {
        ...state,
        user_list_processing: false,
        user_list_success: true,
        user_list_failed: false,
        user_list_data: action.data.data.filter(
          (user) => user.identity.is_active
        ),
        user_map_data: action.data.data.reduce(
          (acc, user) => ({ ...acc, [user?.identity.id]: user?.identity }),
          {}
        ),
        userMaster: action.data.data,
        user_list_message: "",
      };
    case actionTypes.USER_LIST_FAILED:
      return {
        ...state,
        user_list_processing: false,
        user_list_success: false,
        user_list_failed: true,
        user_list_data: [],
        user_map_data: {},
        userMaster: [],
        user_list_message: action.data,
      };

    case actionTypes.FIELD_USER_LIST_INITIAL:
      return {
        ...state,
        field_user_list_processing: false,
        field_user_list_success: false,
        field_user_list_failed: false,
        field_user_list_message: "",
      };
    case actionTypes.FIELD_USER_LIST_PROCESS:
      return {
        ...state,
        field_user_list_processing: true,
        field_user_list_success: false,
        field_user_list_failed: false,
        field_user_list_data: [],
        field_user_list_message: "",
      };

    case actionTypes.FIELD_USER_LIST_SUCCESS: {
      return {
        ...state,
        field_user_list_processing: false,
        field_user_list_success: true,
        field_user_list_failed: false,
        field_user_list_data: action.data.data || [],
        field_user_list_message: "",
        field_user_pagination: action.data.meta || state.field_user_pagination,
      };
    }
    case actionTypes.FIELD_USER_LIST_FAILED:
      return {
        ...state,
        field_user_list_processing: false,
        field_user_list_success: false,
        field_user_list_failed: true,
        field_user_list_data: [],
        field_user_list_message: action.data,
      };

    case actionTypes.USER_UPDATE_INITIAL:
      return {
        ...state,
        user_update_processing: false,
        user_update_success: false,
        user_update_failed: false,
        user_update_data: [],
        user_update_message: "",
      };
    case actionTypes.USER_UPDATE_PROCESS:
      return {
        ...state,
        user_update_processing: true,
        user_update_success: false,
        user_update_failed: false,
        user_update_data: [],
        user_update_message: "",
      };

    case actionTypes.USER_UPDATE_SUCCESS:
      return {
        ...state,
        user_update_processing: false,
        user_update_success: true,
        user_update_failed: false,
        user_update_data: action.data,
        user_update_message: "",
      };
    case actionTypes.USER_UPDATE_FAILED:
      return {
        ...state,
        user_update_processing: false,
        user_update_success: false,
        user_update_failed: true,
        user_update_data: [],
        user_update_message: action.data,
      };

    case actionTypes.ROLE_USER_UPDATE_INITIAL:
      return {
        ...state,
        role_user_update_processing: false,
        role_user_update_success: false,
        role_user_update_failed: false,
        role_user_update_data: [],
        role_user_update_message: "",
      };
    case actionTypes.ROLE_USER_UPDATE_PROCESS:
      return {
        ...state,
        role_user_update_processing: true,
        role_user_update_success: false,
        role_user_update_failed: false,
        role_user_update_data: [],
        role_user_update_message: "",
      };

    case actionTypes.ROLE_USER_UPDATE_SUCCESS:
      return {
        ...state,
        role_user_update_processing: false,
        role_user_update_success: true,
        role_user_update_failed: false,
        role_user_update_data: action.data,
        role_user_update_message: "",
      };
    case actionTypes.ROLE_USER_UPDATE_FAILED:
      return {
        ...state,
        role_user_update_processing: false,
        role_user_update_success: false,
        role_user_update_failed: true,
        role_user_update_data: [],
        role_user_update_message: action.data,
      };

    case actionTypes.USER_INFO_UPDATE_INITIAL:
      return {
        ...state,
        user_info_update_processing: false,
        user_info_update_success: false,
        user_info_update_failed: false,
        user_info_update_data: [],
        user_info_update_message: "",
      };
    case actionTypes.USER_INFO_UPDATE_PROCESS:
      return {
        ...state,
        user_info_update_processing: true,
        user_info_update_success: false,
        user_info_update_failed: false,
        user_info_update_data: [],
        user_info_update_message: "",
      };

    case actionTypes.USER_INFO_UPDATE_SUCCESS:
      return {
        ...state,
        user_info_update_processing: false,
        user_info_update_success: true,
        user_info_update_failed: false,
        user_info_update_data: action.data,
        user_info_update_message: "",
      };
    case actionTypes.USER_INFO_UPDATE_FAILED:
      return {
        ...state,
        user_info_update_processing: false,
        user_info_update_success: false,
        user_info_update_failed: true,
        user_update_data: [],
        user_info_update_message: action.data,
      };

    case actionTypes.USER_LIST_SEARCH:
      return {
        ...state,
        user_list_data: searchUsers(state.userMaster, action.data),
        user_list_search: true,
        role_user_list_data: state.roleUserMaster,
      };
    case actionTypes.USER_LIST_SEARCH_INITIAL:
      return {
        ...state,
        user_list_data: state.userMaster,
        user_list_search: false,
      };

    case actionTypes.ROLE_USER_LIST_INITIAL:
      return {
        ...state,
        role_user_list_processing: false,
        role_user_list_success: false,
        role_user_list_failed: false,
        // role_user_list_data: [],
        // roleUserMaster: [],
        role_user_list_message: "",
      };
    case actionTypes.ROLE_USER_LIST_PROCESS:
      return {
        ...state,
        role_user_list_processing: true,
        role_user_list_success: false,
        role_user_list_failed: false,
        role_user_list_data: [],
        roleUserMaster: [],
        role_user_list_message: "",
      };

    case actionTypes.ROLE_USER_LIST_SUCCESS:
      return {
        ...state,
        role_user_list_processing: false,
        role_user_list_success: true,
        role_user_list_failed: false,
        role_user_list_data: action.data.data,
        roleUserMaster: action.data.data,
        role_user_list_message: "",
      };
    case actionTypes.ROLE_USER_LIST_FAILED: {
      return {
        ...state,
        role_user_list_processing: false,
        role_user_list_success: false,
        role_user_list_failed: true,
        role_user_list_data: [],
        roleUserMaster: [],
        role_user_list_message: action.data,
      };
    }

    case actionTypes.ME_DETAILS_INITIAL:
      return {
        ...state,
        medetails_processing: false,
        medetails_success: false,
        medetails_failed: false,
        medetails_data: {},
        medetails_error_message: "",
      };
    case actionTypes.ME_DETAILS_PROCESS:
      return {
        ...state,
        medetails_loading: true,
        medetails_success: false,
        medetails_failed: false,
        medetails_data: {},
      };
    case actionTypes.ME_DETAILS_SUCCESS:
      return {
        ...state,
        medetails_loading: false,
        medetails_success: true,
        medetails_failed: false,
        medetails_data: action.data,
      };
    case actionTypes.ME_DETAILS_FAILED:
      return {
        ...state,
        medetails_loading: false,
        medetails_success: false,
        medetails_failed: true,
        medetails_error_message: action.data,
        medetails_data: {},
      };

    case actionTypes.TEAM_WISE_USER_LIST_INITIAL:
      return {
        ...state,
        team_wise_user_list_processing: false,
        team_wise_user_list_success: false,
        team_wise_user_list_failed: false,
        team_wise_user_list_data: [],
        userMaster: [],
        team_wise_user_list_message: "",
      };
    case actionTypes.TEAM_WISE_USER_LIST_PROCESS:
      return {
        ...state,
        team_wise_user_list_processing: true,
        team_wise_user_list_success: false,
        team_wise_user_list_failed: false,
        team_wise_user_list_data: [],
        team_wise_user_list_message: "",
      };

    case actionTypes.TEAM_WISE_USER_LIST_SUCCESS:
      return {
        ...state,
        team_wise_user_list_processing: false,
        team_wise_user_list_success: true,
        team_wise_user_list_failed: false,
        team_wise_user_list_data: action.data,
        team_wise_user_list_message: "",
      };
    case actionTypes.TEAM_WISE_USER_LIST_FAILED:
      return {
        ...state,
        team_wise_user_list_processing: false,
        team_wise_user_list_success: false,
        team_wise_user_list_failed: true,
        team_wise_user_list_data: [],
        team_wise_user_list_message: action.data,
      };

    case actionTypes.SET_UPLOAD_AVATAR_INITIAL:
      return {
        ...state,
        upload_avatar_processing: false,
        upload_avatar_success: false,
        upload_avatar_failed: false,
        upload_avatar_data: [],
        upload_avatar_message: "",
      };
    case actionTypes.SET_UPLOAD_AVATAR_PROCESS:
      return {
        ...state,
        upload_avatar_processing: true,
        upload_avatar_success: false,
        upload_avatar_failed: false,
        upload_avatar_data: [],
        upload_avatar_message: "",
      };

    case actionTypes.SET_UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        upload_avatar_processing: false,
        upload_avatar_success: true,
        upload_avatar_failed: false,
        upload_avatar_data: action.data,
        upload_avatar_message: "",
      };
    case actionTypes.SET_UPLOAD_AVATAR_FAILED:
      return {
        ...state,
        upload_avatar_processing: false,
        upload_avatar_success: false,
        upload_avatar_failed: true,
        upload_avatar_data: [],
        upload_avatar_message: action.data,
      };

    case actionTypes.GET_USER_IDS_INITIAL:
      return {
        ...state,
        get_user_ids_processing: false,
        get_user_ids_success: false,
        get_user_ids_failed: false,
        get_user_ids_data: {},
        get_user_ids_message: "",
      };
    case actionTypes.GET_USER_IDS_PROCESS:
      return {
        ...state,
        get_user_ids_processing: true,
        get_user_ids_success: false,
        get_user_ids_failed: false,
        get_user_ids_data: {},
        get_user_ids_message: "",
      };

    case actionTypes.GET_USER_IDS_SUCCESS:
      return {
        ...state,
        get_user_ids_processing: false,
        get_user_ids_success: true,
        get_user_ids_failed: false,
        get_user_ids_data: action.data,
        get_user_ids_message: "",
      };
    case actionTypes.GET_USER_IDS_FAILED:
      return {
        ...state,
        get_user_ids_processing: false,
        get_user_ids_success: false,
        get_user_ids_failed: true,
        get_user_ids_data: {},
        get_user_ids_message: action.data,
      };

    case actionTypes.FIELD_USER_BY_ID_INITIAL:
      return {
        ...state,
        field_user_by_id_processing: false,
        field_user_by_id_success: false,
        field_user_by_id_failed: false,
        field_user_by_id_message: "",
      };
    case actionTypes.FIELD_USER_BY_ID_PROCESS:
      return {
        ...state,
        field_user_by_id_processing: true,
        field_user_by_id_success: false,
        field_user_by_id_failed: false,
        field_user_by_id_data: [],
        field_user_by_id_message: "",
      };

    case actionTypes.FIELD_USER_BY_ID_SUCCESS: {
      return {
        ...state,
        field_user_by_id_processing: false,
        field_user_by_id_success: true,
        field_user_by_id_failed: false,
        field_user_by_id_data: action.data,
        field_user_by_id_message: "",
      };
    }
    case actionTypes.FIELD_USER_BY_ID_FAILED:
      return {
        ...state,
        field_user_by_id_processing: false,
        field_user_by_id_success: false,
        field_user_by_id_failed: true,
        field_user_by_id_data: [],
        field_user_by_id_message: action.data,
      };

    case actionTypes.FIELD_USER_LAST_LOCATION_INITIAL:
      return {
        ...state,
        field_user_last_location_processing: false,
        field_user_last_location_success: false,
        field_user_last_location_failed: false,
        field_user_last_location_message: "",
      };
    case actionTypes.FIELD_USER_LAST_LOCATION_PROCESS:
      return {
        ...state,
        field_user_last_location_processing: true,
        field_user_last_location_success: false,
        field_user_last_location_failed: false,
        field_user_last_location_data: [],
        field_user_last_location_message: "",
      };

    case actionTypes.FIELD_USER_LAST_LOCATION_SUCCESS: {
      return {
        ...state,
        field_user_last_location_processing: false,
        field_user_last_location_success: true,
        field_user_last_location_failed: false,
        field_user_last_location_data: action.data,
        field_user_last_location_message: "",
      };
    }
    case actionTypes.FIELD_USER_LAST_LOCATION_FAILED:
      return {
        ...state,
        field_user_last_location_processing: false,
        field_user_last_location_success: false,
        field_user_last_location_failed: true,
        field_user_last_location_data: [],
        field_user_last_location_message: action.data,
      };

    // user bulk import validate
    case actionTypes.USER_BULK_IMPORT_VALIDATE_INITIAL:
      return {
        ...state,

        user_bulk_import_validate_processing: false,
        user_bulk_import_validate_success: false,
        user_bulk_import_validate_failed: false,
        user_bulk_import_validate_error_data: "",
      };
    case actionTypes.USER_BULK_IMPORT_VALIDATE_PROCESS:
      return {
        ...state,
        user_bulk_import_validate_processing: true,
        user_bulk_import_validate_success: false,
        user_bulk_import_validate_failed: false,
        user_bulk_import_validate_error_data: "",
      };

    case actionTypes.USER_BULK_IMPORT_VALIDATE_SUCCESS: {
      return {
        ...state,
        user_bulk_import_validate_processing: false,
        user_bulk_import_validate_success: true,
        user_bulk_import_validate_failed: false,
        user_bulk_import_validate_error_data: "",
      };
    }
    case actionTypes.USER_BULK_IMPORT_VALIDATE_FAILED:
      return {
        ...state,
        user_bulk_import_validate_processing: false,
        user_bulk_import_validate_success: false,
        user_bulk_import_validate_failed: true,
        user_bulk_import_validate_error_data: action.data,
      };

    // user bulk import
    case actionTypes.USER_BULK_IMPORT_INITIAL:
      return {
        ...state,

        user_bulk_import_processing: false,
        user_bulk_import_success: false,
        user_bulk_import_failed: false,
        user_bulk_import_data: [],
        user_bulk_import_message: "",
      };
    case actionTypes.USER_BULK_IMPORT_PROCESS:
      return {
        ...state,
        user_bulk_import_processing: true,
        user_bulk_import_success: false,
        user_bulk_import_failed: false,
        user_bulk_import_data: [],
        user_bulk_import_message: "",
      };

    case actionTypes.USER_BULK_IMPORT_SUCCESS: {
      return {
        ...state,
        user_bulk_import_processing: false,
        user_bulk_import_success: true,
        user_bulk_import_failed: false,
        user_bulk_import_data: action.data,
        user_bulk_import_message: "",
      };
    }
    case actionTypes.USER_BULK_IMPORT_FAILED:
      return {
        ...state,
        user_bulk_import_processing: false,
        user_bulk_import_success: false,
        user_bulk_import_failed: true,
        user_bulk_import_data: [],
        user_bulk_import_message: action.data,
      };

    // user bulk import get job status
    case actionTypes.USER_BULK_IMPORT_GET_JOB_STATUS_INITIAL:
      return {
        ...state,

        user_bulk_import_get_job_status_processing: false,
        user_bulk_import_get_job_status_success: false,
        user_bulk_import_get_job_status_data: [],
        user_bulk_import_get_job_status_failed: false,
        user_bulk_import_get_job_status_message: {},
      };
    case actionTypes.USER_BULK_IMPORT_GET_JOB_STATUS_PROCESS:
      return {
        ...state,
        user_bulk_import_get_job_status_processing: true,
        user_bulk_import_get_job_status_success: false,
        user_bulk_import_get_job_status_data: [],
        user_bulk_import_get_job_status_failed: false,
        user_bulk_import_get_job_status_message: {},
      };

    case actionTypes.USER_BULK_IMPORT_GET_JOB_STATUS_SUCCESS: {
      return {
        ...state,
        user_bulk_import_get_job_status_processing: false,
        user_bulk_import_get_job_status_success: true,
        user_bulk_import_get_job_status_data: action.data,
        user_bulk_import_get_job_status_failed: false,
        user_bulk_import_get_job_status_message: {},
      };
    }
    case actionTypes.USER_BULK_IMPORT_GET_JOB_STATUS_FAILED:
      return {
        ...state,
        user_bulk_import_get_job_status_processing: false,
        user_bulk_import_get_job_status_success: false,
        user_bulk_import_get_job_status_data: [],
        user_bulk_import_get_job_status_failed: true,
        user_bulk_import_get_job_status_message: action.data,
      };

    // user bulk import logs
    case actionTypes.USER_BULK_IMPORT_LOGS_INITIAL:
      return {
        ...state,

        user_bulk_import_logs_processing: false,
        user_bulk_import_logs_success: false,
        user_bulk_import_logs_data: false,
        user_bulk_importlogs_failed: false,
        user_bulk_import_logs_message: {},
      };
    case actionTypes.USER_BULK_IMPORT_LOGS_PROCESS:
      return {
        ...state,
        user_bulk_import_logs_processing: true,
        user_bulk_import_logs_success: false,
        user_bulk_import_logs_data: false,
        user_bulk_importlogs_failed: false,
        user_bulk_import_logs_message: {},
      };

    case actionTypes.USER_BULK_IMPORT_LOGS_SUCCESS: {
      return {
        ...state,
        user_bulk_import_logs_processing: false,
        user_bulk_import_logs_success: true,
        user_bulk_import_logs_data: action.data,
        user_bulk_importlogs_failed: false,
        user_bulk_import_logs_message: {},
      };
    }
    case actionTypes.USER_BULK_IMPORT_LOGS_FAILED:
      return {
        ...state,
        user_bulk_import_logs_processing: false,
        user_bulk_import_logs_success: false,
        user_bulk_import_logs_data: false,
        user_bulk_importlogs_failed: false,
        user_bulk_import_logs_message: action.data,
      };

    case actionTypes.USER_NEW_LIST_INITIAL:
      return {
        ...state,
        user_new_list_processing: false,
        user_new_list_success: false,
        user_new_list_failed: false,
        user_new_list_data: [],
      };

    case actionTypes.USER_NEW_LIST_PROCESS:
      return {
        ...state,
        user_new_list_processing: true,
        user_new_list_success: false,
        user_new_list_failed: false,
        user_new_list_data: [],
      };

    case actionTypes.USER_NEW_LIST_SUCCESS:
      return {
        ...state,
        user_new_list_processing: false,
        user_new_list_success: true,
        user_new_list_failed: false,
        user_new_list_data: action.data,
      };
    case actionTypes.USER_NEW_LIST_FAILED:
      return {
        ...state,
        user_new_list_processing: false,
        user_new_list_success: false,
        user_new_list_failed: true,
        user_new_list_data: action.data,
      };

    case actionTypes.USER_SEARCH_LIST_INITIAL:
      return {
        ...state,
        user_search_list_processing: false,
        user_search_list_success: false,
        user_search_list_failed: false,
        user_search_list_data: [],
        user_search_list_data_master: [],
        user_search_list_message: "",
      };

    case actionTypes.USER_SEARCH_LIST_PROCESS:
      return {
        ...state,
        user_search_list_processing: true,
        user_search_list_success: false,
        user_search_list_failed: false,
        user_search_list_data: [],
        user_search_list_message: "",
      };

    case actionTypes.USER_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        user_search_list_processing: false,
        user_search_list_success: true,
        user_search_list_failed: false,
        user_search_list_data: action.data,
        user_search_list_data_master: [
          ...state.user_search_list_data_master,
          ...action.data?.data,
        ],
        user_search_list_message: "",
      };
    case actionTypes.USER_SEARCH_LIST_FAILED:
      return {
        ...state,
        user_search_list_processing: false,
        user_search_list_success: false,
        user_search_list_failed: true,
        user_search_list_data: [],
        user_search_list_message: action.data,
      };

    case actionTypes.DEACTIVATE_USERS:
      return {
        ...state,
        deactivateUsersLoading: true,
        deactivateUsersSuccess: false,
      };

    case actionTypes.DEACTIVATE_USERS_COMPLETE:
      return {
        ...state,
        deactivateUsersLoading: false,
        deactivateUsersSuccess: true,
      };

    case actionTypes.ACTVATE_USERS:
      return {
        ...state,
        activateUsersLoading: true,
        activateUsersSuccess: false,
      };

    case actionTypes.ACTVATE_USERS_COMPLETE:
      return {
        ...state,
        activateUsersLoading: false,
        activateUsersSuccess: true,
      };

    case actionTypes.EXPORT_USER_LIST_INITIAL:
      return {
        ...state,
        export_user_list_processing: false,
        export_user_list_success: false,
        export_user_list_failed: false,
        export_user_list_error_msg: "",
      };
    case actionTypes.EXPORT_USER_LIST_PROCESS:
      return {
        ...state,
        export_user_list_processing: true,
        export_user_list_success: false,
        export_user_list_failed: false,
        export_user_list_error_msg: "",
      };

    case actionTypes.EXPORT_USER_LIST_SUCCESS:
      return {
        ...state,
        export_user_list_processing: false,
        export_user_list_success: true,
        export_user_list_failed: false,
        export_user_list_error_msg: "",
      };
    case actionTypes.EXPORT_USER_LIST_FAILED:
      return {
        ...state,
        export_user_list_processing: false,
        export_user_list_success: false,
        export_user_list_failed: true,
        export_user_list_error_msg: action.data,
      };

    default:
      return state;
  }
};
