export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const FETCH_SUBSCRIPTIONS_SUCCESS = "FETCH_SUBSCRIPTIONS_SUCCESS";
export const FETCH_SUBSCRIPTIONS_FAILED = "FETCH_SUBSCRIPTIONS_FAILED";

export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILED = "FETCH_INVOICES_FAILED";

export const SET_BILLING_ERROR_MESSAGE = "SET_BILLING_ERROR_MESSAGE";

export const ON_UPGRADE_MODAL_OPEN = "ON_UPGRADE_MODAL_OPEN";

export const ON_UPGRADE_MODAL_CLOSE = "ON_UPGRADE_MODAL_CLOSE";

export const INITIATE_SUBSCRIPTION = "INITIATE_SUBSCRIPTION";
export const INITIATE_SUBSCRIPTION_SUCCESS = "INITIATE_SUBSCRIPTION_SUCCESS";
export const INITIATE_SUBSCRIPTION_FAILED = "INITIATE_SUBSCRIPTION_FAILED";

export const FETCH_PLAN_GROUP = "FETCH_PLAN_GROUP";
export const FETCH_PLAN_GROUP_SUCCESS = "FETCH_PLAN_GROUP_SUCCESS";
export const FETCH_PLAN_GROUP_FAILED = "FETCH_PLAN_GROUP_FAILED";

export const SEND_INVOICE_VIA_EMAIL = "SEND_INVOICE_VIA_EMAIL";
export const SEND_INVOICE_VIA_EMAIL_SUCCESS = "SEND_INVOICE_VIA_EMAIL_SUCCESS";
export const SEND_INVOICE_VIA_EMAIL_FAILED = "SEND_INVOICE_VIA_EMAIL_FAILED";
