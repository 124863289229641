import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Space, Avatar } from "antd";
// import { DownOutlined } from '@ant-design/icons';
import { CaretDownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import "./header.scss";
// import {Profileicon} from './../../components/icons/profile'
// import {Api} from './../../components/icons/api'
import { Pdownload } from "./../../components/icons/Pdownload";
import { Logout } from "./../../components/icons/logout";
import { Settings } from "./../../components/icons/settings";
// import {Team} from './../../components/icons/team'
import { ACCOUNT, BILLING, DOWNLOAD } from "route/constant";
import { AiOutlineContainer } from "react-icons/ai";
import { AuthContext } from "../../contexts/authContext";
import { TenantAction, UserAction } from "redux/store/action";
import UserInfo from "common/UserInfo/index.tsx";
import { getAvatarUrl } from "utils/transport";
import { roles } from "constants/constant";
import { GenerateStealthKey } from "redux/store/role/action";
import { BsIncognito } from "react-icons/bs";

// const DarkTheme = lazy(() => import("./../../theme/dark-color.scss"));

const Profile = (props) => {
  const { downloadModal, ProfileModal } = props;
  const {
    KeyCloaklogout,
    userInfo,
    KState,
    role,
    getDefaultHeader,
    coreApiUrl,
  } = useContext(AuthContext);
  const [darkMode, setDarkMode] = useState();
  const dispatch = useDispatch();
  const tenantdata = useSelector((state) => state.tenant);
  const users = useSelector((state) => state.users);

  const navigate = useNavigate();
  const handleAction = () => {
    navigate(DOWNLOAD);
  };

  // useEffect(() => {
  //   if (KState && KState.keycloak) GetTenantInfo();
  // }, [KState]);
  const GetTenantInfo = () => {
    if (KState && KState.keycloak) {
      let data = {
        clientId: KState.keycloak.clientId,
        token: KState.keycloak.token,
        role,
      };
      dispatch(TenantAction.TenantListProcess(data));
      dispatch(UserAction.RoleUserListProcess(data));
    }
  };

  useEffect(() => {
    if (darkMode === true) {
      setDarkMode(true);
      document.body.className += "darkmode";
      document.body.classList.remove("lightmode");
      // import("./../../theme/dark-color.scss");
    } else if (darkMode === false) {
      document.body.classList.add("lightmode");
    }
    return function cleanup() {
      document.body.classList.remove("lightmode");
      document.body.classList.remove("darkmode");
    };
  }, [darkMode]);

  const onClickProfile = () => {
    GetTenantInfo();
    ProfileModal();
  };

  const stringToColor = (string) => {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  };

  const stringAvatar = (name = "") => ({
    style: {
      background: stringToColor(name),
      fontSize: "16px",
    },
    children: name.length ? name[0].toUpperCase() : "",
  });

  const items = [
    {
      label: (
        <>
          <div className="profile_avtar" onClick={() => onClickProfile()}>
            <Link to="#0">
              <UserInfo {...users.medetails_data?.identity} size="large" />
            </Link>
          </div>{" "}
        </>
      ),
      key: "0",
    },

    {
      type: "divider",
    },

    {
      label: (
        <>
          <div className="account_avtar">
            <Link to={ACCOUNT}>
              <div className="a_block">
                {" "}
                {
                  <Avatar
                    className=""
                    {...stringAvatar(tenantdata?.tenant_list_data?.name)}
                  />
                }
                <p className="text_profile">
                  {tenantdata?.tenant_list_data?.name}
                </p>
              </div>{" "}
              <Settings />
            </Link>{" "}
          </div>
        </>
      ),
      key: "1",
      disabled: role !== roles.OWNER,
    },
    {
      type: "divider",
    },
    {
      label: (
        <Link to={BILLING}>
          <div className="p_icon_text">
            <AiOutlineContainer />
            Billing
          </div>
        </Link>
      ),
      key: "2",
      disabled: role !== roles.OWNER,
    },
    {
      type: "divider",
    },
    // {
    //   label: (
    //     <>
    //       <div className="dark-mode">
    //         <div className="ion">
    //           <MdOutlineNightlight />
    //           Dark mode
    //         </div>
    //         <Switch size="small" onChange={HandleTheme} />
    //       </div>
    //     </>
    //   ),
    //   key: "2",
    // },
    // {
    //   type: "divider",
    // },
    {
      label: (
        <>
          <div className="p_icon_text" onClick={handleAction}>
            <Pdownload />
            Download Apps
          </div>
        </>
      ),
      key: "3",
    },
    {
      type: "divider",
    },
    {
      label: (
        <>
          <div
            className="p_icon_text"
            onClick={() => {
              KeyCloaklogout();
            }}
          >
            <Logout />
            Logout
          </div>
        </>
      ),
      key: "6",
    },
  ];

  const ownerKey = ["2", "4"];

  return (
    <Dropdown
      menu={{
        items: items.filter(
          (item) => !ownerKey.includes(item.key) || role === roles.OWNER
        ),
      }}
      trigger={["click"]}
      className="drop_profile"
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {users.medetails_data?.identity?.avatar_uri ? (
            <Avatar
              src={
                users.medetails_data?.identity?.avatar_uri === "@we360"
                  ? getAvatarUrl(coreApiUrl, users.medetails_data.identity.id)
                  : users.medetails_data?.identity?.avatar_uri
              }
            />
          ) : (
            <Avatar
              style={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              {...stringAvatar(userInfo && userInfo.name)}
            />
          )}
          <CaretDownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};

Profile.propTypes = {
  showBackground: PropTypes.bool,
};

export default Profile;
