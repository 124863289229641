import React from "react";
import PropTypes from "prop-types";
// import{Link} from "react-router-dom"
import { Dropdown, Space, Badge, Avatar, theme, Divider } from "antd";
// import { DownOutlined } from '@ant-design/icons';
import { BellFilled, BellOutlined } from "@ant-design/icons";

// import { BiChevronDown, } from "react-icons/bi";
import "./header.scss";

const { useToken } = theme;

const items = [
  {
    label: (
      <>
        <div className="a_heading">
          <h2>Notification</h2>
          <div className="mark_asread"></div>
        </div>
        <div className="alert_block">
          <div className="al_flex">
            <div className="a_text">Marketing Activity Alert</div>
            <div className="flex item_center ">
              <div className="a_name">Ravi Gautam</div>
              <div className="a_date_time">
                {" "}
                10 Jan 2023 <span>15:23</span>
              </div>
            </div>
          </div>
          <div className="a_icon">
            <Avatar
              icon={<BellOutlined />}
              style={{ backgroundColor: "#87d068" }}
            />
          </div>
        </div>
      </>
    ),
    key: "1",
  },
  {
    type: "divider",
  },
  {
    label: (
      <>
        <div className="alert_block">
          <div className="al_flex">
            <div className="a_text">Marketing Activity Alert</div>
            <div className="flex item_center ">
              <div className="a_name">Ravi Gautam</div>
              <div className="a_date_time">
                {" "}
                10 Jan 2023 <span>15:23</span>
              </div>
            </div>
          </div>
          <div className="a_icon">
            <Avatar
              icon={<BellOutlined />}
              style={{ backgroundColor: "#87d068" }}
            />
          </div>
        </div>
      </>
    ),
    key: "2",
  },
  {
    type: "divider",
  },
  {
    label: (
      <>
        <div className="alert_block">
          <div className="al_flex">
            <div className="a_text">Marketing Activity Alert</div>
            <div className="flex item_center ">
              <div className="a_name">Ravi Gautam</div>
              <div className="a_date_time">
                {" "}
                10 Jan 2023 <span>15:23</span>
              </div>
            </div>
          </div>
          <div className="a_icon">
            <Avatar
              icon={<BellOutlined />}
              style={{ backgroundColor: "#87d068" }}
            />
          </div>
        </div>
      </>
    ),
    key: "3",
  },
  {
    type: "divider",
  },
  {
    label: (
      <>
        <div className="alert_block">
          <div className="al_flex">
            <div className="a_text">Marketing Activity Alert</div>
            <div className="flex item_center ">
              <div className="a_name">Ravi Gautam</div>
              <div className="a_date_time">
                {" "}
                10 Jan 2023 <span>15:23</span>
              </div>
            </div>
          </div>
          <div className="a_icon">
            <Avatar
              icon={<BellOutlined />}
              style={{ backgroundColor: "#87d068" }}
            />
          </div>
        </div>
      </>
    ),
    key: "4",
  },
];

const Alerts = (props) => {
  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle = {
    boxShadow: "none",
  };

  return (
    <Dropdown
      menu={{
        items,
      }}
      arrow
      trigger={["click"]}
      dropdownRender={(menu) => (
        <div style={contentStyle}>
          {React.cloneElement(menu, {
            style: menuStyle,
          })}
          <Divider
            style={{
              margin: 0,
            }}
          />
          <Space
            style={{
              padding: 8,
            }}
          >
            <div className="viewall">
              <a href="#0">View all Notification</a>
              <div className="mark_asread"></div>
            </div>
          </Space>
        </div>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          <Badge dot>
            <Avatar shape="square" icon={<BellFilled />} />
          </Badge>
        </Space>
      </a>
    </Dropdown>
  );
};

Alerts.propTypes = {
  showBackground: PropTypes.bool,
};

export default Alerts;
