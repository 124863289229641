import { put } from "redux-saga/effects";
import { ProductivityReportAction } from "../../action";
import { Client } from "../../../../utils/transport";
import { hms, hmsOnlyNumber } from "utils/timeConverter";
import dayjs from "dayjs";
import { ExportCSV } from "utils/export-csv";
import offlineValue from "utils/getOfflineTime";
import calculatePercentage from "utils/func_percentage";

const ExportData = (fileName, data, colunms, filterDate, teams) => {
  let result = [];
  const Generated = "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");
  const generatedData = [
    fileName,
    "Teams: - " + teams.join(",").replaceAll(",", "/"),
    "Date:-" + filterDate,

    Generated,
    colunms.join(),
  ];

  data.forEach((record) => {
    const offlinetime = offlineValue(
      record.punch_duration,
      record.online_duration,
      record?.break_duration
    );
    const ProductPercent = calculatePercentage(
      record.productive_duration,
      record.online_duration
    );
    const addColunmsValues = [
      !record?.first_name && !record?.last_name
        ? "--"
        : record?.first_name + " " + record?.last_name,
      //  hms(record?.punch_duration),
      hmsOnlyNumber(record.online_duration),
      //  hmsOnlyNumber(offlinetime),
      hmsOnlyNumber(record.productive_duration),
      hmsOnlyNumber(record.unproductive_duration),
      hmsOnlyNumber(record.neutral_duration),
      hmsOnlyNumber(record.break_duration),
      ProductPercent === 100 ? 99.99 + "%" : ProductPercent + "%" || 0 + "%",
    ];

    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};

const getDownloadCSV = (payload, data) => {
  const teamIds = payload.bodyData.group_id.split(",");
  const selectTeamNames = payload.teamList
    .filter((item) => teamIds.includes(item.id))
    .map((item) => item.name);

  const csvdata = data?.data;
  const date =
    dayjs(payload?.bodyData?.start_date).format("YYYY-MM-DD") +
    " To " +
    dayjs(payload?.bodyData?.end_date).format("YYYY-MM-DD");

  const colunms = [
    "Employee",
    "Online time (hh:mm:ss)",
    "Productive time (hh:mm:ss)",
    "Unproductive time (hh:mm:ss)",
    "Neutral time (hh:mm:ss)",
    "Break time (hh:mm:ss)",
    "Productivity %",
  ];
  const exdata = ExportData(
    "Productivity Employee List",
    csvdata,
    colunms,
    date,
    selectTeamNames
  );

  ExportCSV("Productivity_Employee_List_", exdata);
};

export function* GetProductivityReportData(data) {
  const config = {
    method: "POST",
    url: "query/productivity/productivity_employee_list",

    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivityReportAction.ProductivityReportSuccess(response.data)
      );
    } else {
      yield put(ProductivityReportAction.ProductivityReportFailed(response));
    }
  } catch (err) {
    yield put(ProductivityReportAction.ProductivityReportFailed(err));
  }
}

export function* GetProductivityDownloadReportData(data) {
  const config = {
    method: "POST",
    url: "query/productivity/productivity_employee_list",

    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      getDownloadCSV(data, response.data);
      yield put(ProductivityReportAction.ReportDownloadProductivitySuccess());
    } else {
      yield put(
        ProductivityReportAction.ReportDownloadProductivityFailed(response)
      );
    }
  } catch (err) {
    yield put(ProductivityReportAction.ReportDownloadProductivityFailed(err));
  }
}
